export const defaultPDPIcons = [
  {
    title: 'returns',
    text: 'Easy 30 day returns',
  },
  {
    title: 'delivery',
    text: 'Express delivery available',
  },
  {
    title: 'medal',
    text: 'Every item is quality checked',
  },
];

export const designerPDPIcons = [
  {
    title: 'returns',
    text: 'Easy 30 day returns',
  },
  {
    title: 'medal',
    text: 'Every item is quality checked',
  },
  {
    title: 'search',
    text: 'Authenticated by our expert team',
  },
];