import classNames from "classnames";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { AccountContext } from "../../../context/AccountContext";
import { makeCoreRequest } from "../../../utils/account";
import { Button, LoadingAnimation } from "../../core";
import { CharitySearch } from "../CharitySearch";
import Link from 'next/link';
import { Charity } from "../../../types/charity";
import toast from 'react-hot-toast';

export async function setAutoDonatePreference(nonprofit_id: string | null) {
    const options = {
        method: 'PATCH',
        url: `/customers/me/auto_donation`,
        data: {
            nonprofit_id: nonprofit_id
        }
    }
    return makeCoreRequest(options);
}

type Props = {
    title: string;
    text: string;
    titleSize: string;
}

// Macmillan partnership requires special consent for unique marketing and comms
const MACMILLAN_CHARITY_ID = '15796';

export const AutoDonate = ({ title, text, titleSize }: Props) => {
    const { customerDetails, setCustomerDetails } = useContext(AccountContext);
    const [selectedCharity, setSelectedCharity] = useState<Charity | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasConsented, setHasConsented] = useState(false);
    const [requiresConsent, setRequiresConsent] = useState(false);

    const updateAutoDonate = async (charityId: string | null) => {
        setIsLoading(true);
        try {
            const response = await setAutoDonatePreference(charityId);
            setIsLoading(false);
            if (response?.ok) {

                const { body } = response;

                setCustomerDetails({
                    ...customerDetails,
                    chosen_nonprofit_name: body.chosen_nonprofit_name,
                    chosen_nonprofit_id: body.chosen_nonprofit_id,
                })
                if (charityId) {
                    toast.success(`All future credits earned will go to ${body.chosen_nonprofit_name}. Click 'remove' to undo.`);
                };
            } else {
                setIsLoading(false);
                toast.error('We couldn\'t set your auto-donation preference. Please try again or contact customer service.');
            }
        } catch (error) {
            setIsLoading(false);
            toast.error('We couldn\'t set your auto-donation preference. Please try again or contact customer service.');
        }
    }

    const removeCharity = async () => {
        updateAutoDonate(null);
        setSelectedCharity(null);
        toast.success("Autodonate disabled");
    }

    const confirmAutoDonatePreference = async () => {
        if (!selectedCharity?.id) {
            toast.error('Please select a charity first')
            return;
        }
        if (selectedCharity?.id === `${MACMILLAN_CHARITY_ID}`) {
            if (!hasConsented) {
                toast.error('You need to give consent to proceed.')
                return;
            }
        }

        updateAutoDonate(selectedCharity?.id);
    };

    useEffect(() => {
        setRequiresConsent(selectedCharity?.id === `${MACMILLAN_CHARITY_ID}`);
        setHasConsented(false);
    }, [selectedCharity?.id])


    return (
        <div className={classNames("max-w-screen-sm")}>
            {isLoading ? (
                <LoadingAnimation className={'m-auto'} />
            ) : (
                <div>
                    <h2 className={`font-['Bossa'] uppercase ${titleSize}`}>{title}</h2>
                    <p className={'body-14-bold mb-5 mt-2'}>{text}</p>

                    {!customerDetails.chosen_nonprofit_id ? (
                        <div>
                            <CharitySearch setter={setSelectedCharity} />
                            {requiresConsent && <MacmillanConsentCheckbox hasConsented={hasConsented} setHasConsented={setHasConsented}/>}
                            <Button text="Confirm Auto-donate" onClick={confirmAutoDonatePreference} className="mt-4" iconRight={'arrowRight'} />
                        </div>) : (
                        <div>

                            <div className="flex mt-4">
                                <div className="mr-8">
                                    <p>Your chosen charity is <span className={'body-14-bold'}>{customerDetails.chosen_nonprofit_name}</span></p>
                                </div>
                                <div className="ml-auto">
                                    <p className={'body-14-bold whitespace-nowrap h-fit border-b-2 border-brandYellow cursor-pointer'} onClick={removeCharity}>Remove</p>
                                </div>

                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

interface IMacmillanConsentCheckboxProps {
    hasConsented: boolean; 
    setHasConsented: Dispatch<SetStateAction<boolean>>;
};

const MacmillanConsentCheckbox: React.FC<IMacmillanConsentCheckboxProps> = (
    { hasConsented, setHasConsented }: IMacmillanConsentCheckboxProps) => {
    return (
        <div aria-label="consent checkbox">
            <input type="checkbox" className="mt-4 mr-1" checked={hasConsented} onChange={(e) => setHasConsented(e.target.checked)} />
            By clicking I accept I confirm I am happy for my details to be shared with Macmillan Cancer Support to thank me and update my supporter record. This one-off thanking will not impact on any other consent I have given Macmillan to contact me for other purposes. I can stop receiving Macmillan updates by emailing contact@macmillan.org.uk or calling 0300 1000 200. For more information visit&nbsp;
                <Link href="https://www.macmillan.org.uk/privacy-policy" className="underline" target="_blank">https://www.macmillan.org.uk/privacy-policy</Link><br/>
                &nbsp;<p>I also accept that I will not receive email updates on my donation bag once it has been received. However, the tracking of my bag will still show in my account <Link href="/account/thrift-bags" className="underline" target="_blank">here.</Link></p>
        </div>
    )
}