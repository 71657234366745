import { PropsWithChildren, useEffect, useRef, useState } from 'react';

type Props = PropsWithChildren<{
  onIntersection: VoidFunction;
}>


export const IntersectionSideEffects: React.FC<Props> = ({children, onIntersection}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [intersectionTriggered, setIntersectionTriggered] = useState(false);

  useEffect(() => {
    if (intersectionTriggered) return;
    const element = ref.current;
    if (element) {
      const onObserved: IntersectionObserverCallback = (entries, observer) => {
        const entry = entries[0]
        if (entry.isIntersecting) {
          setIntersectionTriggered(true);
          onIntersection();
          observer.unobserve(element);
        }
      }
      const observer = new IntersectionObserver(onObserved, { threshold: 0.75 });
      observer.observe(element);
      return () => observer.unobserve(element);
    }
  }, [ref.current, intersectionTriggered, onIntersection]);

  return <div ref={ref}>{children}</div>
}