import { storyblokEditable } from '@storyblok/react';
import Image from '../../../../core/Image';

import classNames from 'classnames';
import styles from './styles.module.css';
import { CategorySliderItemStoryblok } from './types';
import Link from 'next/link';

interface Props {
  blok: CategorySliderItemStoryblok;
}

const CategorySliderItem = ({ blok }: Props) => {
  const { image, name, url } = blok || {};

  const imgSrc = image?.filename;
  const cachedURL = url?.cached_url;
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {cachedURL && <Link className={styles.link} href={cachedURL}></Link>}
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="Category slide" />}
      {name && <h5 className={classNames(styles.name, 'body-14-bold')}>{name}</h5>}
    </div>
  );
};

export default CategorySliderItem;
