
type ValueMapper<SRC extends Record<any, any>, OUTPUT extends { [key in keyof SRC]: any }> = <KEY extends keyof SRC>(key: KEY, value: SRC[KEY]) => OUTPUT[KEY];

export const mapObjectValues = <I extends Record<any, any>, O extends Record<any, any>>(object: I, map: ValueMapper<I, O> ): O => {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, map(key, value)])
  ) as O;
}

export const isNullOrUndefined = <T>(value: T | null | undefined): value is null | undefined => {
  if (value === null) return true;
  return typeof value === 'undefined';
}

export const isNotNullOrUndefined = <T>(value: T): value is Exclude<Exclude<T, null>, undefined> => {
  return !isNullOrUndefined(value);
}