import axios from "axios";
import { NextApiRequestCookies } from "next/dist/server/api-utils";

export async function fetchGetJSON(url: string) {
  try {
    const data = await fetch(url).then((res) => res.json());
    return data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw err;
  }
}

export async function fetchPostJSON(url: string, data?: {}) {
  try {
    // Default options are marked with *
    const response = await fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data || {}), // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw err;
  }
}

export const fetchTaxonomyFromCore = async (cookies: NextApiRequestCookies) => {
  
  const options = {
    method: 'GET',
    url: process.env.NEXT_PUBLIC_THRIFT_API + '/taxonomy?cache_bypass=' + Date.now(),
    validateStatus: () => true,
    headers: {},
  }

  options.headers['x-customer-auth'] = cookies.customerAccessToken;

  try {
    const response = await axios(options);
    const body = response.data;
    const responseOK = Math.floor(response.status / 100) === 2;
    const reqId = response.headers['x-req-id'];

    return {
      ok: responseOK,
      status: response.status,
      body,
      reqId,
    };
  } catch (error) {
    console.log(error);
    return;
  }

};
