import { storyblokEditable } from '@storyblok/react';
import Link from 'next/link';
import { MenuLinkStoryblok } from '../../../component-types-sb';

interface Props {
  blok: MenuLinkStoryblok;
}

const MenuLink = ({ blok }: Props) => (
  <Link
    href={blok?.link?.cached_url}
    className="text-base font-medium text-gray-500 hover:text-gray-900"
    {...storyblokEditable(blok)}
  >
    {blok.name}
  </Link>
);
export default MenuLink;
