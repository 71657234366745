import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { LayoutListStoryblok } from './types';

interface Props {
  blok: LayoutListStoryblok;
}

const LayoutList = ({ blok }: Props) => {
  const { items } = blok || {};

  return (
    <section {...storyblokEditable(blok)}>
      <div className={styles.items}>
        {items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default LayoutList;
