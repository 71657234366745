import classNames from 'classnames';
import { FC } from 'react';
import { IBannerProps } from './types';

export const Banner: FC<IBannerProps> = ({ content, className }) => {
  return (
    <div className={classNames("w-full bg-brandYellow25 text-center", className)}>
      <span>{content}</span>
    </div>
  );
};

export default Banner;
