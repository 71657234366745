import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styles from './styles.module.css';
import { AnnouncementBarStoryblok } from './types';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Icon } from '../../../core';

interface Props {
  blok: AnnouncementBarStoryblok;
}

const AnnouncementBar = ({ blok }: Props) => {
  const [display, setDisplay] = useState(false);
  const [cookies, setCookie] = useCookies(['hiddenAnnouncements', 'preference']);
  const preference = cookies.preference ?? 'women';

  const { text, bold_text, link, scroll_text, id } = blok;
  const modifiedLinkWithPreference = link?.url.replaceAll('[preference]', `${preference}`);

  const autoScrollSettings = {
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4000,
    speed: 200,
    vertical: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: 'linear',
  };

  const hiddenAnnouncements: string[] = cookies['hiddenAnnouncements'] || [];
  const handleHideAnnouncement = () => {
    const newHiddenAnnouncements = [...hiddenAnnouncements];
    newHiddenAnnouncements.push(id);
    setDisplay(false);

    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7); //Sets announcement cookie expiry to 7 days in the future
    setCookie('hiddenAnnouncements', newHiddenAnnouncements, {
      path: '/', // Make accessible on all paths
      expires: futureDate,
    });
  };

  useEffect(() => {
    const hideAnnouncementBar = hiddenAnnouncements.includes(id);

    setDisplay(!hideAnnouncementBar);
  }, [id]);

  return (
    <section>
      <Transition
        show={display}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={classNames('relative py-1 px-4 sm:px-6 lg:px-8 ', styles.HeaderAnnouncement)}>
          <div className="pr-16 md:text-center md:px-16">
            {typeof scroll_text === 'string' && scroll_text.trim() !== '' ? (
              <Slider {...autoScrollSettings}>
                <div>
                  <p className="body-14 inline-flex flex-wrap gap-x-2">
                    <a href={modifiedLinkWithPreference}>
                      {text} <strong>{bold_text}</strong>
                    </a>
                  </p>
                </div>
                
                <div>
                  <p className="body-14 inline-flex flex-wrap gap-x-2">
                    <a href={modifiedLinkWithPreference}>
                      {scroll_text}
                    </a>
                  </p>
                </div>
              </Slider>
            ) : (
              <div>
                <p className="body-14 inline-flex flex-wrap gap-x-2">
                  <a href={modifiedLinkWithPreference}>
                    <span className="inline-flex gap-1">
                      {text} <strong>{bold_text}</strong> <Icon title="arrowRight" />
                    </span>
                  </a>
                </p>
              </div>
            )}
          </div>

          <button type="button" className={styles.HeaderAnnouncement__Button} onClick={handleHideAnnouncement}>
            <span className="sr-only">Dismiss</span>
            <Icon title="close" size="24" />
          </button>
        </div>
      </Transition>
    </section>
  );
};

export default AnnouncementBar;
