import React, { Fragment, useEffect } from 'react';

import styles from './styles.module.css';

import { Dialog, Transition } from '@headlessui/react';
import { Icon } from '../../../components/core';
import classNames from 'classnames';
import { DrawerProps } from './types';

const Drawer = ({ component: Component, isOpen, hideDrawer, wideScreen, ...props }: DrawerProps) => {
  const drawerLeft = props?.alignment !== 'right';

  const alignmentClass = drawerLeft ? 'left-0 pr-10' : 'right-0 pl-10';

  /* It's a transition object that is passed to the Transition.Child component. */
  const drawer_settings = {
    enter: 'transform transition ease-in-out duration-500 sm:duration-700',
    enterFrom: !drawerLeft ? 'translate-x-full' : '-translate-x-full',
    enterTo: !drawerLeft ? 'translate-x-0' : '-translate-x-0',
    leave: 'transform transition ease-in-out duration-500 sm:duration-700',
    leaveFrom: !drawerLeft ? 'translate-x-0' : '-translate-x-0',
    leaveTo: !drawerLeft ? 'translate-x-full' : '-translate-x-full',
  };

  /* It's a transition object that is passed to the Transition.Child component. */
  const drawer_transition = {
    enter: 'ease-in-out duration-500',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'ease-in-out duration-500',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0',
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window['ReactNativeWebView']) {
      window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'drawer-update', isOpen }));
    }
  }, [isOpen]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="DrawerDialog" onClose={hideDrawer}>
        <Transition.Child as={Fragment} {...drawer_transition}>
          <div className={classNames(styles.DrawerBg, { 'bg-opacity-0': !isOpen })}>
            <div className={styles.DrawerInner}>
              <div className={`${styles.Drawer} ${alignmentClass}`}>
                <Transition.Child as={Fragment} {...drawer_settings}>
                  <Dialog.Panel className={classNames(styles.DrawerPanel, { [styles.DrawerPanelWide]: wideScreen })}>
                    <Transition.Child as={Fragment} {...drawer_transition}>
                      <div
                        className={classNames(styles.CloseButton, {
                          [styles.CloseButtonDrawerRight]: !drawerLeft,
                        })}
                      >
                        <button type="button" className={styles.CloseButtonIcon} onClick={hideDrawer}>
                          <Icon title={'closeDrawer'} />
                          <span className="sr-only">Close</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className={styles.DrawerContainer}>
                      {Component ? (
                        <Component
                          {...props}
                          onClose={(data) => {
                            hideDrawer();
                            if (props?.onClose) {
                              props.onClose(data);
                            }
                          }}
                        />
                      ) : null}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
