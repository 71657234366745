import { storyblokEditable } from '@storyblok/react';
import { Signpost } from '../../../../core';
import styles from './styles.module.css';
import { DrawerSignpostItemStoryblok } from './types';

interface Props {
  blok: DrawerSignpostItemStoryblok;
}

const DrawerSignpostItem = ({ blok }: Props) => {
  const { url, text } = blok || {};

  const cachedURL = url?.cached_url;
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <Signpost href={cachedURL} signpostText={text} />
    </div>
  );
};

export default DrawerSignpostItem;
