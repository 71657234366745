import { createContext, useState, useEffect, useMemo, useContext, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';

import Shop from '../components/storyblok/nested/Drawer/Shop';
import Cart from '../components/storyblok/nested/Drawer/Cart';

import { DrawerContext } from './DrawerContext';
import { AccountContext } from './AccountContext';
import { SessionContext } from './SessionContext';
import Wishlist from '../components/storyblok/nested/Drawer/Wishlist';
import { Tracking } from '../utils/tracking';

export interface WebviewEventContextType {
  isMobileApp: boolean;
  showSplashScreen: boolean;
  setShowSplashScreen: Dispatch<SetStateAction<boolean>>;
  setShowSplashScreenHandler: (opt) => void;
  notificationStatus: Status;
  setNotificationStatus: Dispatch<SetStateAction<Status>>;
  popupComplete: boolean;
  setPopupComplete: Dispatch<SetStateAction<boolean>>;
  isFirstSession: boolean;
  showPopup: boolean;
}

interface WebviewEventProviderProps {
  config: any;
  children: React.ReactNode;
}

export const STATUS = {
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
} as const;

type ObjectValues<T> = T[keyof T];
type Status = ObjectValues<typeof STATUS>;

const WebviewEventContext = createContext<WebviewEventContextType>(undefined as any); // Put undefined so we know immediately if the context has been initialized incorrectly

const WebviewEventContextProvider: React.FC<WebviewEventProviderProps> = ({ children, config }) => {
  //@ts-ignore
  const [isMobileApp, setIsMobileApp] = useState<boolean>();
  const [cookies, setCookie] = useCookies(['splashScreen']);
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(!cookies.splashScreen);
  const [popupComplete, setPopupComplete] = useState<boolean>(null);
  const [showPopup, setShowPopup] = useState<boolean>(null);
  const [notificationStatus, setNotificationStatus] = useState<Status>(null);
  const [isFirstSession, setIsFirstSession] = useState<boolean>();
  const [notificationPopupCycleStage, setNotificationPopupCycleStage] = useState<1 | 2 | 3>(1);
  const { showDrawer } = useContext(DrawerContext);
  const { push } = useRouter();
  const { basePath } = useContext(SessionContext);

  const { isLoggedIn } = useContext(AccountContext);

  useEffect(() => {
    setIsMobileApp(typeof window !== 'undefined' && window['ReactNativeWebView']);

    if (cookies.splashScreen) {
      setShowSplashScreen(false);
    } else {
      setShowSplashScreen(!isLoggedIn);
    }
  }, []);

  useMemo(() => {
    if (showSplashScreen) {
      typeof window !== 'undefined' &&
        window['ReactNativeWebView'] &&
        window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'splash-screen', data: true }));
    } else {
      typeof window !== 'undefined' &&
        window['ReactNativeWebView'] &&
        window['ReactNativeWebView'].postMessage(JSON.stringify({ type: 'splash-screen', data: false }));
    }
  }, [showSplashScreen]);

  useEffect(() => {
    const currentDate = new Date(Date.now());
    if (typeof window !== 'undefined' && window['ReactNativeWebView']) {
      if (notificationStatus === STATUS.ACCEPTED) {
        window['ReactNativeWebView'].postMessage(
          JSON.stringify({ type: 'notifications-accepted', data: currentDate.toISOString() })
        );
      } else if (notificationStatus === STATUS.DECLINED) {
        let type: string;

        switch (notificationPopupCycleStage) {
          case 1:
            type = 'notifications-declined';
            break;
          case 2:
            type = 'notifications-declined+30';
            break;
          case 3:
            type = 'notifications-declined-permanent';
            break;
        }

        window['ReactNativeWebView'].postMessage(JSON.stringify({ type: type, data: currentDate.toISOString() }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationStatus]);

  const shouldShowPopupAgain = (date: string, daysToWait?: number) => {
    //if the popup was declined before a certain date - show it again?

    if (!daysToWait) {
      setShowPopup(false);
    } else {
      const now = new Date(Date.now());
      const then = new Date(date);

      switch (daysToWait) {
        case 1:
          if (now.getTime() - then.getTime() > 86400000) {
            setNotificationPopupCycleStage(2);
            setShowPopup(true);
          }
          break;
        case 30:
          if (now.getTime() - then.getTime() > 2592000000) {
            setNotificationPopupCycleStage(3);
            setShowPopup(true);
          }
          break;
      }
    }
  };

  const events = {
    HOME: 'navigate home',
    BACK: 'navigate back',
    HIDEHEADER: 'hide header',
    OPENMENU: 'open menu',
    ACCOUNT: 'navigate account',
    WISHLIST: 'navigate wishlist',
    CART: 'navigate cart',
    FCM_TOKEN: 'fcm_token',
    NOTIFICATIONSACCEPTED: 'notifications-accepted',
    NOTIFICATIONSDECLINED: 'notifications-declined',
    NOTIFICATIONSDECLINEDPERMANENT: 'notifications-declined-permanent', // never show again
    NOTIFICATIONSDECLINED30: 'notifications-declined+30', // +30 days till it's shown again
    NOTIFICATIONSFIRSTSESSION: 'notifications-first-session',
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('customEvent', (event: any) => {
        switch (event.detail.message) {
          case events.HOME:
            push(basePath);
            break;
          case events.BACK:
            // TODO go back
            break;
          case events.HIDEHEADER:
            !isMobileApp && setIsMobileApp(true);
            break;
          case events.OPENMENU:
            showDrawer(Shop as any, { config }, true);
            break;
          case events.ACCOUNT:
            push('/account');
            break;
          case events.WISHLIST:
            showDrawer(Wishlist as any, { alignment: 'right' });
            break;
          case events.CART:
            showDrawer(Cart as any, { alignment: 'right' });
            break;
          case events.NOTIFICATIONSACCEPTED:
            // do nothing for now
            setShowPopup(false);
            break;
          case events.NOTIFICATIONSDECLINED:
            shouldShowPopupAgain(event.detail.data, 1);
            break;
          case events.NOTIFICATIONSDECLINED30:
            shouldShowPopupAgain(event.detail.data, 30);
            break;
          case events.NOTIFICATIONSDECLINEDPERMANENT:
            shouldShowPopupAgain(event.detail.data);
            break;
          case events.NOTIFICATIONSFIRSTSESSION:
            setIsFirstSession(true);
            setShowPopup(true);
            break;

          default:
        }
      });
      return () => {
        window.removeEventListener('customEvent', (event) => {});
      };
    }
  }, []);

  const setShowSplashScreenHandler = (opt) => {
    const fiftyYearsFromNow = new Date();
    fiftyYearsFromNow.setFullYear(fiftyYearsFromNow.getFullYear() + 50);

    setCookie('splashScreen', 'displayed', { path: '/', expires: fiftyYearsFromNow });
    setShowSplashScreen(opt);
  };

  return (
    <WebviewEventContext.Provider
      value={{
        isMobileApp,
        notificationStatus,
        setNotificationStatus,
        setPopupComplete,
        popupComplete,
        isFirstSession,
        showPopup,
        showSplashScreen,
        setShowSplashScreen,
        setShowSplashScreenHandler,
      }}
    >
      {children}
    </WebviewEventContext.Provider>
  );
};

export { WebviewEventContext, WebviewEventContextProvider };
