import BackToTopCore from '../../../core/BackToTop';
import BackToTopStoryblok from './types';

interface Props {
  blok: BackToTopStoryblok;
}

export const BackToTop = ({ blok }: Props) => {
  const { text } = blok;

  return <BackToTopCore text={text} />;
};
