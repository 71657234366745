type LocalStorageKeys = 'wishlistUUID' | 'hiddenAnnouncements'; // All the keys being used in the app

export const getLocalStorageValue = (key: LocalStorageKeys) => {
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);

    const initial = saved && JSON.parse(saved);
    return initial;
  }
};

export const setLocalStorageValue = (key: LocalStorageKeys, value: any) => {
  if (typeof window !== 'undefined') {
    const initial = JSON.stringify(value);
    return localStorage.setItem(key, initial);
  }
};

export const clearLocalStorageValue = (key: LocalStorageKeys) => {
  if (typeof window !== 'undefined') {
    return localStorage.removeItem(key);
  }
};
