import { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { ShopifyService } from '../modules/shopify.service';
import { DrawerContext } from './DrawerContext';
import Cart from '../components/storyblok/nested/Drawer/Cart';
import { Tracking } from '../utils/tracking';
import { useCookies } from 'react-cookie';
import { usePrimaryCart, usePrimaryCartId } from '../data-fetching/cart/usePrimaryCart';
import { isNotNullOrUndefined } from '../utils/objectHelpers';
import { getQueryClient } from './QueryContext';
//Prevents SSR useRouter error
import { useRouter } from 'next/compat/router';
import { fetchShopifyItemsBySKU } from '../data-fetching/useShopifyItem';
import { buyNow } from '../data-fetching/cart/buyNow';
import { uniq } from 'lodash';
import { useShopifyAccessToken } from '../data-fetching/useShopifyCustomer';

export interface CheckoutCartContextType {
  openCart: () => void;
  showThriftBagUpsell: boolean;
  showCartHeaderTooltip: boolean;
  showHeaderUpdate: () => void;
}

const CheckoutCartContext = createContext<CheckoutCartContextType>(undefined as any); // Put undefined so we know immediately if the context has been initialized incorrectly

const CheckoutCartProvider = ({ children, config }) => {
  const [showCartHeaderTooltip, setShowCartHeaderTooltip] = useState(false);
  const headerTimer = useRef<any>();
  const router = useRouter();
  const buyNowParam = router?.query['buy_now'];

  const { thrift_bag_cart_upsell } = config.content;

  const { showDrawer } = useContext(DrawerContext);

  const [cookies, setCookie, clearCookie] = useCookies(['CHECKOUT_ID']);
  const [newCartId] = usePrimaryCartId();
  const { cart } = usePrimaryCart();
  const deprecatedCheckoutId = cookies.CHECKOUT_ID;
  const shopifyAccessToken = useShopifyAccessToken();

  useEffect(() => {
    /**
     * This effect watches the "buy_now" query param, adds everything in there to a new cart, and redirects to the checkout
     */
    if (!newCartId) return;
    if (!buyNowParam) return;
    let aborted = false;

    const skus = uniq(Array.isArray(buyNowParam) ? buyNowParam : buyNowParam.split(','));
    if (skus.length === 0) return;
    fetchShopifyItemsBySKU({ skus })
      .then(products => products.map(product => ({
        merchandiseId: product.variantId,
        quantity: 1,
      })))
      .then(lineItems => buyNow({ customerAccessToken: shopifyAccessToken, lineItems }))
      .then(checkoutURL => {
        if (aborted) return;
        if (checkoutURL) router?.push(checkoutURL);
      })
      .catch(e => console.error(e));
    return () => { aborted = true };
  }, [buyNowParam, newCartId, shopifyAccessToken]);

  useEffect(() => {
    /**
     * This is temporary for the transition from the old Checkout api to the "new" Cart api.
     * It takes all the items from the old cart, adds them to the new one, and then clears out the old cart + cookies.
     * If you are reading this after 20th october 2024, you can 100% delete this whole effect block.
     */
    if (!deprecatedCheckoutId) return;
    if (!newCartId) return;

    new Promise<void>(async (resolve, reject) => {
      try {
        const resp = await ShopifyService.getCheckoutCart({ checkoutId: deprecatedCheckoutId });
        if (resp.node?.__typename === 'Checkout') {
          const items: {lineId: string, quantity: number, merchandiseId: string}[] = resp.node?.lineItems?.edges
            ?.filter(({node}) => isNotNullOrUndefined(node.variant))
            .map(({ node }) => {
              return {
                lineId: node.id,
                quantity: node.quantity,
                merchandiseId: node.variant?.id as string, // already know this is defined from the filter step
              }
            })
          await ShopifyService.cartLinesAdd({ cartId: newCartId, lines: items.map(item => ({ quantity: item.quantity, merchandiseId: item.merchandiseId})) })
          await ShopifyService.removeCheckoutCartItem({ checkoutId: deprecatedCheckoutId, lineItemIds: items.map(lineItem => lineItem.lineId ) });
          await getQueryClient().invalidateQueries({ queryKey: ['carts', newCartId]}); // refresh the new cart to make sure all the new cart items show up
          clearCookie('CHECKOUT_ID', { path: '/' })
        } else {
          clearCookie('CHECKOUT_ID', { path: '/' })
        }
        resolve();
      } catch(err) {
        reject(err);
      }
    }).catch(err => {
      console.error(err);
    })

  }, [deprecatedCheckoutId, newCartId]);

  /**
   * Show Header tooltip for a number of seconds
   */
  const showHeaderUpdate = () => {
    clearTimeout(headerTimer.current);
    setShowCartHeaderTooltip(true);
    headerTimer.current = setTimeout(() => {
      setShowCartHeaderTooltip(false);
    }, 5000);
  };

  const openCart = () => {
    Tracking.ViewCart(
      parseFloat(cart?.cost?.subtotalAmount.amount ?? '0'),
      cart?.lines.map((itm) => ({
        item_id: itm.merchandise.product.id,
        item_name: isNotNullOrUndefined(itm.canonicalItem) ? itm.canonicalItem.title : itm.merchandise.product.title,
        item_brand: itm.canonicalItem?.brand ?? 'unknown',
        price: itm.cost.totalAmount.amount,
      })) ?? []
    );
    showDrawer(Cart as any, { alignment: 'right' });
  };

  return (
    <CheckoutCartContext.Provider
      value={{
        openCart,
        showCartHeaderTooltip,
        showThriftBagUpsell: thrift_bag_cart_upsell,
        showHeaderUpdate
      }}
    >
      {children}
    </CheckoutCartContext.Provider>
  );
};

export { CheckoutCartContext, CheckoutCartProvider };