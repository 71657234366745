import { useContext } from "react";
import { CheckoutCartContext } from "../../../../context/CheckoutCartContext";
import { Icon } from "../../../core";
import { HeaderTooltip } from "..";
import styles from './styles.module.css';

import { usePrimaryCart } from '../../../../data-fetching/cart/usePrimaryCart';

export const OpenCartButton: React.FC = () => {
    const { openCart, showCartHeaderTooltip } = useContext(CheckoutCartContext);
    const { cart } = usePrimaryCart();

    const handleOpenCart = () => {
        openCart();
    };

    return (
        <button className={styles.HeaderLink} onClick={handleOpenCart}>
            <div className="relative">
                <Icon title="bag" className={styles.HeaderLinkIcon} />
                {!!cart?.lines?.length ? (
                <div className="absolute top-0 right-[2px] bg-black text-white w-4 h-4 body-10-bold rounded-lg pt-[1px] ">
                    {cart?.lines?.length}
                </div>
                ) : (
                <></>
                )}
                <HeaderTooltip isVisible={showCartHeaderTooltip} text="Added to your bag" />
            </div>
            <span className="body-14-bold hidden lg:inline-block">Bag</span>
        </button>
    )
}