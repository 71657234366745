import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';

import SignpostLink from '../../../core/SignpostLink';
import styles from './styles.module.css';
import { FeatureListStoryblok } from './types';

interface Props {
  blok: FeatureListStoryblok;
}

const FeatureListAlt = ({ blok }: Props) => {
  const { title, subtitle, features, signpost_text, url, full_width_large } = blok || {};
  const cachedURL = url?.cached_url;
  const enlargeItem = full_width_large ? true : false;

  return (
    <section
      className={classNames(styles.container, 'Section', 'Section--spaced', {
        ['SingleColumnLarge']: !full_width_large,
      })}
      {...storyblokEditable(blok)}
    >
      {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
      {subtitle && <p className="body-14 mb-4">{subtitle}</p>}
      <div className={styles.items}>
        {features?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} className={styles.item} enlarge_item={enlargeItem} />
        ))}
      </div>
      {signpost_text && <div className={styles.signpost}>
        <SignpostLink signpostText={signpost_text} iconRight={''} href={cachedURL} />
      </div>}
    </section>
  );
};

export default FeatureListAlt;
