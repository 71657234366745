import Image from 'next/image';
import classNames from 'classnames';

import { Logo } from '../../core/Icons/iconList';
import styles from './styles.module.css';
import FooterLink from '../../storyblok/nested/FooterLink';
import { useContext } from 'react';
import { WebviewEventContext } from '../../../context/WebviewEventContext';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import NewsletterSignup from '../../storyblok/nested/NewsletterSignup';

const DynamicCookieConsent = dynamic(() => import('../CookieConsent').then(file => file.CookieConsent), {
  loading: () => null,
  ssr: false,
})

export const Footer = ({ config }) => {
  const { shop_links, page_links, sell_links, legal_links } = config.content;
  const { isMobileApp } = useContext(WebviewEventContext);

  const isAndroid = typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent);

  if (isMobileApp) {
    if (isAndroid) {
      return (
        <div className="pb-28">
          <style>{`.smile-launcher-frame-container { bottom: 70px !important; }`}</style>
        </div>
      );
    }
    return <div className="pb-12"></div>;
  }

  return (
    <footer className={styles.Footer}>
      <div className="Container">
        <div className={classNames(styles.Upper, styles.FooterContainer, 'Section Section--spaced')}>
          <div className="md:w-3/5">
            <div className={styles.LogoContainer}>
              <Link href="/" className="inline-block m-0">
                <Logo width={150} height={20} />
              </Link>
              <h4 className="body-14 mt-2">The seamless pre-loved marketplace.</h4>
            </div>
            <div style={{maxWidth: 400}}>
              <NewsletterSignup className="ml-0"
                blok={{
                  title: "Sign up for 15% off",
                  subtitle: 'Plus, get early access to sales, drops and edits.',
                  component: 'newsletter_signup',
                  _uid: '1',
                }}
              backgroundColor={'brandYellow'}
              textAlign={'left'}
              />
            </div>
          </div>
          <div className={styles.LinkListsContainer}>
            <div className={classNames(styles.FooterList, styles.Shoppinglist, styles.link)}>
              <h2 className="font-['Bossa'] uppercase text-lg">Shop</h2>
                {shop_links?.map((link) => (
                  <FooterLink blok={link} className={styles.ShopListLink} key={link._uid} />
                ))}
            </div>
            <div className={classNames(styles.FooterList, styles.Shoppinglist, styles.link)}>
              <h2 className="font-['Bossa'] uppercase text-lg">Sell</h2>
                {sell_links?.map((link) => (
                  <FooterLink blok={link} className={styles.ShopListLink} key={link._uid} />
                ))}
            </div>
            <div className={classNames(styles.FooterList, styles.Shoppinglist, styles.link)}>
              <h2 className="font-['Bossa'] uppercase text-lg">Support</h2>
                {page_links?.map((link) => (
                  <FooterLink blok={link} className={styles.ShopListLink} key={link._uid} />
                ))}
            </div>
            <div className={classNames(styles.FooterList, styles.infoListContainer, styles.link)}>
              <Image src={'https://a.storyblok.com/f/177537/206x300/474fc54b4b/bcorp.png'} alt="b-corp-logo" width={100} height={150} style={{height: "auto"}} />
            </div>
          </div>
        </div>
        <div className={classNames(styles.Lower, 'Section')}>
          <div className={styles.PrivacyTerms}>
            <div className={classNames(styles.link, styles.registeredName)}>
              ©{new Date().getFullYear()} Thrift Retail Ltd
            </div>
            {legal_links?.map((link) => (
              <FooterLink blok={link} className={classNames(styles.PrivacyTermsLink, styles.link)} key={link._uid} />
            ))}
            <DynamicCookieConsent />
          </div>
        </div>
      </div>

    </footer>
  );
};
