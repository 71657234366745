import { ProductSearchState } from './types';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/compat/router';
import { equals } from 'ramda';
import { algoliaIndices } from '../../../utils/constants';
import { parseEnum } from './useSearchState';

const identifyPage = (url: string | null): null | 'search' | 'collection' | 'partner-collection' | 'other' => {
  if (url === null) return null;
  const path = (new URL(url)).pathname;
  if (path.includes('/search')) return 'search';
  if (path.includes('/men/') || path.includes('/women/')) return 'collection';
  if (path.includes('/collection/')) return 'partner-collection';
  return 'other';
};

type Options = {
  serverUrl?: string,
  // will force navigation away from collection pages to general search page
  forceToSearch?: boolean
}

export const useSearchUpdater = (options?: Options): ((updatedSearch: Partial<ProductSearchState>) => Promise<void>) => {
  const serverUrl = options?.serverUrl;
  const windowExists = typeof window === 'object';
  const url = windowExists ? window.location.href : serverUrl ?? null;
  const [currentPage, setCurrentPage] = useState<null | 'search' | 'collection' | 'partner-collection' | 'other'>(identifyPage(url));
  const router = useRouter();

  const newState = identifyPage(url);
  if (!equals(currentPage, newState)) setCurrentPage(newState);

  return async (params: Partial<ProductSearchState>) => {
    if (!router || currentPage === null) return; // not initialised yet
    const queryParams = JSON.parse(JSON.stringify({
      q: params.query || undefined,
      page: params.pagination?.page || undefined,
      brand: params.brand,
      brand_type: params.brand_type,
      category: params.category,
      colour: params.colour,
      condition: params.condition,
      material: params.material,
      original_tags: params.original_tags,
      size: params.size,
      style: params.style,
      price_range: params.price_range,
      min_price: params.min_price,
      max_price: params.max_price,
      sort: parseEnum(params.sort, [algoliaIndices.shopifyProducts, algoliaIndices.shopifyProductsPriceAsc, algoliaIndices.shopifyProductsPriceDesc, algoliaIndices.shopifyProductsNewest, algoliaIndices.shopifyProductsPersonalisationTests]),
    })); // the parse and stringify removed undefined keys
    if (currentPage === 'other' || options?.forceToSearch) {
      if (params.department === 'men') {
        await router.push({ pathname: '/men/search', query: queryParams });
      } else {
        await router.push({ pathname: '/women/search', query: queryParams });
      }
    } else if (currentPage === 'collection' || currentPage === 'partner-collection') {
      const collectionId = router.query.collectionId;
      await router.push({
        query: {
          ...queryParams,
          collectionId,
        },
      });
    } else if (currentPage === 'search') {
      await router.push({
        query: queryParams,
      });
    }
  };
};