import formatTitle from 'title';
import { Merge } from 'type-fest';
import truncate from 'lodash/truncate';
import { decodeBase64, AxiosResponse } from '../helpers';
import {
  ShopifyService,
  GetProductListQuery,
  GetProductListQueryVariables,
  CurrencyCode,
  PaginatedProductListFragment,
  GetProductSingleQuery,
} from './shopify.service';
import { ProductMini } from '../helpers/recentlyviewed.utility';

export namespace ProductService {
  export interface Price {
    amount: number;
    currencyCode: CurrencyCode;
  }
  export interface Single {
    id: string;
    title: string;
    description: string;
    descriptionHtml: any;
    productType: string;
    tags: string[];
    collections: string[];
    tagsByCategory: {
      SizeFilter?: string;
      ConditionWebsite?: string;
      Care?: string;
      condition?: string;
      department?: string;
      colour?: string;
      material?: string;
      style?: string;
      brand?: string;
      brand_type?: string;
      category?: string;
      sku?: string;
      size?: string;
      dimensions?: {
        width: number;
        height: number;
        length: number;
      };
      pattern?: string;
    }; //TODO: add in the diff
    handle: string;
    seo: {
      title: string;
      description: string;
    };
    images: {
      id: string;
      src: string;
      alt: string;
    }[];
    variants: {
      id: string;
      title: string;
      image?: string;
      price: Price;
      compareAtPrice?: Price;
    }[];
    totalInventory: number;
  }

  /**
   * Helpers
   */

  function generateImagesFromEdges(edges: any): Single['images'] {
    const images = edges.map(({ node }) => {
      return {
        id: node.id as string,
        src: node.url,
        alt: node.altText || '',
      };
    });

    if (images.length < 1) {
      images.push({
        id: 'default-img',
        src: '/fallback.jpg', // Will default to broken url
        alt: 'Product Image',
      });
    }

    return images;
  }

  function generateVariantsFromEdges(edges: any): Single['variants'] {
    return edges.map(({ node }) => {
      const variant: Single['variants'][0] = {
        id: node.id, //decodeBase64(node.id),
        title: node.title,
        image: node.image?.id! || null,
        price: {
          amount: Number(node.priceV2.amount),
          currencyCode: node.priceV2.currencyCode,
        },
        ...(node.compareAtPriceV2
          ? {
              compareAtPrice: {
                amount: Number(node.compareAtPriceV2.amount),
                currencyCode: node.compareAtPriceV2.currencyCode,
              },
            }
          : {}),
      };

      return variant;
    });
  }

  export async function getSingleFallible(handle: string): Promise<Single | null> {
    const { productByHandle } = await ShopifyService.getProductSingle({ handle });

    return ProductService.fromProductByHandle({productByHandle, handle});
  }

  export async function fromProductByHandle({productByHandle, handle}: GetProductSingleQuery & {handle: string}): Promise<Single | null> {

    if (productByHandle) {
      const { title, description, productType, seo, images, variants, id, descriptionHtml, tags, totalInventory } =
        productByHandle!;

      const tagsByCategory = {};
      const dimensions = {};
      tags?.forEach((tag) => {
        const tagArr = tag.replace(/boolean:/g, '').split(':');

        const [key, value] = tagArr[1]?.split('_') || [];

        if (['width', 'length', 'height'].includes(key)) {
          if (value) {
            dimensions[key] = parseFloat(value);
          }
        } else if (tagArr[1]) {
          const cleanedValue = tagArr[1].replace(/_/g, ' ');

          // try to case bools correctly
          if (cleanedValue.toLowerCase() === 'true') tagsByCategory[tagArr[0]] = true;
          else if (cleanedValue.toLowerCase() === 'false') tagsByCategory[tagArr[0]] = false;
          else tagsByCategory[tagArr[0]] = tagArr[1].replace(/_/g, ' ');
        }
      });

      if (dimensions['length'] && dimensions['width'] && dimensions['height']) tagsByCategory['dimensions'] = dimensions;

      const product: Single = {
        id: id, //decodeBase64(id),
        title: formatTitle(title),
        description,
        descriptionHtml,
        productType: productType,
        tags,
        handle,
        tagsByCategory,
        seo: {
          title: formatTitle(seo.title || title),
          description: seo.description || truncate(description, { length: 256 }),
        },
        images: generateImagesFromEdges(images.edges),
        variants: generateVariantsFromEdges(variants.edges),
        totalInventory: totalInventory ?? 0,
        collections: productByHandle.collections.edges.map(edge => edge.node.handle),
      };
      return product;
    }

    return null;
  }

  export async function getSingle(handle: string): Promise<Single | null> {
    try {
      return getSingleFallible(handle);
    } catch (error) {
      console.log(error);
    }
  }

  export async function getRecommendations(productId: string): Promise<ProductMini[]> {
    if (!productId) {
      return [];
    }
    try {
      const { productRecommendations } = await ShopifyService.getProductRecommendations({ productId });

      if (!productRecommendations) return [];

      return productRecommendations.map((itm) => {
        const tagsByCategory = {};

        itm.tags?.forEach((tag) => {
          const tagArr = tag.replace(/boolean:/g, '').split(':');

          if (tagArr[1]) {
            tagsByCategory[tagArr[0]] = tagArr[1].replace(/_/g, ' ');
          }
        });

        return {
          title: itm.title,
          id: itm.id,
          handle: itm.handle,
          brand: tagsByCategory?.['brand'] ? tagsByCategory?.['brand'] : null,
          images: generateImagesFromEdges(itm.images.edges),
          variants: generateVariantsFromEdges(itm.variants.edges),
          tagsByCategory,
        };
      });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  // Deprecated, uses REST API to pull product recommendations. Not recommended.
  // export const productRecommendations = async (id: string) => {
  //   let config = { method: 'get', url: `${url}/recommendations/products.json?product_id=${id}&intent=related&limit=6`, headers: { "Access-Control-Allow-Origin": "*" } };
  //   const recommendations = await AxiosResponse(config);
  //   return recommendations
  // }
  export interface ListItem {
    id: string;
    url: string;
    title: string;
    description: string;
    image: {
      src: string;
      alt: string;
    };
    price: {
      amount: number;
      currencyCode: CurrencyCode;
    };
  }

  export interface List {
    products: Merge<ListItem, { cursor: string }>[];
    pageInfo: GetProductListQuery['products']['pageInfo'];
  }

  export function getListFromPaginatedProductPage(fragment: PaginatedProductListFragment): List {
    const { edges, pageInfo } = fragment;
    const products: List['products'] = edges.map(({ node, cursor }) => {
      return {
        id: node.id, //decodeBase64(node.id),
        cursor: cursor,
        url: `/products/${node.handle}`,
        title: formatTitle(node.title),
        description: node.description,
        image: {
          src: node.images.edges[0]?.node.url || '',
          alt: node.images.edges[0]?.node.altText || '',
        },
        price: {
          amount: Number(node.priceRange.minVariantPrice.amount),
          currencyCode: node.priceRange.minVariantPrice.currencyCode,
        },
        variants: node.variants.edges.map((variant) => variant.node),
      };
    });

    return { products, pageInfo };
  }

  export async function getList(variables?: GetProductListQueryVariables): Promise<List> {
    const { products } = await ShopifyService.getProductList(variables);
    return getListFromPaginatedProductPage(products);
  }
}
