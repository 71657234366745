import { SocialIcon } from 'react-social-icons'
import { useContext } from 'react';
import { WebviewEventContext } from '../../../context/WebviewEventContext';
import { FacebookMessengerShareButton } from 'react-share';

export const SocialShareButton = ({ url, title, shareButton: ShareButton, iconUrl }) => {
    const { isMobileApp } = useContext(WebviewEventContext);

    return isMobileApp ? null : (
        <div>
            <ShareButton url={url} title={title}>
                <SocialIcon url={iconUrl} bgColor="black" style={{ width: '24px', height: '24px' }} />
            </ShareButton>
        </div>
    );
}