import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { BlogArticleStoryblok } from '../../../component-types-sb';
import { formatDate } from '../../../utils/formatting';
import { Image, Signpost } from '../../core';
import SignpostLink from '../../core/SignpostLink';

const Blog = ({ blok }: BlogArticleStoryblok) => {
  const publishedDate = blok.Published || blok.published_at;

  return (
    <section className="Section PageContent">
      <h1 className="display-24 my-3 px-4 uppercase">{blok.name}</h1>
      <p className="body-14 mb-6 px-4">{formatDate(publishedDate)}</p>
      <Image src={blok.image.filename} alt={blok.image.alt} />

      {blok.body.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      {/* <StoryblokComponent blok={blok.body} /> */}
      {/* <Image src={pageHero} alt={blogTitle} />
      <RichText document={blogContent} className={'body-14 space-y-6 richText px-4'} />
     */}
      <div className="mt-4 px-4">
        <SignpostLink signpostText="Back to blog" href="/blog" iconLeft="arrowLeft" iconRight={null} />
      </div>
    </section>
  );
};

export default Blog;
