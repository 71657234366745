import { storyblokEditable } from '@storyblok/react';
import { Icon } from '../../../../core';
import styles from './styles.module.css';
import { DrawerAnnouncementStoryblok } from './types';
import classNames from 'classnames';
import Link from 'next/link';

interface Props {
  blok: DrawerAnnouncementStoryblok;
}

const DrawerAnnouncement = ({ blok }: Props) => {
  const { url, text, cta_text } = blok || {};

  const cachedURL = url?.cached_url;

  return (
    <div className={classNames(styles.container, 'Section')} {...storyblokEditable(blok)}>
      <p className="body-14">{text}</p>
      {cachedURL && (
        <Link href={'/' + cachedURL} className={classNames(styles.arrow, 'body-14-bold')}>
          {cta_text} <Icon title="arrowRight" className="ml-2" />
        </Link>
      )}
    </div>
  );
};

export default DrawerAnnouncement;
