import React, { useContext } from 'react';
import { CanonicalItem } from '../../../../../../data-fetching/canonicalItem';
import { Button } from '../../../../../core';
import { WishlistContext } from '../../../../../../context/WishlistContext';

import { useCartLine } from '../../../../../../data-fetching/cart/useCartLine';

export const MoveToCartButton: React.FC<{item: CanonicalItem}> = ({item}) => {
    const {toggleItemInWishlist} = useContext(WishlistContext);
    const { addToCart } = useCartLine(item.variant_id);

    const isAvailable = item.in_stock;

    return (
        <Button
          className="w-1/2"
          onClick={async () => {
            if (!addToCart) return;
            await addToCart();
            await toggleItemInWishlist(item);
          }}
          text={isAvailable ? 'move to bag' : 'Sold out'}
          disabled={!isAvailable || !addToCart}
        />
    )
}