import { useContext, useEffect, useState } from 'react';
import { CanonicalItem } from '../../../../../data-fetching/canonicalItem';
import { Icon, LoadingAnimation } from '../../../../core';
import styles from './styles.module.css';
import { useCustomer } from '../../../../account/Payout/useCustomer';
import { Login } from '../../../../account/Authentication/Login';
import { LoginOrRegister } from '../../../../account/Authentication/LoginOrRegister';
import { AccountContext } from '../../../../../context/AccountContext';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    item: CanonicalItem;
}

export const OfferModal: React.FC<Props> = ({ isOpen, onClose, item }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const googleFormUrl = `https://docs.google.com/forms/d/1l_DVE5rL8e0W2z5w7RSgSO0dmESmBk44ZyCl7HhAvkU/viewform?entry.150165209=${item?.sku}&entry.1351906326=${item?.price.toFixed(2)}`;

  return (
    <div className={styles.overlay}>
      <div className={styles.content}>
        <button onClick={onClose} className={styles.closeButton}>
            <Icon title={'close'} color="transparent" />
        </button>
        <LoginOrRegister>
            {isLoading && <div className="mt-40 flex"><LoadingAnimation className="m-auto" /></div>}
            <iframe
              src={googleFormUrl}
              width="100%"
              height="600px"
              className={styles.iframe}
              title="Make an Offer"
              onLoad={(() => setIsLoading(false))}
            ></iframe>
        </LoginOrRegister>
      </div>
    </div>
  );
};