import React, { useContext } from 'react';

import { WebviewEventContext, STATUS } from '../../../context/WebviewEventContext';
import { Logo } from '../Icons/iconList';

import styles from './styles.module.css';

type Props = {};

const PushNotificationsPopup: React.FC<Props> = () => {
  const { setNotificationStatus, setPopupComplete, popupComplete, isFirstSession, showPopup } =
    useContext(WebviewEventContext);

  const handleSelectYes = () => {
    setNotificationStatus(STATUS.ACCEPTED);
    setPopupComplete(true);
  };

  const handleSelectNo = () => {
    // console.log('selected no, setting notification status as declined');

    setNotificationStatus(STATUS.DECLINED);
  };

  // const isFirstSession = true;

  const firstSession = {
    subtitle: 'Seen anything you like?',
    description:
      'Opt in to push notifications to see the latest uploads from the best brands, plus be the first to know when a price drops.',
  };

  const notFirstSession = {
    subtitle: 'Welcome back.',
    description:
      "We hope you're enjoying our app. We'd love to let you know when we have special offers or great new uploads - would you like to opt in to push notifications?",
  };

  return (
    <>
      <div className={styles.container} />
      <div className={styles.innerContainer}>
        <div className={styles.title}>
          <Logo width={130} />
        </div>
        <div className={styles.subtitle}>{isFirstSession ? firstSession.subtitle : notFirstSession.subtitle}</div>
        <div className={styles.description}>
          {isFirstSession ? firstSession.description : notFirstSession.description}
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.acceptBtn} onClick={handleSelectYes}>
            OK
          </div>
          <div className={styles.declineBtn} onClick={handleSelectNo}>
            No, thanks
          </div>
        </div>
      </div>
    </>
  );
};

export default PushNotificationsPopup;
