import React from "react";
import { ModalConsumer } from "./ModalContext";

const ModalRoot = () => {
  return (
    <ModalConsumer>
      {({ components, hideModal }) => {
        const list =
          Object.keys(components).length &&
          Object.keys(components).map((key) => {
            return {
              ...components[key],
              id: key,
            };
          });

        return list
          ? list.map((comp) => {
              const ModalComp = comp.component;
              const ModalProps = comp.props;
              return (
                <ModalComp
                  key={comp.id}
                  {...ModalProps}
                  onRequestClose={(data) => {
                    hideModal(comp.id);
                    if (ModalProps.onClose) {
                      ModalProps.onClose(data);
                    }
                  }}
                  closeTimeoutMS={400}
                />
              );
            })
          : null;
      }}
    </ModalConsumer>
  );
};

export default ModalRoot;
