import { FacebookMessengerShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import 'react-social-icons/facebook';
import 'react-social-icons/whatsapp';
import 'react-social-icons/email';

export const socialMediaPlatforms = [
  // {
  //     name: 'Facebook',
  //     url: 'www.facebook.com',
  //     shareButton: FacebookMessengerShareButton
  //   },
    {
      name: 'WhatsApp',
      url: 'www.whatsapp.com',
      shareButton: WhatsappShareButton
    },
    {
      name: 'Email',
      url: 'www.email.com',
      shareButton: EmailShareButton
    }
];

export const messengerOnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    window.open(
      "fb-messenger://share?link=" +
        encodeURIComponent("https://www.google.com") +
        "&app_id=" +
        encodeURIComponent("521270401588372")
    );
  };