import React from 'react';
import { PayoutsAdditionalContentStoryblok } from '../../../../component-types-sb';
import { render } from 'storyblok-rich-text-react-renderer';

interface Props {
  blok: PayoutsAdditionalContentStoryblok;
}

const PayoutFAQs = ({ blok }: Props) => {

    if (!blok) return null;

    return (
        <div className="flex flex-col gap-2">
            {render(blok.text)}
        </div>
    )
}

export default PayoutFAQs;