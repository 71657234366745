import React, { useState, useEffect, useContext } from 'react';
import classNames from 'classnames';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import { useKeenSlider } from 'keen-slider/react';
import styles from './styles.module.css';
import { CarouselStoryblok } from './types';
import 'keen-slider/keen-slider.min.css';
import { uuidv4 } from '../../../../utils/helpers';
import { useMediaQuery } from 'react-responsive';

import { Icon } from '../../../core';
import { AccountContext } from '../../../../context/AccountContext';

interface Props {
  blok: CarouselStoryblok;
  desktopSlidesPerView: number;
  offsetDotsDesktop: boolean;
  className?: string;
  showDots?: boolean;
  preview?: boolean;
}

const MIN_AUTOPLAY_TIMER_MS = 500;

const getAutoplayOptions = (autoplay: string | undefined) => {
  const autoplayAsNumber = parseFloat(autoplay);

  if (isNaN(autoplayAsNumber) || autoplayAsNumber <= MIN_AUTOPLAY_TIMER_MS) {
    return { config: {}, hooks: [], isAutoplaying: false };
  }

  return {
    isAutoplaying: true,
    config: {
      loop: true,
    },
    hooks: [
      (slider) => {
        let timeout
        let mouseOver = false
        function clearNextTimeout() {
          clearTimeout(timeout)
        }
        function nextTimeout() {
          clearTimeout(timeout)
          if (mouseOver) return
          timeout = setTimeout(() => {
            slider.next()
          }, autoplayAsNumber)
        }
        slider.on("created", () => {
          slider.container.addEventListener("mouseover", () => {
            mouseOver = true
            clearNextTimeout()
          })
          slider.container.addEventListener("mouseout", () => {
            mouseOver = false
            nextTimeout()
          })
          nextTimeout()
        })
        slider.on("dragStarted", clearNextTimeout)
        slider.on("animationEnded", nextTimeout)
        slider.on("updated", nextTimeout)
      },
    ]
  }
}

const useSlidesByLoginState = (slides: CarouselStoryblok["items"]): NonNullable<CarouselStoryblok["items"]> => {
  const { isLoggedInDynamic } = useContext(AccountContext);
  if (typeof slides === 'undefined') return [];
  const indexOfAccountRegistrationSlider = slides.findIndex((slide) => slide.component === 'register_account_partner_step');
  if (indexOfAccountRegistrationSlider === -1) {
    // no requirement to be logged in because there's no register account partner step
    return slides;
  }
  else if (isLoggedInDynamic) {
    // there's a register account partner step, and we're logged in
    return slides.filter(slide => slide.component !== 'register_account_partner_step');
  } else {
    // there's a register account partner step, and we're logged out
    return slides.slice(0, indexOfAccountRegistrationSlider + 1);
  }
}

const Carousel = ({
  blok,
  desktopSlidesPerView = 2,
  offsetDotsDesktop = true,
  showDots = true,
  className,
  preview,
  ...props
}: Props) => {
  const { items, hide_arrows, autoplay } = blok || {};
  const slidesByLoginState = useSlidesByLoginState(items);
  const slides = preview ? items : slidesByLoginState;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderOptions, setSliderOptions] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [galleryId, setGalleryId] = useState('');
  const [dotTransform, setDotTransform] = useState(0);

  const autoplayOptions = getAutoplayOptions(autoplay);
  const [sliderRef, slider] = useKeenSlider({ ...sliderOptions, ...autoplayOptions.config}, [...autoplayOptions.hooks]);

  const isDesktop = useMediaQuery({ query: '(min-width: 48rem)' });

  const sliderCurrent = slider?.current;

  const slidesLength = isDesktop
    ? Math.trunc(sliderCurrent?.slides.length) - (desktopSlidesPerView - 1) + (autoplayOptions.isAutoplaying ? 1 : 0)
    : Math.trunc(sliderCurrent?.slides.length);

  const dynamicDots = slides.length > 5;

  const isIndicator = (index) => {
    if (!dynamicDots) {
      return;
    }

    const hasTransformed = dotTransform < 0;
    const first5 = !hasTransformed;
    const last5 = hasTransformed && currentSlide >= slides.length - 3;

    const prevDotIndex = last5 ? slides.length - 5 : hasTransformed && currentSlide - 2;

    const nextDotIndex = first5 ? 4 : !last5 && currentSlide + 2;

    return (hasTransformed && index <= prevDotIndex) || (!last5 && index >= nextDotIndex);
  };

  const handleNext = () => sliderCurrent?.next();
  const handlePrev = () => sliderCurrent?.prev();

  const initializeSlider = () => {
    setSliderOptions({});
    setTimeout(() => {
      setSliderOptions({
        initial: 0,
        slides: {
          perView: isDesktop ? desktopSlidesPerView : 1,
          spacing: isDesktop ? 16 : 0,
        },
        slideChanged(s) {
          const latest = s.track.details.rel;
          const shouldFollow = dynamicDots && slides.length > latest + 2;

          const transformDots = () => {
            const dotWidth = 26;
            const transform = latest + 1 > 2 ? 0 - dotWidth * (latest - 2) : 0;
            setDotTransform(transform);
          };

          setCurrentSlide(latest);

          if (shouldFollow) {
            transformDots();
          }
        },
      });
      setLoaded(true);
    }, 5);
  };

  // reinitialise the gallery if the slides change or the viewport changes size enough
  const slideIds = slides.map((blok) => blok._uid).join(',');

  useEffect(() => {
    initializeSlider();
    setGalleryId(uuidv4());
  }, [isDesktop, slideIds]);

  return (
    <section
      className={classNames(styles.container, {
        Section: !blok.full_width,
        [className]: className,
        [styles.loaded]: loaded,
      })}
      {...storyblokEditable(blok)}
    >
      <div ref={sliderRef} className={classNames('keen-slider', styles.slider)}>
        {slides?.map((nestedBlok, index) => {
          const isLastSlide = index === slides?.length - 1;
          return (
            <div className={`keen-slider__slide number-slide${index}`} key={`${galleryId}-${nestedBlok._uid}`}>
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                prev={handlePrev}
                next={handleNext}
                isLastSlide={isLastSlide}
                {...props}
              />
            </div>
          );
        })}
      </div>
      {slider && !hide_arrows && (
        <>
          <button className={styles.slidePrev} onClick={handlePrev} disabled={currentSlide === 0}>
            <Icon title="chevronLeft" />
          </button>
          <button className={styles.slideNext} onClick={handleNext} disabled={currentSlide === slidesLength - 1}>
            <Icon title="chevronRight" />
          </button>
        </>
      )}
      {showDots && (
        <div className={classNames(styles.carouselControls, { [styles.carouselControlsOffset]: offsetDotsDesktop })}>
          {sliderCurrent && (
            <div className={styles.carouselDotGroup}>
              <div
                className={classNames(styles.carouselDotWrapper, {
                  [styles.staticDots]: !dynamicDots,
                })}
                style={{ transform: `translateX(${dotTransform}px)` }}
              >
                {slidesLength &&
                  Array.from(Array(slidesLength).keys()).map((idx) => {
                    return (
                      <button
                        key={`${galleryId}-button-${idx}`}
                        onClick={() => {
                          sliderCurrent?.moveToIdx(idx);
                        }}
                        className={classNames(styles.carouselDot, {
                          [styles.active]: currentSlide === idx,
                          [styles.indicator]: isIndicator(idx),
                        })}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Carousel;
