import React, { useContext } from 'react';
import { StoryblokComponent } from '@storyblok/react';
import DrawerHeader from '../DrawerHeader/index';
import styles from './styles.module.css';
import FadeIn from '../../../../transitions/FadeIn';
import { DrawerContext } from '../../../../../context/DrawerContext';
import Shop from '../Shop';

const Categories = ({ items, config }) => {
  const { showDrawer } = useContext(DrawerContext);

  const handleBack = () => showDrawer(Shop as any, { config: config });
  return (
    <FadeIn>
      <div className={styles.container}>
        <DrawerHeader title="Categories" onBack={handleBack} />

        <div className={styles.items}>
          {items?.map((nestedBlok: any) => {
            const showDropdown = nestedBlok.subcategories?.length > 0;
            return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} showDropdown={showDropdown} />;
          })}
        </div>
      </div>
    </FadeIn>
  );
};

export default Categories;
