import { AlgoliaFacet, ProductSearchState, SearchableFacets } from './types';

export const ToAlgoliaFacet = (key: SearchableFacets): AlgoliaFacet => {
  const map = Object.entries(AlgoliaFacet).find(([name, facet]) => name === key);
  if (!map) throw new Error(`Invalid facet ${key}`);
  return map[1];
};

export const toSearchableFacet = (key: AlgoliaFacet): SearchableFacets => {
  const map = Object.entries(AlgoliaFacet).find(([name, facet]) => facet === key);
  if (!map) throw new Error(`Invalid facet ${key}`);
  const k = map[0] as keyof typeof SearchableFacets;
  return SearchableFacets[k];
};

export const toActiveRefinements = (searchState?: ProductSearchState): Partial<ProductSearchState> => {
  if (!searchState) return {};
  const validFacets = Object.keys(SearchableFacets);

  const validEntries = Object.entries(searchState).filter(([facet, refinement]) => {
    if (typeof refinement === 'undefined') return false;
    else return validFacets.includes(facet);
  });
  return Object.fromEntries(validEntries);
}

export const pickABTestedSortIndex = (indexA: string, indexB: string) => {
  return Math.random() < 0.5 ? indexA : indexB;
}
