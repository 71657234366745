import React, { useContext } from 'react';
import { storyblokEditable, StoryblokComponent, ISbStoryData } from '@storyblok/react';
import Signpost from '../../../../core/Signpost';
import styles from './styles.module.css';
import { DrawerItemGridStoryblok } from './types';
import classNames from 'classnames';
import { DrawerContext } from '../../../../../context/DrawerContext';
import Categories from '../Categories/index';
import Brands from '../Brands';

import { ConfigStoryblok } from '../../../../../component-types-sb';

interface Props {
  blok: DrawerItemGridStoryblok;
  config: ISbStoryData<ConfigStoryblok>;
}

const DrawerItemGrid = ({ blok, config }: Props) => {
  const { showDrawer } = useContext(DrawerContext);

  const { title, items, columns, signpost_text, sub_level, remove_padding_top } = blok || {};

  const columnsClass = styles[`items-${columns}`];

  const limitedItems = (items ?? []).slice(0, 3 * (parseInt(columns ?? '1')));

  const SubLevel = sub_level === 'categories' ? Categories : Brands;

  const handleNavigate = () => showDrawer(SubLevel as any, { items: items, config: config });

  return (
    <div className={classNames('Section ', { 'Section--spaced': !remove_padding_top })} {...storyblokEditable(blok)}>
      {title && <h2 className={classNames(styles.title, 'body-16-bold')}>{title}</h2>}
      <div className={classNames(styles.items, columnsClass)}>
        {limitedItems?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
      {signpost_text && <Signpost signpostText={signpost_text} onClick={handleNavigate} />}
    </div>
  );
};

export default DrawerItemGrid;
