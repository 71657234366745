import React from 'react';
import { CategoryType } from './types';
import styles from './styles.module.css';
import { Icon } from '../';
import classNames from 'classnames';

const IconsBlock = ({ title, icons, isList }: CategoryType) => {
  const styleClass = isList ? styles.iconList : styles.iconBlock;
  const gridClass = isList ? styles.iconListGrid : styles.iconGridBlock;
  return (
    <section className={styles.container}>
      <div className={gridClass}>
        {icons.map((icon: any, index: number) => (
          <div key={index} className={styleClass}>
            {icon.title && <Icon title={icon.title} className="mr-3" width={26} height={26} />}
            <p className={classNames(styles.text, 'body-12 sm:body-14')}>{icon.text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default IconsBlock;
