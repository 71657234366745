import React from 'react';
import { storyblokEditable } from '@storyblok/react';

import { Button, Image, RichText } from '../../../core';

import styles from './styles.module.css';
import PartnerHeroSplitStoryblok from './types';

import classNames from 'classnames';

interface Props {
  blok: PartnerHeroSplitStoryblok;
}

const PartnerHeroSplit = ({ blok }: Props) => {
  const { content, image, link_text, link_url } = blok || {};

  const imgSrc = image?.filename;
  const cachedURL = link_url?.cached_url;

  return (
    <section className={styles.container} {...storyblokEditable(blok)}>
      <div className={styles.content}>
        <RichText className={classNames(styles.text)} document={content} />
        <Button text={link_text} href={cachedURL} isSecondary className={styles.link} />
      </div>
      <div className={styles.imageContainer}>{imgSrc && <Image src={imgSrc} alt="" className={styles.image} />}</div>
    </section>
  );
};

export default PartnerHeroSplit;
