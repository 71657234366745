import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { ComponentIconsStoryblok, IconGridStoryblok } from '../../../../component-types-sb';

interface Props {
  blok: IconGridStoryblok;
}

const IconGrid = ({ blok }: Props) => {
  const { Row, Icons, Background } = blok;
  return (
    <div className="w-full relative flex flex-row" {...storyblokEditable(blok)}>
      {Icons?.map((nestedBlok: ComponentIconsStoryblok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  );
};

export default IconGrid;
