// todo: fetch these from taxonomy in core 

export const departmentDisplayNames = {
    mens: 'Mens',
    womens: 'Womens',
};

export const categoryDisplayNames = {
    accessories: 'Accessories',
    activewear_bottoms: 'Activewear Bottoms',
    'activewear_hoodies_&_sweatshirts': 'Activewear Hoodies & Sweatshirts',
    activewear_tops: 'Activewear Tops',
    bottoms: 'Bottoms',
    'coats_jackets_&_waistcoats': 'Coats Jackets & Waistcoats',
    dresses: 'Dresses',
    footwear: 'Footwear',
    'jumpers_&_cardigans': 'Jumpers & Cardigans',
    'jumpsuits_&_playsuits': 'Jumpsuits & Playsuits',
    maternity: 'Maternity',
    'shirts_&_tops': 'Shirts & Tops',
    skirts: 'Skirts',
    suits: 'Suits',
};

export const typeDisplayNames = {
    bag: 'Bag',
    belt: 'Belt',
    blazer: 'Blazer',
    blouse: 'Blouse',
    boot: 'Boot',
    bottoms: 'Bottoms',
    cape: 'Cape',
    cardigan: 'Cardigan',
    casual_shirt: 'Casual Shirt',
    casual: 'Casual',
    coat: 'Coat',
    dress_shoe: 'Dress Shoe',
    dress: 'Dress',
    flat: 'Flat',
    formal_shirt: 'Formal Shirt',
    formal_trousers: 'Formal Trousers',
    gloves: 'Gloves',
    hat: 'Hat',
    heel: 'Heel',
    hoodie: 'Hoodie',
    jacket: 'Jacket',
    jeans: 'Jeans',
    jumper: 'Jumper',
    jumpsuit: 'Jumpsuit',
    leggings: 'Leggings',
    maxi_dress: 'Maxi Dress',
    maxi_skirt: 'Maxi Skirt',
    midi_dress: 'Midi Dress',
    midi_skirt: 'Midi Skirt',
    mini_dress: 'Mini Dress',
    mini_skirt: 'Mini Skirt',
    playsuit: 'Playsuit',
    polo: 'Polo',
    poncho: 'Poncho',
    purse: 'Purse',
    sandal: 'Sandal',
    scarf: 'Scarf',
    shirt: 'Shirt',
    shorts: 'Shorts',
    suit: 'Suit',
    sunglasses: 'Sunglasses',
    sweatpants: 'Sweatpants',
    sweatshirt: 'Sweatshirt',
    t_shirt: 'T Shirt',
    tank: 'Tank',
    ties: 'Ties',
    top: 'Top',
    tops: 'Tops',
    trainer: 'Trainer',
    trousers: 'Trousers',
    waistcoat: 'Waistcoat',
    wallet: 'Wallet',
};

export const sizeDisplayString = {
    chest: 'Chest',
    collar: 'Collar',
    eu_dress: 'EU',
    eu_shoe: 'EU Shoe',
    height: 'Height',
    italy_dress: 'Italy',
    leg: 'Leg',
    length: 'Length',
    lwh: 'LWH',
    one_size: 'One Size',
    sml: 'SML',
    uk_dress: 'UK',
    uk_shoe: 'UK Shoe',
    us_dress: 'US',
    us_shoe: 'US Shoe',
    waist: 'Waist',
    width: 'Width',
};

export const SIZES = {
    sml: {
        type: 'enum',
        values: ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl'],
    },
    uk_dress: { type: 'even_number', min: 2, max: 30 },
    us_dress: { type: 'even_number', min: 0, max: 26 },
    italy_dress: { type: 'even_number', min: 32, max: 60 },
    eu_dress: { type: 'even_number', min: 30, max: 58 },
    chest: { type: 'number', min: 32, max: 66 },
    waist: { type: 'number', min: 22, max: 48 },
    leg: { type: 'number', min: 22, max: 38 },
    uk_shoe: { type: 'half_number', min: 2, max: 14 },
    us_shoe: { type: 'half_number', min: 4, max: 15 },
    eu_shoe: { type: 'half_number', min: 35, max: 46 },
    collar: { type: 'half_number', min: 12, max: 22 },
    one_size: { type: 'boolean' },
};