import { useSimpleForm } from '../useForm';
import React, { FormEventHandler, useContext, useEffect } from 'react';
import Link from 'next/link';
import { Button } from '../../core';
import { AccountContext } from '../../../context/AccountContext';
import styles from './styles.module.css'

type LoginFormContent = {
  email: string,
  password: string,
  passwordVisible: boolean,
}

const DefaultForm: LoginFormContent = {
  email: '',
  password: '',
  passwordVisible: false
}

type Props = {
  redirect?: string
}

export const Login: React.FC<Props> = ({ redirect }) => {
  const {formManager, formData} = useSimpleForm(DefaultForm);

  const { loginShopifyCustomer, isCustomerLoading, shopifyCustomerError, setShopifyCustomerError } = useContext(AccountContext);

  const togglePasswordVisibility = () => {
    formManager.passwordVisible.update(!formManager.passwordVisible.state);
  };

  useEffect(() => {
    setShopifyCustomerError(null);

    if (formManager.password.state === '') {
      formManager.passwordVisible.update(false);
    }
  }, [formManager.password.state]);


  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (isCustomerLoading) return;
    loginShopifyCustomer(
      formData.email,
      formData.password,
      redirect,
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-2 mb-4">
        <label className="body-14-bold" htmlFor="email">
          Email
        </label>
        <input type="email" id="email" placeholder="example@example.com" required value={formManager.email.state} onChange={(e) => formManager.email.update(e.target.value)} />
      </div>

      <div>
        <div className="flex flex-col gap-2">
          <label className="body-14-bold" htmlFor="password">
            Password
          </label>
          <div className="flex flex-col gap-0">
            { !formManager.passwordVisible.state ? <input
                id='password'
                type={'password'}
                placeholder="*********"
                required
                autoComplete={'current-password'}
                value={formManager.password.state}
                onChange={(e) => formManager.password.update(e.target.value)}
              /> :
              <input
                id='password'
                type={'text'}
                required
                autoComplete={'off'}
                value={formManager.password.state}
                onChange={(e) => formManager.password.update(e.target.value)}
              />
            }
          </div>
          <div className="flex flex-row items-center justify-between mb-4 mt-0">
            <Link className="body-14-underline text-digitalBlue" href="/forgot-password">
              Forgot Password?
            </Link>
            {formManager.password.state && (
              <button
                className="body-14-underline text-digitalBlue"
                type="button"
                onClick={togglePasswordVisibility}
              >
                {formManager.passwordVisible.state ? 'Hide' : 'Show'}
              </button>
            )}
          </div>
        </div>
      </div>

      {shopifyCustomerError && <p className="bg-brandRed25 p-2 body-12 mb-4">{shopifyCustomerError}</p>}

      <Button isLoading={isCustomerLoading} loadingText="Loading..." text="login" className="w-full" />
    </form>
  )
}