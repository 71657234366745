import styles from '../styles.module.css';
import Link from "next/link";
import classNames from "classnames";
import { appendBasePath } from '../utils/appendBasePath';
import { NavLinkStoryblok } from '../../../../../component-types-sb';

interface Props {
    categories: NavLinkStoryblok[];
    selectedTab: string | null;
    basePath: string;
}

export const StandardHeader: React.FC<Props> = ({ categories, selectedTab, basePath }) => {
    if (!categories) return null;

    return (
        <nav className={`${styles.CategoriesHeader}`}>
            <div className="Container">
                <div className="Section">
                    <div className="flex justify-between">
                        <div className={styles.Categories}>
                            {categories.map((category) => (
                                <Link
                                    key={category.text}
                                    href={appendBasePath(basePath, category.url?.url)}
                                    className={classNames(
                                        styles.Category,
                                        { [styles.ActiveTab]: category.url?.url === selectedTab }
                                    )}
                                >
                                    {category.text}
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
}