import React from 'react';
import { ProductSearchState, SearchableFacets } from '../queries/types';
import { asArray } from '../../../utils/collectionUtils';
import { toStr } from '../../../utils/stringUtils';
import { facetRefinementToDisplayString, searchableFacetToDisplayString } from './utils';

export const ListFacets: React.FC<{
  onSelect: (facet: SearchableFacets) => void,
  facets: SearchableFacets[],
  searchState: ProductSearchState
}> = ({ onSelect, facets, searchState }) => {

  return (
    <>
      {facets.map( (facet) =>
        <FacetSelector key={facet} facet={facet} onSelect={onSelect} searchState={searchState} />
      )}
    </>
  );
};

const FacetSelector: React.FC<{
  onSelect: (facet: SearchableFacets) => void,
  facet: SearchableFacets,
  searchState: ProductSearchState
}> = ({ onSelect, facet, searchState }) => {
  const activeRefinements = asArray(searchState[facet]);

  return (
    <li className={'flex'} key={facet}>
      <button onClick={() => onSelect(facet)}
              className={'font-bold text-sm py-4 px-2 border-b-1 border-gray-300 w-full text-left overflow-auto'}
      >
        {searchableFacetToDisplayString(facet)}
        <div className={'font-normal body-12'}>
          {activeRefinements
            .map(toStr)
            .map(facetRefinementToDisplayString)
            .join(', ')
          }
        </div>
      </button>
    </li>
  );
}