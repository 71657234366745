import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import React, { PropsWithChildren } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'



const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0,
    },
  },
});

const isServerSide = () => {
  return typeof window === 'undefined';
}

export const getQueryClient = (): QueryClient => {
  if (isServerSide()) {
    return new QueryClient({
      defaultOptions: {
        queries: {
          gcTime: 0,
          staleTime: 0,
        },
      },
    });
  } else {
    return queryClient;
  }
}

const tryGetWindow = () => {
  try {
    return window;
  } catch (e) {
    console.log('disabled')
    return { localStorage: null };
  }
}

const persister = createSyncStoragePersister({
  storage: tryGetWindow().localStorage,
})

persistQueryClient({
  queryClient: getQueryClient(),
  persister: persister,
  maxAge: Infinity,
})

export const ReactQueryProvider: React.FC<PropsWithChildren<{ hideDevTools?:boolean }>> = ({children, hideDevTools}) => {
  const showDevTools = !hideDevTools;
  return (
    <PersistQueryClientProvider client={getQueryClient()} persistOptions={{ persister }}>
      {children}
      {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </PersistQueryClientProvider>
  )
}