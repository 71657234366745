import { useEffect, useRef } from 'react';

export const useScrollbar = () => {
    const scrollbarRef = useRef<HTMLDivElement>(null);
    const scrollbarSliderRef = useRef<HTMLDivElement>(null);

    const handleScroll = () => {
        const { current: scrollbar } = scrollbarRef;
        const { current: scrollbarSlider } = scrollbarSliderRef;
        if (scrollbar && scrollbarSlider) {
            const maxScrollLeft = scrollbar.scrollWidth - scrollbar.offsetWidth;
            const target = scrollbar.offsetWidth - scrollbarSlider.offsetWidth;
            const scrollPercentage = scrollbar.scrollLeft / maxScrollLeft;

            scrollbarSlider.style.transform = `translateX(${target * scrollPercentage}px)`;
        }
    };

    useEffect(() => {
        const { current: scrollbar } = scrollbarRef;

        if (scrollbar) {
            scrollbar.addEventListener('scroll', handleScroll);
            return () => {
                scrollbar.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return { scrollbarRef, scrollbarSliderRef };
};
