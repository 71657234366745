import React, { useContext, useEffect, useState } from 'react';
import { Image } from '../../core';

import { IntlUtility } from '../../../helpers';

import styles from './styles.module.css';
import classNames from 'classnames';
import { ArrowRight, HeartWishlistIcon, HeartWishlistIconRemove } from '../../core/Icons/iconList';
import Link from 'next/link';
import { toShortTitle } from '../../../utils/formatting';
import { CanonicalItem } from '../../../data-fetching/canonicalItem';
import { WishlistContext } from '../../../context/WishlistContext';
import { useShopifyItem } from '../../../data-fetching/useShopifyItem';
import { ItemCalloutType, useItemCallouts } from '../../searching/useItemCallouts';
import {
  daysDiffToString,
  daysRemainingForOffer,
  offerHasExpired,
  useActiveOfferForItem,
} from '../../../data-fetching/useActiveOffers';
import { useShopifyAccessToken } from '../../../data-fetching/useShopifyCustomer';
import { createCheckoutUrl } from '../utils';
import { useRouter } from 'next/router';

interface Props {
  item: CanonicalItem;
}

export const IndependentSimpleItemCard: React.FC<{handle: string}> = ({handle}) => {
  const { item } = useShopifyItem(handle);
  if (!item) return null;
  return <Index item={item}/>
}

export const Index: React.FC<Props> = ({ item }) => {
  return (
    <>
      <Link href={`/products/${item.handle}`}>
        <div className={classNames({
          [styles.designerImageContainer]: item.brand_type === 'designer',
          [styles.imageContainer]: item.brand_type !== 'designer',
        })}>
          <Image
            src={item.photos[0]}
            alt={item.title}
            loading="lazy"
            width={400}
            height={400}
            className={styles.image}
          />
          <Callout item={item} />
          <WishlistToggle item={item} />
        </div>
        <h3 className={classNames(styles.title, 'body-14')}>{toShortTitle(item)}</h3>
        <p className={styles.description}>{item.size.display}</p>
        <PriceRenderer item={item} />
      </Link>
    </>
  );
};


const Callout: React.FC<Props> = ({item}) => {
  const callouts = useItemCallouts(item);
  const callout = callouts.find(callout => [ItemCalloutType.Discount, ItemCalloutType.Promo, ItemCalloutType.Designer].includes(callout.type));
  if (!callout) return null;
  return (
    <div className={`absolute top-0 right-0 ${callout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${callout.colour} py-1 px-1 text-xs uppercase font-bold`}
  >
    {callout.label}
  </div>
  )
}

const WishlistToggle: React.FC<Props> = ({item}) => {
  const { toggleItemInWishlist, itemIsInWishlist } = useContext(WishlistContext);

  const isInWishlist = itemIsInWishlist(item);

  return (
    <div
      className={styles.wishlist}
      onClick={async (ev) => {
        ev.preventDefault();
        toggleItemInWishlist(item);
      }}
    >
      {isInWishlist ? <HeartWishlistIconRemove title="heart" /> : <HeartWishlistIcon title="heart" />}
    </div>);
}


export const PriceRenderer: React.FC<Props> = ({item}) => {
  const [checkoutUrl, setCheckoutUrl] = useState<string | null>(null);
  const router = useRouter();
  const formattedPrice = IntlUtility.formatPriceSimple(item.price);
  const formattedStrikethroughPrice = item.strikethrough_price ? IntlUtility.formatPriceSimple(item.strikethrough_price) : null;
  const offer = useActiveOfferForItem(item.sku);
  const customerAccessToken = useShopifyAccessToken();

  const handleCheckout = async () => {
    if (!customerAccessToken) return;

    const url = await createCheckoutUrl({ variant_id: item.variant_id }, customerAccessToken);
    setCheckoutUrl(url);
  };

  useEffect(() => {
    if (checkoutUrl) {
      router.push(checkoutUrl);
    }
  }, [checkoutUrl]);

  
  if (offer && !offerHasExpired(offer)) {
    const promo = `Offer expires ${daysDiffToString(daysRemainingForOffer(offer))}`;
    return (<p className={classNames(styles.subtitle, 'body-14-bold')}>
      {IntlUtility.formatPriceSimple(offer.offer_price)}
      <span className='line-through pl-1 text-black50'>{formattedPrice}</span>
      {formattedStrikethroughPrice ? (
        <span className='line-through pl-1 text-black50'>{formattedStrikethroughPrice}</span>) : null}
      <br/> <span className="flex justify-between"><span className={'text-brandRed'}>{promo}</span>
      <span className="mr-1 body-16 flex whitespace-nowrap gap-2 items-center text-black hover:underline">
        <button onClick={handleCheckout}><i>BUY NOW</i></button><ArrowRight />
      </span></span>
    </p>);
  } else {
    return (<p className={classNames(styles.subtitle, 'body-14-bold')}>
      {formattedPrice}
      {formattedStrikethroughPrice ? (<span className="line-through pl-1 text-black50">{formattedStrikethroughPrice}</span>) : null}
    </p>);
  }

}
