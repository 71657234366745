import { isNullOrUndefined } from '../utils/objectHelpers';
import { SKU } from '../types/payout';
import { useShopifyCustomer } from './useShopifyCustomer';
import { fetchShopifyItemsBySKU } from './useShopifyItem';
import { useQuery } from '@tanstack/react-query';

export type Offer = {
  offer_price: number,
  // date-only string for the final date that the offer is valid
  expires_on: string,
}
export type Offers = Record<SKU, Offer>

const one_day_in_milliseconds = 1000 * 60 * 60 * 24;

export const useActiveOffers = (): Offers | null => {
  const { shopifyCustomer } = useShopifyCustomer();
  if (isNullOrUndefined(shopifyCustomer)) return null;
  try {
    return JSON.parse(shopifyCustomer?.customer?.active_offers?.value ?? 'null');
  } catch (err) {
    if (err instanceof Error) {
      console.error(err?.message);
      console.error(err?.stack);
    }
  }
  return null;
};

export const useActiveOfferForItem = (sku: SKU | undefined | null): Offer | null => {
  const allActiveOffers = useActiveOffers();
  if (!sku) return null;
  const offer = allActiveOffers ? allActiveOffers[sku] : null;
  if (!offer) return null;
  if (!shouldShowOffer(offer)) return null;
  return offer;
}

export const daysRemainingForOffer = (offer: Offer) => {
  const expiryDate = new Date(offer.expires_on);
  const today = new Date(new Date().toISOString().slice(0, 10)); // date part only
  return Math.floor((expiryDate.getTime() - today.getTime()) / one_day_in_milliseconds);
};

export const offerHasExpired = (offer: Offer) => {
  return daysRemainingForOffer(offer) < 0;
}

export const shouldShowOffer = (offer: Offer) => {
  if (offer.offer_price < 0) return false;
  const daysRemaining = daysRemainingForOffer(offer);
  if (daysRemaining < -2) return false;
  if (daysRemaining > 60) return false;
  return true;
};

export const daysDiffToString = (daysDifference: number): string => {
  if (daysDifference <= -2) return `${-1 * daysDifference} days ago`;
  if (daysDifference === -1) return `last night`;
  if (daysDifference === 0) return `tonight`;
  if (daysDifference === 1) return `tomorrow night`;
  if (daysDifference >=2) return `in ${daysDifference} days`;
  return '';
}

export const useProductsWithActiveOffers = (limit?: number) => {
  const activeOffers = useActiveOffers() ?? {};

  const fetchProducts = async () => {
    if (!activeOffers) return null;
    const activeOfferProductsSkus = Object.keys(activeOffers)
      .filter(sku => !offerHasExpired(activeOffers[sku]))
      .slice(0, limit);
    
    if (activeOfferProductsSkus.length > 0) {
      return await fetchShopifyItemsBySKU({ skus: activeOfferProductsSkus });
    } else {
      return null;
    }
  };

  const { data: productsWithActiveOffer = [], isLoading, isError, error, isPending, status } = useQuery({
    queryKey: ['productsWithActiveOffers', activeOffers],
    queryFn: fetchProducts,
    staleTime: 0,
    gcTime: 0,
    enabled: !!activeOffers,
  });

  return { productsWithActiveOffer, loading: isPending || isLoading, status, error: isError ? error.message : null };
};