import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { PartnerFaqSectionStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: PartnerFaqSectionStoryblok;
}

const PartnerFAQSection = ({ blok }: Props) => {
  const { heading, items } = blok || {};

  return (
    <div className="bg-white relative mb-16" {...storyblokEditable(blok)}>
      <h2 className="display-24 mb-10">{heading}</h2>
      <div>
        {items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </div>
  );
};

export default PartnerFAQSection;
