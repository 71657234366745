import classNames from 'classnames';
import { animateScroll } from 'react-scroll';

import Icon from '../Icons';
import styles from './styles.module.css';

const BackToTop = ({ text = 'Back to top' }) => {
  const scrollToTop = () => animateScroll.scrollToTop();
  return (
    <section className="Section">
      <button onClick={scrollToTop} className={styles.container}>
        <Icon title="arrowUp" className={styles.icon} />
        <span className={classNames('body-12-bold-caps', styles.text)}>{text}</span>
      </button>
    </section>
  );
};

export default BackToTop;
