
import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';

import styles from './styles.module.css';
import { CheckIcon, ChevronDown } from '../../core/Icons/iconList';
import { ProductSearchState } from '../queries/types';
import { algoliaIndices } from '../../../utils/constants';

import { useSearchUpdater } from '../queries/useSearchUpdater';
import { pickABTestedSortIndex } from '../queries/utils';

export const ALGOLIA_SORT_A_B_TEST_OPTIONS = {
  A: algoliaIndices.shopifyProducts,
  B: algoliaIndices.shopifyProductsPersonalisationTests
}

const sortTitleOptions = [
  {
    // default index shopify_products was set up to sort loosely by newest (after relevance) - replicas employ other sorting strategies
    // primary custom ranking attribute is relevance, secondary is item created_at date
    // currently we define multiple sorts to allow AB testing of Algolia indexes
    sorts: [ALGOLIA_SORT_A_B_TEST_OPTIONS.A, ALGOLIA_SORT_A_B_TEST_OPTIONS.B],
    title: "Recommended",
    default: true,
  },
  // other sort options use strict ranking criteria (above relevance)
  {
    sorts: [algoliaIndices.shopifyProductsNewest],
    title: "Newest",
    default: false,
  },
  {
    sorts: [algoliaIndices.shopifyProductsPriceAsc],
    title: "Price (low to high)",
    default: false,
  },
  {
    sorts: [algoliaIndices.shopifyProductsPriceDesc],
    title: "Price (high to low)",
    default: false,
  }
]

export const SortResultsDropdown: React.FC<{ searchState: ProductSearchState}> = ({ searchState }) => {
  const searchUpdater = useSearchUpdater();

  const dropdownTitle = sortTitleOptions.find(option => searchState.sort && option.sorts.includes(searchState.sort))?.title ?? "Sort"

  const applyOption = (option: typeof sortTitleOptions[0]) => {
    // currently A/B testing different Algolia sort indexes
    const sortToApply = option.sorts.length === 2 ? pickABTestedSortIndex(option.sorts[0], option.sorts[1]) : option.sorts[0]
    searchUpdater({
      ...searchState,
      sort: sortToApply,
    })
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className={classNames('body-12-bold-caps', styles.dropdownButton)}>
        {dropdownTitle}
        <ChevronDown width={14} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.dropdownItems}>
        {sortTitleOptions?.map((option) => {
          return (
            <DropdownMenu.Item
              className={classNames('body-12-bold-caps', styles.dropdownItem)}
              onSelect={() => applyOption(option)}
              key={option.title}
            >
              <span>{option.title}</span>
              {(option.title === dropdownTitle || !dropdownTitle && option.default) && (
                <span>
                  <CheckIcon width={14} />
                </span>
              )}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
