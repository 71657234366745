import React, { PropsWithChildren, useRef } from 'react';
import Image from 'next/image';
import { useMousePositionInContainer } from '../../util/UseMousePositionInContainer';

type Props = PropsWithChildren<{
 src: string;
 disabled?: boolean;
}>

type Position = {
  x: number,
  y: number,
  xOffset: number,
  yOffset: number,
  width: number,
  height: number,
  zoom: number,
}

export const PinchPanZoom:React.FC<PropsWithChildren<{ maxZoom: number }>> = ({children, maxZoom}) => {
  const containerRef = useRef<null | HTMLDivElement>(null);


  return <div className={'relative h-full w-full overflow-hidden'} ref={containerRef}>
      {children}
  </div>
}

export const ImageZoom: React.FC<Props> = ({ children, src, disabled }) => {
  const containerRef = useRef<null | HTMLDivElement>(null);
  const position = useMousePositionInContainer(containerRef);
  const maxZoom = 3.5;

  const transform = !position ? undefined : `translate(${-position.xPc*100}%, ${-position.yPc*100}%) translate(${position.xPc * position.width}px, ${position.yPc * position.height}px)`
  const width = (position?.width ?? 400) * maxZoom;
  const height = (position?.height ?? 400) * maxZoom;
  return (
    <div ref={containerRef} className="relative object-contain h-full overflow-hidden">
      <PinchPanZoom maxZoom={maxZoom}>
        { children }
      </PinchPanZoom>
      {disabled ? null : (
        <Image src={src} width={width} height={height} alt={"Zoomed product photo"} style={{ position:"absolute", left: 0, top: 0, willChange: "transform", transform, maxWidth: "none", opacity: transform ? 1 : 0, pointerEvents: "none" }} />
      )}
    </div>
  )
}