import React, { useContext } from 'react';
import { CanonicalItem } from '../../../../data-fetching/canonicalItem';
import { Button } from '../../../core';

import { useCartLine } from '../../../../data-fetching/cart/useCartLine';

interface Props {
    item: CanonicalItem;
    isLoading: boolean;
    handleAddToCart: null | (() => Promise<void>);
}

export const AddToCartButton: React.FC<Props> = ({ item, isLoading, handleAddToCart }) => {
    const { isInCart, addToCart } = useCartLine(item.variant_id);

    const isOutOfStock = !item?.in_stock;
    const disableAddToCart = isInCart || isLoading || isOutOfStock;

    return (
        <Button
            className="justify-center grow w-full"
            isLoading={isLoading}
            loadingText="Adding to bag"
            text={isInCart ? 'Added to bag' : isOutOfStock ? 'Sold out' : 'Add to bag'}
            disabled={disableAddToCart}
            onClick={async (e: any) => {
                e.preventDefault();
                if (handleAddToCart) {
                  return handleAddToCart();
                }
            }}
        />
    );
};