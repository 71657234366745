import classNames from 'classnames';
import { Filter, Tick } from '../../core/Icons/iconList';
import styles from './styles.module.css';
import { ProductSearchState } from '../../searching/queries/types';
import { toActiveRefinements } from '../../searching/queries/utils';

const FilterButton = ({ onClick, searchState }: { onClick: VoidFunction, searchState: ProductSearchState }) => {
  const activeRefinements = toActiveRefinements(searchState);
  return (
    <button onClick={onClick} className={classNames('body-12-bold-caps', styles.button)}>
      Filter
      <span className="flex items-center">{Object.keys(activeRefinements).length > 0 ? <Tick className="ml-1" /> : <Filter width={15} />}</span>
    </button>
  );
};

export default FilterButton;
