import React, { useContext } from 'react';
import styles from './styles.module.css';
import DrawerHeader from '../DrawerHeader/index';
import { DrawerContext } from '../../../../../context/DrawerContext';
import { Cart as CartContent } from '../../../../sections/Cart/Cart';
import { usePrimaryCart } from '../../../../../data-fetching/cart/usePrimaryCart';

const Cart = () => {
  const { hideDrawer } = useContext(DrawerContext);

  const handleBack = () => hideDrawer();

  const { cart } = usePrimaryCart();

  const cartCount = cart?.lines?.length || 0;

  return (
    <div className={styles.drawerWrapper}>
      <div className={styles.drawerHeader}>
        <DrawerHeader title="Bag" subtitle={`${cartCount} items`} onBack={handleBack} />
      </div>

      <CartContent />
    </div>
  );
};

export default Cart;
