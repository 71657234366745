import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';

const Page = ({ blok }) => {
  const announcement = blok?.announcement || [];
  const items = blok?.body || [];

  return (
    <>
      {announcement.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      <main className={classNames('Container', { ['Page']: blok.grid_layout })} {...storyblokEditable(blok)}>
        {items.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </main>
    </>
  );
};

export default Page;
