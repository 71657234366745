import { useContext } from "react";
import { WishlistContext } from '../../../../context/WishlistContext';
import { WatchlistStoryblok } from "./types";
import { IndependentWishlistItemCard } from "../Drawer/Wishlist/List";
import styles from './styles.module.css';
import classNames from "classnames";
import { Signpost } from "../../../core";
import { Skeleton } from "../../../account/Common/Skeleton";
import { useScrollbar } from "./useScrollbar";
import { useProductsWithActiveOffers } from "../../../../data-fetching/useActiveOffers";
import { getWatchlistHandlesWithActiveOfferProductsRemoved } from "./utils";

interface Props {
    blok: WatchlistStoryblok;
}

export const Watchlist = ({ blok }: Props) => {
    const { wishlist, wishlistLoading } = useContext(WishlistContext);
    const { text } = blok || {};
    const { scrollbarRef, scrollbarSliderRef } = useScrollbar();
    const { productsWithActiveOffer, loading: productsLoading } = useProductsWithActiveOffers(5);

    const filteredWatchlistItems = getWatchlistHandlesWithActiveOfferProductsRemoved(wishlist, productsWithActiveOffer ?? []);

    if (!filteredWatchlistItems) return null;

    return (
        <section
        className={classNames('Section')}
        >
            {wishlistLoading || productsLoading ?
            <div className="flex flex-row mr-5 mt-13 justify-center items-center gap-12">
                <Skeleton height={280} width={600} />
                <Skeleton height={280} width={600} />
                <Skeleton height={280} width={600} />
                <Skeleton height={270} width={600} />
                <Skeleton height={270} width={600} />
            </div> :
            <div className={styles.container}>
                {text && <h3 className={classNames(styles.title, 'display-18-caps')}>{text}</h3>}
                <div className="relative">
                    <div className={styles.products} ref={scrollbarRef} >
                        {filteredWatchlistItems.map((itemHandle) => {
                        return <IndependentWishlistItemCard key={itemHandle} handle={itemHandle}
                        className={styles.card} />;
                        })}
                    </div>
                    <div className={styles.slider}>
                        <div ref={scrollbarSliderRef} className={styles.sliderHandle} />
                    </div>
                </div>
                <div className="flex relative justify-start mb-2 mt-2 md:mt-0 mb-6 md:mb-4">
                    <Signpost href="/wishlist" signpostText="See all"></Signpost>
                </div>
            </div>}
      </section>
    )
}