import React from 'react';
import { storyblokEditable } from '@storyblok/react';
import { AutodonatePartnerStepStoryblok } from '../../../../component-types-sb';
import { Button, RichText } from '../../../core';
import { setAutoDonatePreference } from '../../../account/AutoDonate';
import styles from './styles.module.css';
import { AsyncButton } from '../../../core/Button';

interface Props {
    blok: AutodonatePartnerStepStoryblok;
    next: () => void;
}

export const AutodonatePartnerStep: React.FC<Props> = ({ blok, next }: Props) => {
    const { content, charity_id, cta_text, button_colour } = blok || {};

    return (
        <div className="w-full h-full relative p-10 flex flex-col items-center gap-2" {...storyblokEditable(blok)}>
            {content && <RichText className={styles.content} document={content} />}
            {cta_text && <AsyncButton text={cta_text} button_colour={button_colour} onClick={() => setAutoDonatePreference(charity_id).then(() => next())}/>}
        </div>
    )
}

export default AutodonatePartnerStep;