import { storyblokEditable } from '@storyblok/react';
import styles from './styles.module.css';
import { FeatureStoryblok } from './types';
import RichText from '../../../core/RichText/index';
import Image from '../../../core/Image';
import classNames from 'classnames';
interface Props {
  blok: FeatureStoryblok;
  className?: string;
  enlarge_item?: boolean;
}

const Feature = ({ blok, className, enlarge_item }: Props) => {
  const { image, item, description, alignment } = blok || {};

  const imgSrc = image?.filename;

  return (
    <div
      className={classNames(styles.container, {
        [styles.imageRight]: alignment === 'right',
        [styles.enlargeItem]: enlarge_item === true,
        [className]: className,
      })}
      {...storyblokEditable(blok)}
    >
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="feature" />}
      <div className={styles.textContainer}>
        {item && <h4 className={classNames(styles.title, 'body-14-bold')}>{item}</h4>}
        {description && <RichText document={description} className={classNames('body-14', styles.description)} />}
      </div>
    </div>
  );
};

export default Feature;
