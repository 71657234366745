import React from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import { Image } from '../../core';
import { PartnerPageStoryblok } from '../../../component-types-sb';

type Props = {
  blok: PartnerPageStoryblok,
  preview?: boolean,
}

const PartnerPage: React.FC<Props> = ({ blok, preview }) => {
  const { logo, body, variant_id } = blok;
  const items = body || [];
  const imgSrc = logo?.filename;

  return (
    <>
      <div className="m-auto p-10 border-b border-black">
        <Image src={imgSrc} alt="partner logo" className="m-auto max-w-full" style={{ width: 400 }} />
      </div>
      <main className={classNames('Container', { ['Page']: blok.grid_layout })} {...storyblokEditable(blok)}>
        {items.map((nestedBlok: any) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            desktopSlidesPerView={1}
            showDots={false}
            variantID={variant_id}
            preview={preview}
          />
        ))}
      </main>
    </>
  );
};

export default PartnerPage;
