import { useState, useEffect } from "react";
import { NavLinkStoryblok } from "../../../../component-types-sb";
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { useRouter } from "next/router";
import useSticky from "./utils/useSticky.tsx";
import { StickyHeader } from "./StickyHeader";
import { StandardHeader } from "./StandardHeader";

interface Props {
  categories: NavLinkStoryblok[];
}

export const CategoriesHeader: React.FC<Props> = ({ categories }) => {
  const { basePath } = useContext(SessionContext);
  const isSticky = useSticky();
  const router = useRouter();

  const [selectedTab, setSelectedTab] = useState<string | null>(null);

  useEffect(() => {
    const selectedCategory = categories?.find(category => router.asPath.endsWith(category.url?.url));
    if (selectedCategory) {
      setSelectedTab(selectedCategory.url?.url);
    }
  }, [basePath, categories]);

  if (!categories) return null;

  return (
    <div>
      {isSticky ? (
        <StickyHeader 
          categories={categories} 
          selectedTab={selectedTab} 
          basePath={basePath}
        />
      ) : null}
      
        <StandardHeader 
          categories={categories} 
          selectedTab={selectedTab} 
          basePath={basePath}
        />
    </div>
  );
};