import { storyblokEditable } from '@storyblok/react';
import Image from '../../../core/Image';
import styles from './styles.module.css';
import { BlockQuoteStoryblok } from './types';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

interface Props {
  blok: BlockQuoteStoryblok;
}

const BlockQuote = ({ blok }: Props) => {
  const { image, quote, name, alignment, alignment_large } = blok || {};
  const imgSrc = image?.filename;

  return (
    <div
      className={classNames(styles.container, {
        [styles.imageRight]: alignment === 'right',
        [styles.imageRightLarge]: alignment_large ? alignment_large === 'right' : alignment === 'right',
      })}
      {...storyblokEditable(blok)}
    >
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="Block quote" />}
      <div className={styles.textContainer}>
        {quote && <p className={classNames(styles.quote, 'body-14')}>{quote}</p>}
        {name && <div className={classNames(styles.name, 'body-14-bold')}>{name}</div>}
      </div>
    </div>
  );
};

export default BlockQuote;
