import React, { useRef, useEffect } from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { CategorySliderStoryblok } from './types';
import { isNullOrUndefined } from '../../../../utils/objectHelpers';

interface Props {
  blok: CategorySliderStoryblok;
}

const CategorySlider = ({ blok }: Props) => {
  const { title, categories } = blok || {};

  const scrollbarRef = useRef<HTMLDivElement | null>(null);
  const scrollbarHandleRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    const { current: scrollbar } = scrollbarRef;
    const { current: scrollbarHandle } = scrollbarHandleRef;
    if (isNullOrUndefined(scrollbar) || isNullOrUndefined(scrollbarHandle)) return;
    const maxScrollLeft = scrollbar.scrollWidth - scrollbar.offsetWidth;
    const target = scrollbar.offsetWidth - scrollbarHandle.offsetWidth;
    const scrollPercentage = scrollbar.scrollLeft / maxScrollLeft;

    scrollbarHandle.style.transform = `translateX(${target * scrollPercentage}px)`;
  };

  return (
    <section className={classNames(styles.container, 'Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
      <div className={styles.itemsContainer}>
        <div ref={scrollbarRef} onScroll={handleScroll} className={styles.items}>
          {(categories ?? []).map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
        <div className={styles.overlay}></div>
      </div>
      <div className={styles.slider}>
        <div ref={scrollbarHandleRef} className={styles.sliderHandle} />
      </div>
    </section>
  );
};

export default CategorySlider;
