import React, { createContext, useCallback, useEffect, useState } from 'react';
import { GenderTogglePaths, GenderToggleValues } from '../components/core/GenderToggle/types';
import { useCookies } from 'react-cookie';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { ShopifyService } from '../modules/shopify.service';
import { noop } from 'lodash';

type SessionContextType = {
  gender: string;
  handleGenderToggle: (val: any) => void;
  isWomen: boolean;
  basePath: '/men' | '/women';
};

const SessionContext = createContext<SessionContextType>({
  gender: 'women',
  handleGenderToggle: (val) => {},
  isWomen: true,
  basePath: '/women',
});

const SessionProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['preference', 'customerAccessToken']);
  const router = useRouter();
  const [gender, setGender] = useState(router.asPath.indexOf(GenderTogglePaths.MEN) > -1 ? 'men' : 'women');
  const isWomen = gender === GenderToggleValues.WOMEN;
  const basePath = isWomen ? '/women' : '/men';

  const handleGenderToggle = useCallback(
    (val: GenderToggleValues) => {
      const futureDate = new Date();
      futureDate.setMonth(futureDate.getMonth() + 6);
      setGender(val);
      setCookie('preference', val, {
        path: '/', // Make accessible on all paths
        expires: futureDate,
      });
    },
    [setCookie]
  );

  useEffect(() => {
    if (router.asPath.indexOf(GenderTogglePaths.MEN) > -1 && router.asPath.indexOf(GenderTogglePaths.WOMEN) > -1) {
      // Prevent a loop from happening when users open a collection on a suburl with the opposite link as part of it
      return;
    }

    if (router.asPath.indexOf(GenderTogglePaths.MEN) > -1 && isWomen) {
      handleGenderToggle(GenderToggleValues.MEN);
      return;
    }
    if ((router.asPath.indexOf(GenderTogglePaths.WOMEN) > -1 || router.asPath === '/') && !isWomen) {
      handleGenderToggle(GenderToggleValues.WOMEN);
    }
  }, [router.asPath, isWomen, handleGenderToggle]);

  useEffect(() => {
    if (!gender) {
      handleGenderToggle(GenderToggleValues.WOMEN);
    }
  }, [gender, handleGenderToggle]);

  return (
    <SessionContext.Provider
      value={{
        gender,
        handleGenderToggle,
        isWomen,
        basePath,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
