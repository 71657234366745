import React, { createContext, useState, useEffect } from 'react';
import { useRouter } from 'next/router';

export type Alignments = 'left' | 'right';

type DrawerStateProps = {
  alignment?: Alignments;
  wideScreen?: boolean;
  [k: string]: any;
};

type DrawerStateType = {
  component: React.ElementType | null;
  props?: DrawerStateProps;
};

export interface DrawerContextType extends DrawerStateType {
  isOpen: boolean;
  showDrawer(component: JSX.Element, props?: DrawerStateProps, toggle?: boolean): void;
  hideDrawer(): void;
}

export const DrawerContext = createContext<DrawerContextType>({
  component: null,
  props: {},
  isOpen: false,
  showDrawer: () => {},
  hideDrawer: () => {},
});

export const DrawerProvider = (props: { children?: React.ReactChild }) => {
  const [drawerState, setDrawerState] = useState<DrawerStateType>({
    component: null,
    props: {},
  });

  const router = useRouter();

  const [isOpen, setIsOpen] = useState(false);
  let lastTrackedPath;

  const showDrawer = (component, props = {}, toggle = false) => {
    //@ts-ignore

    if (toggle && isOpen) {
      setIsOpen(false);
    } else {
      setDrawerState({
        ...drawerState,
        component,
        props,
      });
      setIsOpen(true);
    }
  };

  const hideDrawer = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      if (lastTrackedPath !== url) {
        hideDrawer();
      }

      lastTrackedPath = url;
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <DrawerContext.Provider
      value={{
        ...drawerState,
        isOpen,
        showDrawer,
        hideDrawer,
      }}
    >
      {props.children}
    </DrawerContext.Provider>
  );
};

export const DrawerConsumer = DrawerContext.Consumer;
