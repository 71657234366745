import styles from './styles.module.css';
import { SignpostCore } from './types';
import classNames from 'classnames';
import Icon from '../Icons/index';
import Link from 'next/link';

const SignpostLink = ({
  signpostText,
  href,
  onClick,
  iconLeft,
  iconRight = 'arrowRight',
  isSecondary = false,
  bgColour,
}: SignpostCore) => {
  const Element = href ? Link : 'button';

  const customStyles = {
    backgroundColor: bgColour,
    border: bgColour !== 'white' ? 'none' : undefined,
    color: bgColour ? 'white' : undefined,
  };

  return (
    <Element
      className={classNames(styles.container, { [styles.secondary]: isSecondary })}
      {...(href && { href })}
      {...(onClick && !href && { onClick })}
      style={customStyles}
    >
      {iconLeft && <Icon className={classNames(styles.icon, styles.iconLeft)} title={iconLeft} />}
      <h5 className={classNames(styles.text, 'body-16-bold')}>{signpostText}</h5>
      {iconRight && <Icon className={styles.icon} title={iconRight} color={customStyles.color} />}
    </Element>
  );
};

export default SignpostLink;
