import { fetchPostJSON } from './api-helpers';

export const getBrandList = async (department: string) => {
  try {
    return await fetchPostJSON('/api/get-brand-list', department);
  } catch (err) {
    // Sentry.captureException(err);
    console.log('error is', err);
  }
};
