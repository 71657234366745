import { CurrencyCode } from '../modules/@shopify/generated';
import { ProductService } from '../modules/product.service';
import { CanonicalItem } from '../data-fetching/canonicalItem';
import { roundNumberDownToNearest5, capitalizeFirstLetter } from './helpers';
import { isNotUndefined } from '../components/sections/ProductSingle/utils/helpers';
import { AlgoliaHit } from '../components/searching/Hit/hit.types';

export const formatDate = (date: Date | string | number) => {
  const dateObj = new Date(date);
  const day = dateObj.getDate();
  const month = dateObj.toLocaleString('en-GB', { month: 'long' });
  const year = dateObj.getFullYear();

  const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return 'th';
    switch (number % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return `${day}${nthNumber(day)} ${month} ${year}`;
};

export const formatTwoDigitYearDateString = (dateStr: string | null): string | null => {
  if (!dateStr) return null;

    let date = new Date(dateStr);

    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let year = date.getFullYear().toString().slice(-2);

    let formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
}

// PDP

export const formatAgoliaProduct = (productAlgolia: AlgoliaHit) => {
  if (!productAlgolia) return null;

  const {
    title,
    product_type,
    handle,
    id,
    body_html_safe,
    tags,
    image,
    inventory_quantity,
    price,
    objectID,
    compare_at_price,
  } = productAlgolia || {};

  const tagsByCategory: any = {};

  tags?.forEach((tag) => {
    const tagArr = tag.replace(/boolean:/g, '').split('_');

    if (tagArr[1]) {
      const lowerCaseKey = tagArr[0].toLowerCase();
      if (lowerCaseKey.includes('size')) {
        //Format algolia size tag to match tag from shopify
        const size = lowerCaseKey.split('size');
        size.shift();

        tagsByCategory.size = size + ' ' + tagArr[1].toLowerCase();
      }
      tagsByCategory[tagArr[0].toLowerCase()] = tagArr[1].replace(/_/g, ' ');
    }
  });

  const product: ProductService.Single = {
    id: `gid://shopify/Product/${id}`, //decodeBase64(id),
    title: title,
    description: body_html_safe,
    descriptionHtml: body_html_safe,
    tags,
    productType: product_type,
    handle,
    tagsByCategory,
    seo: {
      title: title,
      description: body_html_safe,
    },
    collections: productAlgolia.collections ?? [],
    images: [{ src: image, id: image, alt: '' }],
    variants: [
      {
        compareAtPrice: { amount: compare_at_price, currencyCode: CurrencyCode.Gbp },
        price: { amount: price, currencyCode: CurrencyCode.Gbp },
        title: title,
        id: `gid://shopify/ProductVariant/${objectID}`,
      },
    ],
    totalInventory: inventory_quantity,
  };
  return product;
};

export const allowedMaterials = [
  'angora',
  'cashmere',
  'cotton',
  'leather',
  'linen',
  'mohair',
  'silk',
  'suede',
  'wool',
];

const generateShortMaterialDisplayString = (materials: CanonicalItem["materials"]): string => {

  const displayMaterial = materials
    .find(material => allowedMaterials.includes(material.toLowerCase()));

  const materialComponents = [
    displayMaterial,
  ].join(' ');

  return materialComponents;
}

export const toShortTitle = (item: CanonicalItem): string => {
  const { brand_type, brand, style, materials } = item;
  const materialDisplayString = generateShortMaterialDisplayString(materials);

  const titleComponents = [
    brand_type === 'vintage' ? brand_type : '',
    brand,
    materialDisplayString,
    style,
  ].filter(isNotUndefined);

  const fullTitle = titleComponents
    .filter(titleComponent => titleComponent?.trim().length !== 0) // remove any empty segments
    .map(titleComponent => capitalizeFirstLetter(titleComponent))
    .join(' ');

  return fullTitle.split('').length > 27 ? `${fullTitle.slice(0, 27)}...` : fullTitle;
};
