import { CurrencyCode } from '../modules/shopify.service';
import { Base64 } from 'js-base64';
import axios from 'axios';

export namespace IntlUtility {
  interface Price {
    amount: number;
    currencyCode?: string; //CurrencyCode;
    locales?: string | string[];
  }

  export function formatPrice({ amount, currencyCode = 'GBP', locales }: Price): string {
    return new Intl.NumberFormat(locales, {
      style: 'currency',
      currency: currencyCode,
    }).format(amount);
  }
  export function formatPriceSimple(amount: number): string {
    if (amount < 0) return `-£${Math.abs(amount).toFixed(2)}`;
    return `£${amount.toFixed(2)}`;
  }
}

export function decodeBase64(value: string): string {
  return Base64.decode(value);
}

export async function AxiosResponse(config: any) {
  const x = axios(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });

  return x;
}
