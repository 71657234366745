import { useEffect, useState } from "react";
import { CountdownStoryblok } from "../../../../../component-types-sb";
import { calculateTimeLeftUntilSaleEnds } from "./utils";

interface Props {
  blok: CountdownStoryblok;
}

const Countdown = ({ blok }: Props) => {
  const [timeLeft, setTimeLeft] = useState('');

  const { _uid, daySaleEnds } = blok;

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeftUntilSaleEnds(new Date(daySaleEnds.replace(' ', 'T')));
      setTimeLeft(newTimeLeft.toString());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [daySaleEnds]);

  return (
    <p className="body-14">
        <strong>{timeLeft}</strong>
    </p>
  )
}

export default Countdown;