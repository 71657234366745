import { PropsWithChildren, useState } from 'react';
import { InfoCircle } from '../../core/Icons/iconList';
import HelpTooltip from '../../core/HelpTooltip';

type Props = {
  maxWidth?: number;
}

export const Help: React.FC<PropsWithChildren<Props>> = ({ children, maxWidth }) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  
  if (!children) return null;
  return (
    <span className='relative inline-block'
          onMouseEnter={() => setOpenToolTip(true)}
          onMouseLeave={() => setOpenToolTip(false)}>
      <InfoCircle width={15} height={15} />
      <HelpTooltip maxWidth={maxWidth} open={openToolTip}>{children}</HelpTooltip>
    </span>
  );
};