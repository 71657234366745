import React from 'react';
import classNames from "classnames"
import { CanonicalItem } from '../../../../data-fetching/canonicalItem';

export const ItemSpecifics: React.FC<{ item: CanonicalItem }> = ({ item }) => {
    return (
        <div>
            <ul className="pl-5 list-disc capitalize">
                <li className={classNames('mb-2')}><span>Colour: </span>{item.colour}</li>
                <li className={classNames('mb-2')}><span>Condition: </span>{item.condition}</li>
                {item.original_tags && <li className={classNames('mb-2')}><span>With Original Tags</span></li>}
                {item.size.dimensions && <li className={classNames('mb-2')}><span>Dimensions: </span>{item.size.display}</li>}
                <li className={classNames('mb-2')}><span>{`${item.materials.length > 1 ? 'Materials' : 'Material'}`}: </span>
                    {item.materials.join(', ')}
                </li>
            </ul>
        </div>
    )
}