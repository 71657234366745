const MINUTE = 1000 * 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const SECOND = 1000;

export const calculateTimeLeftUntilSaleEnds = (daySaleEnds: Date) => {
  const currentDate = new Date();
  const timeRemaining = daySaleEnds.getTime() - currentDate.getTime();

  if (timeRemaining < 0) return '';

  const days = Math.floor(timeRemaining / DAY);
  const hours = Math.floor((timeRemaining % DAY) / HOUR);
  const minutes = Math.floor((timeRemaining % HOUR) / MINUTE);
  const seconds = Math.floor((timeRemaining % MINUTE) / SECOND);

  if (days === 0) {
    if (hours === 0) {
      if (minutes === 0) {
        return seconds;
      }
      return `${minutes} mins ${seconds} secs left`;
    }
    return `${hours} hrs ${minutes} mins ${seconds} secs left`;
  }

  return `${days} days ${hours} hrs ${minutes} mins ${seconds} secs left`;
};