import { useShopifyAccessToken } from '../useShopifyCustomer';
import { useEffect } from 'react';
import { ShopifyService } from '../../modules/shopify.service';
import { isNotNullOrUndefined } from '../../utils/objectHelpers';
import { refreshCart, useCart } from './useCart';
import { useCookies } from 'react-cookie';

const PrimaryCartIDCookieName = 'PrimaryCartId';

export const usePrimaryCart = () => {
  const [savedCartId] = usePrimaryCartId()
  return useCart(savedCartId);
};

/** Important: only use this hook in one place that runs on every page
 *  This hook ensures that there is a default cart created, and that it stays synchronised with the buyer identity (which is required to make offers work)
 */
export const usePrimaryCartExists = () => {
  const [savedCartId, setCartId, clearSavedCartId] = usePrimaryCartId();
  const customerToken = useShopifyAccessToken();
  const { cart, metadata } = usePrimaryCart();
  const fetchStatus = metadata.status;
  const cartId = cart?.id;

  useEffect(() => {
    if (cartId) return; // cart fetch succeeded
    if (fetchStatus !== 'success') return; // still fetching
    if (savedCartId === null) return; // savedCartID is already unset
    /**
     * Fetch succeeded but there's no cart. Shopify deletes carts after 10 days of being dormant, or after checkout.
     * Regardless of why, the cart no longer exists, so we need to clear the local cartID to trigger re-initialisation of the cart
     */
    clearSavedCartId();
  }, [cartId, fetchStatus, savedCartId]);

  const failureReason = metadata?.failureReason?.message;
  useEffect(() => {
    if (failureReason && failureReason.includes('Variable $cartId of type ID! was provided invalid value') && savedCartId !== null) {
      // cart ID has been corrupted for some reason. clear it.
      clearSavedCartId();
    }
  }, [failureReason]);

  useEffect(() => {
    if (savedCartId) return;
    let aborted = false;

    ShopifyService.cartCreate().then(cart => {
      const id = cart.cartCreate?.cart?.id;
      if (isNotNullOrUndefined(id) && !aborted) setCartId(id);
    });

    return () => {
      aborted = true;
    };
  }, [savedCartId]);

  useEffect(() => {
    if (!cartId) return;
    let aborted = false;

    ShopifyService.cartBuyerIdentityUpdate({
      cartId: cartId,
      buyerIdentity: { customerAccessToken: customerToken ?? null },
    })
      .then(cart => {
        if (!aborted) return refreshCart(cartId);
      });

    return () => {
      aborted = true;
    };
  }, [customerToken, cartId]);
};

export const usePrimaryCartId = () => {
  /**
   * I used this specific library to do this because it triggers an update to all hooks if the cookie is updated.
   */
  const [cookies, setCookie, deleteCookie] = useCookies<typeof PrimaryCartIDCookieName, { [PrimaryCartIDCookieName]: string | null }>([PrimaryCartIDCookieName]);
  return [
    cookies[PrimaryCartIDCookieName] ?? null,
    (newCartId: string | null) => {
      setCookie(PrimaryCartIDCookieName, newCartId, { path: '/', sameSite:'strict', expires: new Date('2100-01-01') });
    },
    () => {
      deleteCookie(PrimaryCartIDCookieName, { path: '/', sameSite:'strict', expires: new Date('2100-01-01') });
    }
  ] as [
    string | null, // cookie value
    (newCartId: string | null) => void, // set cookie value
    () => void, // clear cookie value
  ]
};

export const usePrimaryCartOfferRefresher = () => {
  // basically have to update an item in the cart to force the shopify function to re-run
  const { cart } = usePrimaryCart();

  return () => {
    if (!cart) return;
    if (cart.lines.length === 0) return;
    const firstCartLine = cart.lines[0];
    return ShopifyService
      .cartLinesUpdate({ cartId: cart.id, lines: [{ id: firstCartLine.id, quantity: firstCartLine.quantity }] })
      .then(() => refreshCart(cart.id));
  };
};