import React, { useContext } from 'react';
import { Button } from '../../../core';
import { WishlistContext } from '../../../../context/WishlistContext';
import { CanonicalItem } from '../../../../data-fetching/canonicalItem';

export const WatchlistButton: React.FC<{ item: CanonicalItem}> = ({item}) => {
    const { toggleItemInWishlist, wishlist } = useContext(WishlistContext);
    const isInWishlist = !!wishlist?.listcontents?.find((itm) => `${itm.epi}` === `${item?.variant_id}`);

    return (
        <div>
            <Button
                className="w-full"
                isSecondary={true}
                text={!!isInWishlist ? 'Remove item' : 'Watch item'}
                iconLeft={!!isInWishlist ? 'heartWishRemove' : 'heartWish'}
                onClick={(e) => {
                    e.preventDefault();

                    toggleItemInWishlist({
                        productId: item.id,
                        variantId: item.variant_id,
                        slug: item._original.handle,
                        price: item.price,
                        name: item.title,
                        brand: item.brand,
                    });
                }}
                tooltip="Get notified when the price drops"
            />
        </div>
    )
}