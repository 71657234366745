import { useCookies } from "react-cookie";
import { useSearchHistory } from "../../../searching/SearchBox";
import classNames from "classnames";
import styles from './styles.module.css';
import Link from "next/link";
import { ArrowRight } from "../../../core/Icons/iconList";
import { YourRecentSearchesStoryblok } from "./types";
import useTimeout from "../../../../helpers/useTimeout";

type YourRecentSearchesProps = {
    blok: YourRecentSearchesStoryblok; 
}

const RecentSearch: React.FC<{ recentSearch: string}> = ({ recentSearch }) => {
    const [cookies] = useCookies(['preference']);
    const preference = cookies.preference ?? 'women';
    
    return (
        <span className="flex">
            <Link className="flex inline-block items-center gap-1" href={`${preference}/search?q=${encodeURIComponent(recentSearch)}`}>
                <span className="flex inline-block gap-2">
                    <p className="hover:underline font-bold">{recentSearch}</p>
                </span>
                <ArrowRight />
            </Link>
        </span>
    );
};

export const YourRecentSearches: React.FC<YourRecentSearchesProps> = ({ blok }) => {
    const { title } = blok || {};

    const [searchHistory] = useSearchHistory(5);

    // have to do this to prevent hydration errors unfortunately, because localstorage is not available for server-side renders
    const readyToRender = useTimeout(100);
    if (!readyToRender) return null;

    if (!searchHistory || searchHistory.length < 3) return null;

    return (
        <section
        className={classNames('Section')}
        >
            <div className={styles.container}>
                {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
                <div className="relative">
                    <div className={styles.searches} >
                        {searchHistory && searchHistory.map((search) => {
                            return <div key={search}><RecentSearch recentSearch={search}/>
                            </div>
                        })}
                    </div>
                </div>
            </div>
      </section>
    )
}