import { useEffect, useState } from "react";
import { algoliaProductSearch } from "../../../searching/queries/algoliaProductSearch";
import { ProductSearchState } from "../../../searching/queries/types";
import { AlgoliaHit } from "../../../searching/Hit/hit.types";
import { SearchRefinements } from "../../../searching/queries/types";
import classNames from "classnames";
import styles from './styles.module.css';
import { IndependentSimpleItemCard } from "../../../shopify/SimpleItemCard";
import { useScrollbar } from "../Watchlist/useScrollbar";
import { CollectionNewestUploadsStoryblok } from "./types";
import Link from "next/link";
import { ArrowRight } from "../../../core/Icons/iconList";

interface Props {
  blok: CollectionNewestUploadsStoryblok;
}

export const CollectionNewestUploads = ({ blok }: Props) => {
  const { collection, signpost_link } = blok || {};

  const searchState: ProductSearchState = {
    pagination: {
      pageSize: 5,
      page: 0,
    },
    collections: `${collection}`,
  };

  const [searchResults, setSearchResults] = useState<{
    hits: AlgoliaHit[];
    refinements: SearchRefinements;
    pagination: {
      total_hits: number;
      total_pages: number;
      page: number;
      hits: number;
    };
  } | null>(null);

  const { scrollbarRef, scrollbarSliderRef } = useScrollbar();

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const results = await algoliaProductSearch({
          ...searchState,
          collections: collection,
        });
        setSearchResults(results);
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    fetchSearchResults();
  }, [collection]);

  if (!collection || searchResults?.hits.length === 0) return null;

  return (
    <section
      className={classNames('Section')}
    >
      <div className={styles.container}>
        <h3 className={classNames(styles.title, 'display-18-caps')}>Latest uploads</h3>
        <div className="relative">
            <div className={styles.products} ref={scrollbarRef} >
                {searchResults?.hits.map((item) => {
                return <div key={item.handle} className="product-item min-w-[200px] min-h-[200px]">
                  <IndependentSimpleItemCard key={item.handle} handle={item.handle} />
                </div>;
                })}
            </div>
            <div className={styles.slider}>
                <div ref={scrollbarSliderRef} className={styles.sliderHandle} />
            </div>
            {signpost_link && <div className="flex justify-end mt-2 items-center gap-1">
              <Link href={signpost_link.cached_url} className={classNames(styles.text, 'body-16-bold')}>See more</Link>
              <ArrowRight />
            </div>}
        </div>
      </div>
    </section>
  );
};