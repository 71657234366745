import React, { useContext } from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import { Image } from '../../../core';

import styles from './styles.module.css';
import { PartnerHeaderStoryblok } from './types';
import { CheckoutCartContext } from '../../../../context/CheckoutCartContext';
import { DrawerContext } from '../../../../context/DrawerContext';
import { Icon } from '../../../core';

interface Props {
  blok: PartnerHeaderStoryblok;
}

const MobileDrawer = ({ items }) => {
  return (
    <ul className={styles.mobileLinks}>
      {items.map(({ url, text }) => {
        const cachedURL = url?.cached_url;
        return (
          <li className={styles.mobileLink} key={cachedURL}>
            <a href={cachedURL}>{text}</a>
          </li>
        );
      })}
    </ul>
  );
};

const PartnerHeader = ({ blok }: Props) => {
  const { logo, links } = blok || {};
  const { openCart } = useContext(CheckoutCartContext);
  const { showDrawer } = useContext(DrawerContext);

  const handleOpenShopDrawer = () => {
    showDrawer(MobileDrawer as any, { items: links });
  };

  return (
    <section className={classNames(styles.container, 'Section')} {...storyblokEditable(blok)}>
      <button onClick={handleOpenShopDrawer} className={styles.menuButton}>
        <svg width="12" height="11" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.76 11.78">
          <defs></defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path className="cls-1" d="M0,1.68V0H12.76V1.68ZM0,6.73V5.05H12.76V6.73Zm0,5V10.1H8v1.68Z"></path>
            </g>
          </g>
        </svg>
      </button>
      {logo && <Image src={logo?.filename} alt={logo?.alt} className={styles.logo} />}
      <div className={styles.items}>
        {links?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} className={styles.link} />
        ))}
      </div>
      <button onClick={openCart}>
        <Icon title="bag" className={styles.HeaderLinkIcon} />
      </button>
    </section>
  );
};

export default PartnerHeader;
