import { max } from 'ramda';

const letterSizes = ['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'];
const sortSizesSmallestFirst = (a: [string, number], b: [string, number]) => {
  const sizeValueA = a[0].split('_')[1].toLowerCase().trim();
  const sizeValueB = b[0].split('_')[1].toLowerCase().trim();


  // try to sort by numerical sizes first
  const aSize = parseFloat(sizeValueA);
  const bSize = parseFloat(sizeValueB);
  if (!isNaN(aSize) && !isNaN(bSize)) {
    return aSize - bSize
  };

  // then try letter sizes
  if (letterSizes.includes(sizeValueA) && letterSizes.includes(sizeValueB)) {
    return letterSizes.indexOf(sizeValueA) - letterSizes.indexOf(sizeValueB);
  }

  // last resort, crude case insensitive lexicographical sorting
  const aChars = a[0].split('');
  const bChars = a[0].split('');
  const maxIterations = max(aChars.length, bChars.length);
  for (let i = 0; i < maxIterations; i++) {
    if (!aChars[i]) return -1;
    if (!bChars[i]) return 1;
    const diff = bChars[i].charCodeAt(0) - aChars[i].charCodeAt(0);
    if (diff !== 0) return diff;
  }
  return 0;
};

export const groupSizeRefinements = (refinementOptions: Record<string, number>) => {
  const entries = Object.entries(refinementOptions);
  const optionsStartingWith = (text: string) => Object.fromEntries(entries.filter(([value, count]) => value.startsWith(text)).sort(sortSizesSmallestFirst));
  return {
    'UK Clothing Size': optionsStartingWith('uk'),
    'SML Clothing Size': optionsStartingWith('sml'),
    'UK Shoe Size': optionsStartingWith('shoeuk'),
    'US Shoe Size': optionsStartingWith('shoeus'),
    'Waist': optionsStartingWith('waist'),
    'Leg Length': optionsStartingWith('leg'),
    'Chest': optionsStartingWith('chest'),
    'Collar': optionsStartingWith('collar'),
  };
};