import { ProductSearchState, SearchableFacets } from '../queries/types';
import { capitalizeFirstLetter } from '../../../utils/helpers';

export const isFacetOptionActive = ({ facet, option, searchState }: {
  facet: SearchableFacets,
  option: string,
  searchState: ProductSearchState
}) => {
  const facetState = searchState[facet];
  return Array.isArray(facetState) ? facetState.includes(option) : facetState === option;
};

// this prepares the actual name of the facet for display
export const searchableFacetToDisplayString = (searchableFacet: SearchableFacets | string) => {
  if (searchableFacet === SearchableFacets.original_tags) return 'Tags Attached';
  else return capitalizeFirstLetter(searchableFacet.replaceAll('_', ' '));
};

const priceRangeRegex = /^[0-9]+:[0-9]+$/

// this prepares the filters for a specific facet for display
export const facetRefinementToDisplayString = (facetRefinement: string) => {
  if (facetRefinement === 'true') return 'Yes';
  else if (facetRefinement === 'false') return 'No';
  else if (facetRefinement.startsWith('uk_')) return facetRefinement.replaceAll('_', ' ').toUpperCase();
  else if (facetRefinement.startsWith('eu_')) return facetRefinement.replaceAll('_', ' ').toUpperCase();
  else if (facetRefinement.startsWith('shoeuk_')) return facetRefinement.replaceAll('shoeuk_', 'UK ');
  else if (facetRefinement.startsWith('shoeeu_')) return facetRefinement.replaceAll('shoeeu_', 'EU ');
  else if (facetRefinement.startsWith('sml_')) return facetRefinement.replaceAll('sml_', '').toUpperCase();
  else if (priceRangeRegex.test(facetRefinement)) return facetRefinement.split(':').map(amount => `£${amount}`).join(" - ");
  else return capitalizeFirstLetter(facetRefinement.replaceAll('_', ' '));
};