import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import styles from './styles.module.css';
import { SalecountdownBarStoryblok } from './types';
import classNames from 'classnames';
import { Transition } from '@headlessui/react';
import { calculateTimeLeftUntilSaleEnds } from './utils';

import { Icon } from '../../../core';

interface Props {
  blok: SalecountdownBarStoryblok;
}

const SaleCountdownBar = ({ blok }: Props) => {
  const [display, setDisplay] = useState(false);
  const [cookies, setCookie] = useCookies(['hiddenAnnouncements', 'preference']);
  const [timeLeft, setTimeLeft] = useState('');
  const preference = cookies.preference ?? 'women';

  const { id='', text, link = {url:''}, bold_text, daySaleEnds='2000-01-01T00:00:00.00Z' } = blok;
  const modifiedLinkWithPreference = link.url.replaceAll('[preference]', `${preference}`);

  const hiddenAnnouncements: string[] = cookies['hiddenAnnouncements'] || [];
  const handleHideAnnouncement = () => {
    const newHiddenAnnouncements = [...hiddenAnnouncements];
    newHiddenAnnouncements.push(id);
    setDisplay(false);

    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 7); //Sets announcement cookie expiry to 7 days in the future
    setCookie('hiddenAnnouncements', newHiddenAnnouncements, {
      path: '/', // Make accessible on all paths
      expires: futureDate,
    });
  };

  useEffect(() => {
    const hideSaleCountdownBar = hiddenAnnouncements.includes(id);

    setDisplay(!hideSaleCountdownBar);

    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeftUntilSaleEnds(new Date(daySaleEnds.replace(' ', 'T')));
      setTimeLeft(newTimeLeft.toString());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [id]);


  return (
    <section>
      <Transition
        show={display}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={classNames('relative py-3 px-4 sm:px-6 lg:px-8 ', styles.HeaderAnnouncement)}>
          <div className="pr-16 sm:text-center md:px-16">
            <p className="body-14 inline-flex flex-wrap gap-x-2">
              <a href={modifiedLinkWithPreference}>
                {text} <strong>{bold_text}</strong>
              </a>
            </p>
            <p className="body-14">
              <strong>{timeLeft}</strong>
            </p>
          </div>
          <button type="button" className={styles.HeaderAnnouncement__Button} onClick={handleHideAnnouncement}>
            <span className="sr-only">Dismiss</span>
            <Icon title="close" size="24" />
          </button>
        </div>
      </Transition>
    </section>
  );
};

export default SaleCountdownBar;