import { useContext } from 'react';
import styles from './styles.module.css';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { GenderTogglePaths } from './types';
import Link from 'next/link';

const GenderToggle = () => {
  const router = useRouter();
  const isWomen = router.asPath !== GenderTogglePaths.MEN; // Use path to make sure it renders correctly on the server side

  return (
    <section className={classNames(styles.container, styles.bordered, 'Section')}>
      <p className={'display-18-caps border-none'}>{isWomen ? 'Women' : 'Men'}</p>
      <Link
        href={isWomen ? GenderTogglePaths.MEN : GenderTogglePaths.WOMEN}
        className={classNames('body-14-bold border-b-2 border-brandYellow', styles.toggleLink)}
      >
        Shop {isWomen ? 'men' : 'women'}
      </Link>
    </section>
  );
};

export default GenderToggle;
