import React, { PropsWithChildren } from 'react';
import styles from './styles.module.css';
import classNames from 'classnames';

type Props = PropsWithChildren<{
  text?: string;
  open: boolean;
}>;

const Tooltip: React.FC<Props> = ({open, text, children}) => {
  const contents = children ?? text;
  if (!contents) return null;

  const openStyles = open ? styles.open : null;

  return (
    <div className={classNames(styles.tooltip, openStyles)}>
      {contents}
      <div className={styles.tooltipArrow} />
    </div>
  )
}

export default Tooltip;