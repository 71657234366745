import { render } from 'storyblok-rich-text-react-renderer';
import { RichTextCore } from './types';
import { STORYBLOK_COMPONENT_RESOLVERS } from '../../../modules/@storyblok/storyblok';
import { StoryblokComponentType } from '@storyblok/js';
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';

const RichText = ({ document, className }: RichTextCore) => <div className={className}>{render(document, { defaultBlokResolver: (name, blok) => {
    // @ts-ignore
    const component: StoryblokComponentType | undefined = STORYBLOK_COMPONENT_RESOLVERS[name];
    if (isNotNullOrUndefined(component)) return component({ blok });
    return null;
  }})}</div>;

export default RichText;
