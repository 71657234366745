import React, { useState } from 'react';
import classNames from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import styles from './styles.module.css';
import { CarouselStoryblok } from './types';
import 'keen-slider/keen-slider.min.css';
import { useMediaQuery } from 'react-responsive';
import fallbackImage from './../../../public/fallback.jpg';

import { Image, Icon } from '../../core';
import { ImageZoom } from '../ImageZoom';

interface Props {
  blok?: CarouselStoryblok;
  imageList?: {src: string}[] | string[];
}

const CarouselBlock = ({ blok, imageList }: Props) => {
  // normalise the format if an imageList is provided
  const cleandImageList = (imageList && imageList.length > 0) 
  ? imageList.map(image => typeof image === 'string' ? { src: image } : image) : [{ src: fallbackImage.src }];

  const items = blok || cleandImageList || [];
  const hasSingleSlide = items.length === 1;

  const [isZoomed, setIsZoomed] = useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);

  const isTabletOrAbove = useMediaQuery({ query: '(min-width: 768px)' });

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    initial: 0,
    drag: true,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
    },
  });

  return (
    <>
      <div className={styles.sliderContainer}>
        {!hasSingleSlide ? (
          <div ref={sliderRef} className="keen-slider">
            {cleandImageList &&
              items?.map(({ src }: any, index: any, id: string | number, alt: 'Thrift+') => (
                <div key={src} className={classNames(`keen-slider__slide number-slide${index}`, styles.slide)}>
                  <ImageZoom src={src} disabled={index >= 2}>
                    <Image src={src} alt="product image" width="600" loading="eager" className={styles.img} />
                  </ImageZoom>
                </div>
              ))}
          </div>
        ) : (
          // <ImageZoom src={items[0].src}>
            <Image
              src={items[0].src}
              alt="product image"
              width="300"
              loading="eager"
              className="absolute top-0 left-0 h-full w-full object-contain"
            />
          // </ImageZoom>
        )}
        {loaded && instanceRef.current && (
          <>
            <Arrow
              left
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />

            <Arrow
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={currentSlide === instanceRef.current.track.details.slides.length - 1}
            />
          </>
        )}
        <div className={styles.carouselControlsCenter}>
          {loaded && instanceRef.current && (
            <div className={`dots ${styles.carouselDotWrapper}`}>
              {instanceRef.current.track.details.slides.map((_, idx) => {
                return (
                  <button
                    key={idx}
                    onClick={() => {
                      instanceRef.current?.moveToIdx(idx);
                    }}
                    className={`dot ${styles.carouselDot} ${currentSlide === idx ? styles.active : ''}`}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function Arrow(props: { disabled: boolean; left?: boolean; onClick: (e: any) => void }) {
  const disabeld = props.disabled ? ' arrow--disabled' : '';
  return {
    ...(props.left ? (
      <Icon
        title="chevronLeft"
        className={`arrow arrow--left ${disabeld} ${styles.slidePrev}`}
        onClick={props.onClick}
        disabled={props.disabled}
      />
    ) : (
      <Icon
        title="chevronRight"
        className={`arrow arrow--right ${disabeld} ${styles.slideNext}`}
        onClick={props.onClick}
        disabled={props.disabled}
      />
    )),
  };
}

export default CarouselBlock;
