import { storyblokEditable } from '@storyblok/react';
import { Icon, RichText } from '../../../core';
import { useState } from 'react';
import styles from './styles.module.css';
import { ResellFormStoryblok } from './types';
import classNames from 'classnames';
import { checkBrand } from '../../../../utils/domainUtils/brandUtils';
import { LABELS } from '../../../../consts/brandLabels';
import { Brand, BrandType } from '../../../../types/brands';
import debounce from 'lodash/debounce';
import Link from 'next/link';

interface Props {
  blok: ResellFormStoryblok;
}
const BrandChecker = ({ blok }: Props) => {
  const { heading, subtitle, richtext } = blok || {};
  const [response, setResponse] = useState<Brand[]>([]);

  const colors: Record<Exclude<BrandType, BrandType.VINTAGE>, string> = {
    designer: 'rgba(147, 193, 178, 0.25)',
    premium: 'rgba(147, 193, 178, 0.25)',
    high_street: 'rgba(237, 225, 84, 0.25)',
    low_high_street: 'rgba(237, 225, 84, 0.25)',
    not_accepted: 'rgba(210, 125, 93, 0.25)',
  };

  const debouncedSearch = debounce(async (searchTerm: string) => {
    const searchLower = searchTerm.toLowerCase().trim();
    if (searchTerm) {
      const result = await checkBrand(searchLower);
      setResponse(result ?? []);
    } else {
      setResponse([]);
    }
  }, 600);

  const handleSearchChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    await debouncedSearch(searchTerm);
  };

  return (
    <div className={classNames(styles.resellContainer)} {...storyblokEditable(blok)}>
      <div className={styles.resellCard}>
        <div className="max-w-sm mx-auto">
          {heading && <h2 className="display-18-caps text-center mt-2 md:text-xl">{heading}</h2>}
          {subtitle && (
            <div className={styles.resellContent}>
              <p className="body-14 my-3 text-center">{subtitle}</p>
            </div>
          )}
          <div className="w-full relative flex justify-items-stretch">
            <input
              type="text"
              className={classNames(styles.resellInput, 'body-14')}
              placeholder="Type a brand name"
              onChange={handleSearchChange}
            />
            {/* <button title="" className={styles.resellSearchButton}>
              <Icon title="search" />
            </button> */}
          </div>

          {response.length ? response.map((brand) => {
            if (brand.brand_type !== BrandType.VINTAGE) {
                return (
                    <p className={styles.resellResponse} style={{ backgroundColor: colors[brand.brand_type] }} key={brand.brand_id}>
                        <span className="font-bold">{brand.brand_name}</span> {LABELS[brand.brand_type]}
                    </p>
                );
            }
            return null;
        }) : null}
        </div>
      </div>
      <div className="bg-brandYellow25 p-4 flex justify-center">
        <p>Brand missing? Click <Link href="https://docs.google.com/forms/d/e/1FAIpQLSc0F06DxZKjiQ6FuFqim8N8dpoYqzNhRNLpuXS2ndHUsYx64w/viewform" className="underline" target="_blank">here</Link> to suggest one.</p>
      </div>
      {richtext && (
        <RichText className="body-12 text-center mt-6 px-4 text-black75 max-w-sm mx-auto" document={richtext} />
      )}
    </div>
  );
}

export default BrandChecker;
