import { buyNow } from "../../data-fetching/cart/buyNow";


export const createCheckoutUrl = async (item: { variant_id: number }, customerAccessToken: string): Promise<string | null> => {
  const checkoutUrl = await buyNow({
    lineItems: [{
      merchandiseId: item.variant_id,
      quantity: 1
    }],
    customerAccessToken: customerAccessToken
  });

  if (checkoutUrl) {
    try {
      return checkoutUrl;
    } catch (error) {
      console.error('invalid URL', checkoutUrl);
      return null;
    }
  }

  return null;
}