import React from 'react';

import { InlineHtmlStoryblok } from './types';

interface Props {
  blok: InlineHtmlStoryblok;
}

export const InlineHTML = ({ blok }: Props) => {
  const { inline_html } = blok || {};

  return <section dangerouslySetInnerHTML={{ __html: inline_html }}></section>;
};

export default InlineHTML;
