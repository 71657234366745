import React from 'react';
import { HeartWishlistIconRemove } from '../../../../../core/Icons/iconList';

export const WatchCount: React.FC<{ watchCount: number | null }> = ({ watchCount }) => {
    if (!watchCount) return null;

    return (
        <div className="flex items-center justify-center mr-1 mb-0">
            {watchCount && watchCount > 3 && (
              <>
                <span className="body-14-bold">{watchCount} </span>
                <span className="px-1 py-1">{<HeartWishlistIconRemove title="heart" />}</span>
              </>
            )}
        </div>
    )
}