const urlIsPDP = (link: string) => link.includes('/products');
const urlIsDesignerStore = (link: string) => link.includes('/designer-store');

export const appendBasePath = (basePath: string, link: string): string | null => {2
    //TODO: refactor to make more maintainable with designer link
    if (urlIsPDP(link) || urlIsDesignerStore(link)) {
        return link;
    }
    
    if (!link.includes('/men') && !link.includes('/women')) {
        return `${basePath}${link}`;
    } else {
        return link;
    }
};