type SessionStorageKeys = 'productsPrev' | 'productHitIds' | 'prevViewedHit';

export const getSessionStorageValue = (key: SessionStorageKeys) => {
  if (typeof window !== 'undefined') {
    const saved = sessionStorage.getItem(key);

    const initial = saved && JSON.parse(saved);
    return initial;
  }
};

export const setSessionStorageValue = (key: SessionStorageKeys, value: any) => {
  if (typeof window !== 'undefined') {
    const initial = JSON.stringify(value);
    return sessionStorage.setItem(key, initial);
  }
};
