import { ShopifyService } from '../../modules/shopify.service';
import { refreshCart } from './useCart';
import { usePrimaryCart } from './usePrimaryCart';
import { giftcardVariantIDs, thriftBagVariantID, thriftBagVariantIDs } from '../../utils/constants';
import { MAX_BAGS_PER_ORDER } from '../../consts/bags';
import { useContext } from 'react';
import { CheckoutCartContext } from '../../context/CheckoutCartContext';
import { MAX_NUM_OF_GIFTCARDS } from '../../components/shopify/CartItem/GiftcardCartItem';

export const normaliseVariantID = (variantID: string) => {
  if (variantID.indexOf('gid://') < 0) {
    return `gid://shopify/ProductVariant/${variantID}`; // If the id isn't in the graphql format, let's add it
  } else {
    return variantID;
  }
};

type CartLineActions = {
  addToCart: null | (() => Promise<void>),
  removeFromCart: null | (() => Promise<void>),
  toggleInCart: null | (() => Promise<void>),
  setQuantity: null | ((qty: number) => Promise<void>),
  isInCart: null | boolean,
  quantity: null | number,
  maxAllowedQuantity: null | number,
}

export const useCartLine = (variantID: string | number): CartLineActions  => {
  const cartContext = useContext(CheckoutCartContext);
  const {cart} = usePrimaryCart();
  if (!cart) {
    return {
      addToCart: null,
      removeFromCart: null,
      toggleInCart: null,
      isInCart: null,
      setQuantity: null,
      quantity: null,
      maxAllowedQuantity: null,
    };
  }
  const normalisedVariantID = normaliseVariantID(`${variantID}`);
  const itemLineInCart = cart.lines?.find((line) => line.merchandise.id === normalisedVariantID);
  const existingLineID = itemLineInCart?.id ?? null;
  const isInCart = !!itemLineInCart;
  const isThriftBagVariant = thriftBagVariantIDs.includes(normalisedVariantID);
  const isGiftCard = giftcardVariantIDs.includes(normalisedVariantID);
  const currentQuantity = itemLineInCart?.quantity ?? 0;
  const maxAllowedQuantity = isGiftCard ? MAX_NUM_OF_GIFTCARDS : isThriftBagVariant ? MAX_BAGS_PER_ORDER : 1;

  const setQuantity = async (qty: number) => {
    const qtyToSet = Math.min(qty, maxAllowedQuantity);
    if (qtyToSet === 0 && existingLineID) {
      await ShopifyService.cartLinesRemove({ cartId: cart.id, lineIds: existingLineID });
    } else if (existingLineID && qtyToSet !== currentQuantity) {
      await ShopifyService.cartLinesUpdate({ cartId: cart.id, lines: [{ id: existingLineID, quantity: qty }] });
    } else if (!existingLineID && qtyToSet > 0) {
      await ShopifyService.cartLinesAdd({
        cartId: cart.id,
        lines: [{ merchandiseId: normalisedVariantID, quantity: qty }],
      });
    }
    await refreshCart(cart.id);
  };

  const addToCart = isInCart ? null : async () => {
    await setQuantity(1);
    cartContext.showHeaderUpdate();
  };

  const removeFromCart = !isInCart ? null : async () => {
    await setQuantity(0);
  };

  return {
    addToCart,
    removeFromCart,
    isInCart,
    toggleInCart: isInCart ? removeFromCart : addToCart,
    setQuantity,
    quantity: currentQuantity,
    maxAllowedQuantity,
  };
};

export const useThriftBagCartLine = (variantID: string | number = thriftBagVariantID) => {
  return useCartLine(variantID);
}