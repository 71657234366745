import React, { useState } from 'react';
import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { PartnerFaqItemStoryblok } from './types';
import classNames from 'classnames';
import { Icon, RichText } from '../../../../core';
import { Transition } from '@headlessui/react';

interface Props {
  blok: PartnerFaqItemStoryblok;
}

const PartnerFAQItem = ({ blok }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { title, content } = blok || {};

  return (
    <div className="bg-white relative mb-8" {...storyblokEditable(blok)}>
      <button className="flex mb-4" onClick={() => setExpanded(!expanded)}>
        <Icon
          title="chevronDown"
          color="#fff"
          className={classNames(
            'bg-black h-7 w-7 flex items-center justify-center rounded-full mr-4 transition-transform duration-500 flex-shrink-0',
            {
              '-rotate-180': expanded,
            }
          )}
        />

        <h3 className="body-18-bold text-left">{title}</h3>
      </button>
      <Transition
        show={expanded}
        enter="transform transition ease-in-out"
        enterFrom="translate-y-12 opacity-0"
        enterTo="translate-y-0 opacity-1"
        leave="transform transition ease-in-out"
        leaveFrom="translate-y-0 opacity-1"
        leaveTo="translate-y-12 opacity-0"
        className={styles.content}
      >
        {content && <RichText document={content} />}
      </Transition>
    </div>
  );
};

export default PartnerFAQItem;
