import React, { useContext, useState, useEffect } from 'react';

import styles from './styles.module.css';
import { Icon } from '../../../../core';
import DrawerHeader from '../DrawerHeader/index';

import { DrawerContext } from '../../../../../context/DrawerContext';
import { CheckoutCartContext } from '../../../../../context/CheckoutCartContext';
import { WishlistContext } from '../../../../../context/WishlistContext';
import { AccountContext } from '../../../../../context/AccountContext';
import { MarketingOptInPrompt } from '../../../../account/MarketingPreferences';
import { PaginationBar } from './PaginationBar';
import Link from 'next/link';
import List from './List';
import { usePrimaryCart } from '../../../../../data-fetching/cart/usePrimaryCart';

const PAGE_SIZE = 30;

const Wishlist = ({ config, disableBack }) => {
  const { hideDrawer } = useContext(DrawerContext);
  const { isLoggedIn } = useContext(AccountContext);
  const { wishlist, wishlistLoading } = useContext(WishlistContext);
  const wishlistItems = wishlist?.listcontents ?? [];

  const handleBack = () => hideDrawer();

  const { openCart } = useContext(CheckoutCartContext);
  const { cart } = usePrimaryCart();
  const wishlistCount = wishlist?.listcontents?.length || 0;

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentWishlistPage = wishlistItems.length > 0 ? wishlistItems.slice(startIndex, endIndex) : [];
  const totalPages = Math.ceil(wishlistItems.length / PAGE_SIZE);

  useEffect(() => {
    if (wishlistItems.length > 0) {
      const newTotalPages = Math.ceil(wishlistItems.length / PAGE_SIZE);
      if (currentPage > newTotalPages) {
        setCurrentPage(newTotalPages);
      }
    }
  }, [wishlistItems, wishlistCount]);

  useEffect(() => {
    if (wishlistCount > 0 && !isLoggedIn) {
    }
  }, [wishlistCount, isLoggedIn]);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={styles.drawerWrapper}>
      <div className={styles.drawerHeader}>
        <DrawerHeader title="Watchlist" subtitle={`${wishlistCount} items`} onBack={disableBack ? null : handleBack} />
      </div>

      {wishlistCount > 0 ? (
        <>
          {!isLoggedIn ? (
            <div className="relative bg-brandGreen/25 py-3 px-4 mb-2 mx-2 mt-2">
              <p className="body-14">
                Get alerts when the prices drop on your favourites.{' '}
                <Link href={'/account/login'} className={'inline-flex items-center font-bold ml-2;'}>
                  Create Thrift account <Icon title="arrowRight" />
                </Link>
              </p>
            </div>
          ) : (
            <>
              <MarketingOptInPrompt />
            </>
          )}
          <List
            list={currentWishlistPage}
            loading={wishlistLoading}
          />
          {totalPages > 1 && <div className="mt-7">
            <PaginationBar currentPage={currentPage} totalPages={totalPages} loading={wishlistLoading}
            onPageChange={handleChangePage}
        /></div>}
        </>
      ) : (
        <div className={styles.emptyCart}>
          <h3 className="body-14 text-center px-4">
            No items.
            <br />
            <br />
            Add items to your Watchlist to be the first to know when they price drop.
            <br />
            <br />
            Already got a watchlist?{' '}
            <Link className="underline" href="/account/login">
              Sign in
            </Link>{' '}
            to access it.
            <br />
            <br />
            There are {cart?.lines?.length || 0} items in{' '}
            <button onClick={openCart} className="underline">
              your bag
            </button>
          </h3>
        </div>
      )}
    </div>
  );
};

export default Wishlist;
