import { useQuery } from '@tanstack/react-query';
import { getQueryClient } from '../../context/QueryContext';
import { isNullOrUndefined } from '../../utils/objectHelpers';
import { ShopifyService } from '../../modules/shopify.service';
import { convertShopifyProductToCanonicalItem } from '../useShopifyItem';

export type CartID = string;

export const useCart = (cartId?: null | undefined | CartID) => {
  const result = useQuery({
    queryKey: ['carts', cartId],
    queryFn: ({ queryKey }) => {
      const interiorCartId = queryKey[1];
      if (isNullOrUndefined(interiorCartId)) return null;
      return ShopifyService.getCart({ cartId: interiorCartId });
    },
    staleTime: 15 * 1000,
    gcTime: 15 * 1000,
    refetchOnMount: 'always',
    refetchOnWindowFocus: true,
  }, getQueryClient());

  const { data, ...rest} = result;
  const cart = data?.cart;
  if (!cart) return { cart: null, metadata: rest };

  const {lines, ...restOfCart} = cart;
  const normalisedCart = {
    ...restOfCart,
    lines: lines.edges.map(edge => {
      return {
        ...(edge.node),
        canonicalItem: convertShopifyProductToCanonicalItem(edge.node.merchandise.product)
      }
    })
  };

  return { cart: normalisedCart, metadata: rest };
}

export const refreshCart = (cartId: string) => {
  return getQueryClient().invalidateQueries({ queryKey: ['carts', cartId]})
}

export type Cart = Exclude<Exclude<ReturnType<typeof useCart>['cart'], null>, undefined>;
export type CartLine = Cart['lines'][0];

