import { storyblokEditable } from '@storyblok/react';
import Link from 'next/link';
import Image from '../../../core/Image';

import styles from './styles.module.css';
import { BrandTileStoryblok } from './types';

interface Props {
  blok: BrandTileStoryblok;
}

const BrandTile = ({ blok }: Props) => {
  const { image, url } = blok || {};

  const imgSrc = image?.filename;
  const cachedURL = url?.cached_url;
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {cachedURL && <Link className={styles.link} href={cachedURL}></Link>}
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="Brand tile" />}
    </div>
  );
};

export default BrandTile;
