import React, { useEffect } from 'react';

type Position = {
  x: number,
  y: number,
  width: number,
  height: number,
  xPc: number,
  yPc: number
}

export const useMousePositionInContainer = <Element extends HTMLElement>(wrapper: React.MutableRefObject<Element | null>): Position | null => {
  const [position, setPosition] = React.useState<null | Position>(null);

  useEffect(() => {
    if (wrapper.current) {
      const element = wrapper.current;

      const reset = (e: Event) => {
        e.preventDefault();
        setPosition(null);
      };
      const calcPosition = ({ clientX, clientY }: { clientX: number, clientY: number }) => {
        const boundingRect = wrapper.current?.getBoundingClientRect();
        if (!boundingRect) return setPosition(null);
        const { left, top, width, height } = boundingRect;
        const x = (clientX - left);
        const y = (clientY - top);
        if (x >= width || x <= 0 || y >= height || y <= 0) return setPosition(null);
        return setPosition({ x, y, width, height, xPc: x / width, yPc: y / height });
      };

      const onMouseMove = (event: MouseEvent) => calcPosition(event);
      element.addEventListener('mousemove', onMouseMove);
      element.addEventListener('mouseout', reset);
      element.addEventListener('touchend', reset);
      return () => {
        element.removeEventListener('mousemove', onMouseMove);
        element.removeEventListener('mouseout', reset);
        element.removeEventListener('touchend', reset);
      };
    }
  }, [wrapper]);

  return position;
};