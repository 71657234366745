import { storyblokEditable } from '@storyblok/react';
import { ComponentIconsStoryblok } from '../../../../component-types-sb';
import Image from 'next/image';
import { RichText } from '../../../core';

interface Props {
  blok: ComponentIconsStoryblok;
}

const ComponentIcons = ({ blok }: Props) => {
  const { Text, Icon } = blok;
  return (
    <section>
        <div className="w-full flex flex-row relative gap-4 py-4" {...storyblokEditable(blok)}>
            <Image src={Icon.filename} alt={'icon'} width={130} height={130} className="aspect-square object-contain" />
            {Text && <RichText document={Text} className={'text-left'}></RichText>}
        </div>
    </section>
  );
};

export default ComponentIcons;