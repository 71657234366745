import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { AsSeenInListStoryblok } from './types';

interface Props {
  blok: AsSeenInListStoryblok;
}

const AsSeenInList = ({ blok }: Props) => {
  const { title, items } = blok || {};

  return (
    <section className={classNames(styles.container, 'Section', 'SingleColumnLarge')} {...storyblokEditable(blok)}>
      {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
      <div className={styles.items}>
        {items?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default AsSeenInList;
