import { useProductsWithActiveOffers } from "../../../../data-fetching/useActiveOffers";
import { Signpost } from "../../../core";
import { IndependentWishlistItemCard } from "../Drawer/Wishlist/List";
import { YourOffersStoryblok } from "./types";
import classNames from "classnames";
import styles from './styles.module.css';
import { useScrollbar } from "../Watchlist/useScrollbar";
import { Skeleton } from "../../../account/Common/Skeleton";

interface Props {
    blok: YourOffersStoryblok;
}

export const YourOffers = ({ blok }: Props) => {
    const { productsWithActiveOffer, loading } = useProductsWithActiveOffers(5);
    const { scrollbarRef, scrollbarSliderRef } = useScrollbar();
    const { text } = blok ?? {};

    if (!productsWithActiveOffer || productsWithActiveOffer.length < 2) return null;

    return (
        <section className={classNames('Section')}>
                <div className={styles.container}>
                    {text && <h3 className={classNames(styles.title, 'display-18-caps')}>{text}</h3>}
                    {loading ?
                    <div className="flex flex-row mr-5 mt-8 justify-center items-center gap-10">
                        <Skeleton height={290} width={600} />
                        <Skeleton height={290} width={600} />
                        <Skeleton height={290} width={600} />
                        <Skeleton height={290} width={600} />
                        <Skeleton height={290} width={600} />
                    </div> : <>
                    <div className="relative flex flex-col justify-start">
                        <div className={styles.products} ref={scrollbarRef}>
                            {productsWithActiveOffer.map((product) => {
                                return <IndependentWishlistItemCard key={product.handle} handle={product.handle}
                                className={styles.card} />
                            })}
                        </div>
                        <div className={styles.slider}>
                            <div ref={scrollbarSliderRef} className={styles.sliderHandle} />
                        </div>
                    </div>
                    <div className="flex relative mb-2 mt-1 md:mt-2 mb-6 md:mb-4">
                        <Signpost href="/account/offers" signpostText="See all"></Signpost>
                    </div></>}
                </div>
        </section>
    );
}