import { storyblokEditable } from '@storyblok/react';
import { Image, Signpost } from '../../../core';
import styles from './styles.module.css';
import { InstafeedStoryblok, InstaFeed, InstaPost } from './types';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import Link from 'next/link';

interface Props {
  blok: InstafeedStoryblok;
}

const Instafeed = ({ blok }: Props) => {
  const { title, signpost_text, url } = blok || {};
  const cachedURL = url?.cached_url;
  const [items, setItems] = useState<InstaPost[]>([]);

  const resyncInstagram = useCallback(async () => {
    try {
      const res = await fetch(
        'https://instafeed.nfcube.com/feed/v4?charge=0&fu=0&limit=9&account=thrift-test.myshopify.com&fid=0&hash=236b6ea8a55dbd43ceb0087822b6f9f3'
      );

      const resJson: InstaFeed = await res.json();
      setItems(resJson.data);
    } catch (error) {
      console.log('ERROR FETCHING INSTAFEED', error);
    }
  }, []);

  useEffect(() => {
    resyncInstagram();
  }, []);

  return (
    <section className={classNames(styles.container, 'Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      {title && <h2 className={classNames(styles.title, 'display-18-caps')}>{title}</h2>}
      <div className={styles.items}>
        {items?.map((x) => (
          <Link key={x.link} href={x.link} target="_blank">
            {x.images.standard_resolution && <img src={x.images.standard_resolution.url} alt="instagram image" className={styles.image} />}
          </Link>
        ))}
      </div>
      <div className={styles.instaLink}>
        {signpost_text && <Signpost target="_blank" signpostText={signpost_text} href={cachedURL} />}
      </div>
      <p></p>
    </section>
  );
};

export default Instafeed;
