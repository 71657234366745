import React from 'react';
import { IconProps } from './types';
import { ChevronDown, HeartWishlistIcon, HeartWishlistIconRemove } from './iconList';
import {
  CloseDefault,
  HamburgerMenu,
  Bag,
  Heart,
  User,
  Search,
  ArrowRight,
  ArrowLeft,
  ReturnBag,
  MedalIcon,
  DeliveryTruck,
  CloseIcon,
  ReturnIcon,
  MinusRound,
  CloseDrawer,
  Tick,
  ChevronRight,
  ChevronLeft,
  ArrowUp,
  Printer,
  CreditIcon,
  ItemSold,
  COSaved,
  WaterDrop,
  InfoCircle
} from './iconList';

const IconList = {
  close: CloseDefault,
  closeAlt: CloseIcon,
  closeDrawer: CloseDrawer,
  hamburger: HamburgerMenu,
  bag: Bag,
  heart: Heart,
  user: User,
  search: Search,
  arrowRight: ArrowRight,
  arrowLeft: ArrowLeft,
  arrowUp: ArrowUp,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  chevronDown: ChevronDown,
  heartWish: HeartWishlistIcon,
  heartWishRemove: HeartWishlistIconRemove,
  returns: ReturnBag,
  medal: MedalIcon,
  delivery: DeliveryTruck,
  return: ReturnIcon,
  minusRound: MinusRound,
  tick: Tick,
  printer: Printer,
  creditIcon: CreditIcon,
  itemSold: ItemSold,
  coSaved: COSaved,
  waterDrop: WaterDrop,
  infoCircle: InfoCircle
};

const Icon = ({ title, height, width, size, className, onClick, color }: IconProps) => {
  const SelectIcon = IconList[title];
  return (
    <span className={className} onClick={onClick}>
      <SelectIcon height={height} width={width} size={size} color={color} />
      <span className="sr-only">{title}</span>
    </span>
  );
};

export default Icon;
