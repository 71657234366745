

// ensures contents are a string and that string is not empty
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';

export const isNonEmptyStr = (text: any): text is string => {
  if (typeof text !== 'string') return false;
  return text.trim().length > 0;
};

export const toStr = (anything: any): string => {
  if (isNotNullOrUndefined(anything)) return `${anything}`
  return '';
}