import { useCookies } from 'react-cookie';
import { useQuery } from '@tanstack/react-query';
import { GetCustomerByAccessTokenQuery, ShopifyService } from '../modules/shopify.service';
import { isNonEmptyStr } from '../utils/stringUtils';
import { Address } from '../components/pages/account/bag-returns/AddressInput/AddressForm';
import { isNullOrUndefined } from '../utils/objectHelpers';

export const useShopifyAccessToken = (): string | undefined => {
  const [{ customerAccessToken }] = useCookies(['customerAccessToken']);
  return customerAccessToken;
}

export const useShopifyCustomer = () => {
  const customerToken = useShopifyAccessToken();

  const { data, error, isLoading } = useQuery({
    queryKey: ['customer', 'shopify', customerToken],
    queryFn: async (options) => {
      const accessToken = options.queryKey[2];
      if (isNullOrUndefined(accessToken)) return null;
      return await ShopifyService.getCustomerByAccessToken({ accessToken });
    },
    gcTime: 0,
    staleTime: 50,
  });
  return { error, isLoading, shopifyCustomer: data };
};

const splitBuildingNumberOut = (address: string): [string, string] => {
  const matches = address.trim().match(/^([\d-]+)/);
  if (matches === null) return ['', address];
  const buildingNumber = matches[0];
  return [buildingNumber.trim(), address.slice(buildingNumber.length).trim()];
};

export type ShopifyAddress = {
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  phone?: string | null,
  zip?: string | null
};

export const shopifyAddressToZigZagAddress = (shopifyAddress: ShopifyAddress): Address => {
  const normalisedStreetAddress1 = (shopifyAddress?.address1 ?? '').trim();
  const normalisedStreetAddress2 = (shopifyAddress?.address2 ?? '').trim();
  const [buildingNumber, restOfAddress] = splitBuildingNumberOut(normalisedStreetAddress1);
  const [backupBuildingNumber, restOfAddress2] = splitBuildingNumberOut(normalisedStreetAddress2);
  const building = isNonEmptyStr(buildingNumber) ? buildingNumber : backupBuildingNumber;
  return {
    building: building,
    address1: restOfAddress,
    address2: restOfAddress2,
    city: shopifyAddress?.city ?? '',
    phone: shopifyAddress?.phone ?? '',
    postcode: shopifyAddress?.zip ?? '',
  };
};

export const shopifyCustomerToZigZagAddress = (shopifyCustomer: GetCustomerByAccessTokenQuery | null | undefined): Address | undefined => {
  const address = shopifyCustomer?.customer?.defaultAddress;
  if (!address) return undefined;
  return shopifyAddressToZigZagAddress(address);
};