import React, { useState, useContext } from 'react';

import { IntlUtility } from '../../../helpers';
import { Image } from '../../core';
import styles from './styles.module.css';
import classNames from 'classnames';
import CustomSelect from '../../core/CustomSelect';
import { thriftBagVariantIDs } from '../../../utils/constants';
import Link from 'next/link';
import { WishlistContext } from '../../../context/WishlistContext';
import { roundNumberDownToNearest5 } from '../../../utils/helpers';
import { MAX_BAGS_PER_ORDER } from '../../../consts/bags';
import { Help } from '../../account/Payout/Help';
import { useStoryblokConfig } from '../../../context/ConfigContext';
import { CartLine } from '../../../data-fetching/cart/useCart';
import { toPDPtitle } from '../../sections/ProductSingle/utils/helpers';
import { useCartLine } from '../../../data-fetching/cart/useCartLine';

interface Props {
  item: CartLine;
  visible?: boolean;
  updateCart?: () => void;
}

export const ThriftBagCartItem: React.FC<Props> = ({ item }) => {
  const { addItemToWishlist, itemIsInWishlist } = useContext(WishlistContext); //?
  const { removeFromCart, setQuantity } = useCartLine(item.merchandise.id);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateQty = async (qty:number) => {
    if (!setQuantity) return;
    setIsLoading(true);
    const cappedQty = Math.min(qty, MAX_BAGS_PER_ORDER);
    await setQuantity(cappedQty);
    setIsLoading(false);
  };

  const options = new Array(MAX_BAGS_PER_ORDER)
    .fill(null)
    .map((_, i) => ({ label: `${i+1}`, value: i+1 }));


  const isAvailable = item.merchandise.availableForSale;

  const price = parseFloat(item.cost.totalAmount.amount);
  const compareAtPrice = item.merchandise.compareAtPrice ? parseFloat(item.merchandise.compareAtPrice.amount) : parseFloat(item.merchandise.price.amount);

  const url = `/products/${item.canonicalItem?.handle}`;

  return (
    <div key={item.id} className={classNames(styles.list, { [styles.listLoading]: isLoading })}>
      <div className={styles.imageCont}>
        <Link href={url} className="forced-aspect-square block">
          {item.merchandise.image ? <Image src={item.merchandise.image.url} alt={item.merchandise.image.altText ?? item.merchandise.product.title} className={styles.image} /> : null}
        </Link>
        {!isAvailable && <div className={classNames('display-10-caps', styles.soldOut)}>Sold out</div>}
      </div>

      <div className={styles.itemCont}>
        <Link href={url}>
          <p className="body-14 block md:hidden">{item.merchandise.product.title}</p>
          <p className="body-14 hidden md:block">{item.merchandise.product.title}</p>
        </Link>
        <p className="body-14">{item.canonicalItem?.size.display}</p>

        <p className="body-14-bold whitespace-pre-line">
          { price === 0 ? (
            "FREE"
          ) : IntlUtility.formatPrice({
            amount: price,
            currencyCode: item.cost.totalAmount.currencyCode,
          })}
          {(compareAtPrice && compareAtPrice !== price ) ? (
            <span className={'line-through pl-1 text-black50'}>
              {IntlUtility.formatPrice({
                amount: compareAtPrice,
                currencyCode: item.cost.totalAmount.currencyCode,
              })}
            </span>
          ) : null}
        </p>
      </div>


      <div className={styles.btnCol}>
          <label className="body-12 mb-2">
            <span className={"flex items-center gap-1 my-1"}>Qty <Help maxWidth={125}>You can fit 15-20 items in one bag.</Help></span>
            <CustomSelect
              options={options}
              value={item.quantity}
              onChange={({ value }) => handleUpdateQty(value)}
              fullWidth={false}
            />
          </label>
        <button className="body-12 mb-1 text-right whitespace-nowrap" onClick={() => removeFromCart && removeFromCart()} >
          Remove
        </button>
        {isAvailable && item.canonicalItem && !itemIsInWishlist(item.canonicalItem) && (
          <button className="body-12 underline mb-1 text-right whitespace-nowrap" onClick={async () => {
            if (!item.canonicalItem) return;
            await addItemToWishlist(item.canonicalItem).then(() => {
              handleUpdateQty(0);
            });
          }}>
            Save for later
          </button>
        )}
      </div>
    </div>
  );
};