import { RelatedProducts as RelatedProductsWidget } from '@algolia/recommend-react';
import recommend from '@algolia/recommend';
import { algoliaIndices } from '../../../utils/constants';
import { Index } from '../../shopify/SimpleItemCard';
import { convertAlgoliaHitToCanonicalItem } from '../../../data-fetching/useAlgoliaHit';

const recommendClient = recommend(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_RECOMMEND_API_KEY
);

const RelatedItem = ({ item: hit }) => {
  return <Index item={convertAlgoliaHitToCanonicalItem(hit)} />;
};

const HeaderComponent = () => {
  return <h3 className={'body-18-bold uppercase'}>You may also like</h3>;
};

const RelatedProducts: React.FC<{variantId: number}> = ({ variantId }) => {
  return (
    <div className="bg-brandYellow25">
      <RelatedProductsWidget
        classNames={{
          root: 'px-3 py-6 Container',
          list: 'mt-2 grid gap-y-6 gap-x-2 grid-cols-3 md:grid-cols-6 xl:gap-x-4',
        }}
        recommendClient={recommendClient}
        indexName={algoliaIndices.shopifyProducts}
        objectIDs={[`${variantId}`]}
        itemComponent={RelatedItem}
        headerComponent={HeaderComponent}
        maxRecommendations={12}
      />
    </div>
  );
};

export default RelatedProducts;
