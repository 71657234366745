import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { BlockQuoteListStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: BlockQuoteListStoryblok;
}

const BlockQuoteList = ({ blok }: Props) => {
  const { title, block_quotes, full_width_large } = blok || {};

  return (
    <section
      className={classNames(styles.container, 'Section', 'Section--spaced', {
        ['SingleColumnLarge']: !full_width_large,
        [styles.fullWidth]: full_width_large,
      })}
      {...storyblokEditable(blok)}
    >
      {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
      <div className={styles.items}>
        {block_quotes?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default BlockQuoteList;
