import { storyblokEditable } from '@storyblok/react';
import styles from './styles.module.css';
import { PdpIconStoryblok } from './types';
import Image from '../../../../core/Image';
import { RichText } from '../../../../core';

interface Props {
  blok: PdpIconStoryblok;
}

const PdpIcon = ({ blok }: Props) => {
  const { icon, text } = blok || {};

  const iconSrc = icon?.filename;

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {iconSrc && <Image className={styles.icon} src={iconSrc} alt={icon?.alt} />}
      <RichText document={text} className={styles.text} />
    </div>
  );
};

export default PdpIcon;
