import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import Signpost from '../../../core/Signpost';
import styles from './styles.module.css';
import { FeatureListStoryblok } from './types';

interface Props {
  blok: FeatureListStoryblok;
}

const FeatureList = ({ blok }: Props) => {
  const { title, features, signpost_text, url, full_width_large } = blok || {};
  const cachedURL = url?.cached_url;
  return (
    <section
      className={classNames(styles.container, 'Section', 'Section--spaced', {
        ['SingleColumnLarge']: !full_width_large,
        [styles.gridLarge]: full_width_large,
      })}
      {...storyblokEditable(blok)}
    >
      {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
      <div className={styles.items}>
        {features?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
      {signpost_text && <Signpost signpostText={signpost_text} href={cachedURL} />}
    </section>
  );
};

export default FeatureList;
