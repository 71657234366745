import { Middleware } from 'openapi-fetch';
import { parseCookies } from 'universal-cookie/lib/utils';
import { CoreEntities } from '../entities';

export const authMiddleware: Middleware = {
  async onRequest(req, options) {

    const isPublicRoute = req.url.includes('/public/v1/');
    if (isPublicRoute) return req;

    const cookies = parseCookies(document.cookie) as Record<string, string | undefined>;
    const accessToken = cookies["customerAccessToken"];
    if (!accessToken) {
      if (window.location.href.includes('/account')) {
        document.cookie = 'customerAccessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        window.location.href = '/account/login';
      } else {
        throw new Error('Request token ')
      }
    } else {
      req.headers.set('x-customer-auth', accessToken);
      return req;
    }
  },
  async onResponse(res, options) {
    if (res.status === 401) {
      //Delete the cookie
      document.cookie = 'customerAccessToken=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      if (window.location.href.indexOf('/account') > -1) {
        window.location.href = '/account/login';
      }
    }
    else {
      return res
    }
  },
};

// seems to be a bug where 204 bodies are still attempted to be parsed, this just makes sure that the body is undefined
export const fix204sMiddleware: Middleware = {
  async onRequest(req, options) {
    return req;
  },
  async onResponse(res, options) {
    if (res.status === 204) {
      return new Response(undefined, { status: 204, statusText: "No Content", headers: res.headers  });
    } else {
      return res
    }
  },
};