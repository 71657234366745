import Link from 'next/link';

const FooterLink = ({ blok, className }) => {
  const { text, url } = blok;

  let cachedURL = url?.cached_url;

  if (!cachedURL.includes('http') && cachedURL[0] !== '/') {
    // Make sure we're linking to the absolute path
    cachedURL = '/' + cachedURL;
  }
  return (
    <Link className={className} href={cachedURL}>
      {text}
    </Link>
  );
};

export default FooterLink;
