import React from 'react';
import Link from 'next/link';
import styles from './styles.module.css';
import classNames from 'classnames';

import { CanonicalItem } from '../../../data-fetching/canonicalItem';

interface Props {
  currentUrl?: string;
  prevUrl?: string;
  item?: CanonicalItem;
}

const Breadcrumbs: React.FC<Props> = ({ currentUrl, prevUrl, item }: Props) => {

  const url = item ? prevUrl : currentUrl; //uses current url on collection or search. Prev url on PDP

  const urlArr = url?.split('/');

  const isCollectionOrSearch = url?.includes('women/') || url?.includes('men/');

  const department = item
    ? item.department.replace(/['s]/g, '').toLowerCase()
    : urlArr?.find((path) => path === 'men' || path === 'women');

  const layout = isCollectionOrSearch ? urlArr[urlArr.length - 1].replace(/\.json/g, '') : 'all'; //Format collection/search

  const layoutDisplay = layout && layout.split('?')[0].replace(/-/g, ' ');

  const { title, handle, display_title } = item || {};

  return (
    <ul className={classNames(styles.container, 'Section')}>
      {department && (
        <li className={styles.item}>
          <Link className={classNames('underline', styles.itemLink)} href={`/${department}`}>
            {department}
          </Link>
        </li>
      )}

      {department && <li className={styles.spacer}>{'>'}</li>}
      {department && layout && (
        <li className={styles.item}>
          <Link className={classNames(styles.itemLink, { underline: item })} href={`/${department}/${layout}`}>
            {layoutDisplay}
          </Link>
        </li>
      )}
      {handle && title && layout && <li className={styles.spacer}>{'>'}</li>}
      {handle && title && (
        <li className={styles.item}>
          <Link className={styles.itemLink} href={`/products/${handle}`}>
            {display_title}
          </Link>
        </li>
      )}
    </ul>
  );
};

export default Breadcrumbs;
