import { storyblokEditable } from '@storyblok/react';
import Link from 'next/link';
import styles from './styles.module.css';
import { DrawerSubcategoryItemStoryblok } from './types';

interface Props {
  blok: DrawerSubcategoryItemStoryblok;
}

const DrawerSubcategoryItem = ({ blok }: Props) => {
  const { url, title } = blok || {};

  const cachedURL = url?.cached_url;
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {cachedURL && <Link className={styles.link} href={cachedURL}></Link>}
      <div className={styles.content}>{title && <h5 className="body-14-bold">{title}</h5>}</div>
    </div>
  );
};

export default DrawerSubcategoryItem;
