import React, { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import { Signpost, Button, IconsBlock } from '../../core';

import { IntlUtility } from '../../../helpers';
import { DrawerContext } from '../../../context/DrawerContext';
import { CartList } from '../../shopify/CartList';

import { SessionContext } from '../../../context/SessionContext';
import ThriftBagUpsell from '../../shopify/ThriftBagUpsell';
import { thriftBagVariantID, thriftBagVariantIDs } from '../../../utils/constants';
import { AccountContext } from '../../../context/AccountContext';
import { Tracking } from '../../../utils/tracking';
import { CheckoutCartContext } from '../../../context/CheckoutCartContext';
import Banner from '../../banner';
import { useCartLine } from '../../../data-fetching/cart/useCartLine';
import { usePrimaryCart, usePrimaryCartOfferRefresher } from '../../../data-fetching/cart/usePrimaryCart';

interface Props {}

export const Cart: React.FC<Props> = () => {
  const { hideDrawer } = useContext(DrawerContext);
  const { cart } = usePrimaryCart();
  const { basePath } = useContext(SessionContext);
  const { customerToken } = useContext(AccountContext);
  const { showThriftBagUpsell } = useContext(CheckoutCartContext);
  const router = useRouter();

  const cartCount = cart?.lines.length || 0;

  const subtotal = {
    amount: cart ? parseFloat(cart.cost.subtotalAmount.amount) : 0,
    currencyCode: cart?.cost.subtotalAmount.currencyCode,
  };

  const subtotalBeforeDiscountsApplied = cart?.lines.reduce((total, item) => {
    const priceAmount = parseFloat(item.cost.totalAmount.amount)
    const compareAtAmountString = item.merchandise.compareAtPrice?.amount;
    const compareAtPriceAmount = compareAtAmountString ? parseFloat(compareAtAmountString) : priceAmount;

    return total + compareAtPriceAmount;
  }, 0) ?? 0;

  const compareAtSubtotal = {
    amount: subtotalBeforeDiscountsApplied,
    currencyCode: subtotal.currencyCode,
  };

  const totalSaving = {
    amount: subtotalBeforeDiscountsApplied - subtotal.amount,
    currencyCode: subtotal.currencyCode,
  };

  const iconsList = [
    {
      title: 'returns',
      text: 'Fuss-free 30 day returns',
    },
    {
      title: 'delivery',
      text: 'Express delivery within 2 days',
    },
    {
      title: 'medal',
      text: 'Every item is quality checked',
    },
  ];

  const cartContainsThriftBag = (cart?.lines.findIndex((line) => thriftBagVariantIDs.includes(line.merchandise.id)) ?? 0)> -1;

  const {addToCart} = useCartLine(thriftBagVariantID);
  const shouldShowThriftBagUpsell = showThriftBagUpsell && !cartContainsThriftBag;
  const offerRefresher = usePrimaryCartOfferRefresher();

  useEffect(() => {
    offerRefresher();
  }, []);

  return (
    <>
    <Banner content={<div className="p-2 whitespace-pre-line">Each piece is unique,<strong> snap up your finds quick.</strong></div>} />
      {cartCount > 0 ? (
        <>
          <div className="overflow-y-auto overflow-x-hidden flex-1">
            <CartList items={cart?.lines || []} />
            {shouldShowThriftBagUpsell && addToCart && <ThriftBagUpsell addItem={addToCart} />}
          </div>

          <div className="py-4 px-4 border-t border-gray-200 flex flex-col justify-between">
            <Signpost
              onClick={() => {
                hideDrawer();
                router.push(basePath);
              }}
              signpostText="Continue shopping"
            />
            <IconsBlock icons={iconsList} isList={true} />
            <div className="flex justify-between">
              <div className="flex flex-row w-1/3">
                <div className="mr-2">
                  <h3 className="body-14">
                    Subtotal:
                      <span className="body-14-bold">
                      {IntlUtility.formatPrice(subtotal)}
                      {subtotalBeforeDiscountsApplied > 0 && subtotalBeforeDiscountsApplied !== subtotal.amount && (
                        <span className={'line-through pl-1 text-black50 mr-2'}>
                          {IntlUtility.formatPrice(compareAtSubtotal)}
                        </span>
                      )}
                      </span>
                  </h3>
                  {totalSaving.amount > 0 && (
                    <h3 className="body-14-bold text-brandRed text-xs font-family: italic">
                      Savings: {IntlUtility.formatPrice(totalSaving)}
                    </h3>
                  )}
                </div>
              </div>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  let cartUrl = cart?.checkoutUrl.replace('thrift-test.myshopify.com', 'order.thrift.plus'); // Ensure the user gets directed to the new url
                  Tracking.initiateCheckout(
                    subtotal.amount,
                    cart?.lines.map((itm) => ({
                      item_id: itm.merchandise.product.id,
                      item_name: itm.merchandise.product.title,
                      item_brand: itm.canonicalItem?.brand ?? 'unknown',
                      price: itm.cost.totalAmount.amount,
                    })) ?? []
                  );
                  if (customerToken) {
                    window.location.href = `${cartUrl}?a_mode=${!!window['ReactNativeWebView'] ? '1' : '0'}`;
                  } else {
                    let additionalTrackingData = '';

                    if (!!window['ReactNativeWebView']) {
                      // If we're visiting from the app, add in the additional tracking
                      // Note: if this gets changed, also change it in checkout/start.tsx + in the app itself
                      additionalTrackingData = 'utm_source=thrift-app&utm_medium=thrift-app';
                    }
                    window.location.href = cartUrl + '?' + additionalTrackingData;
                  }
                }}
                text="Checkout"
                className="md:w-2/3"
              />
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col justify-center items-center h-48">
          <h3 className="body-14 mb-8">No items in your bag</h3>
          {shouldShowThriftBagUpsell && addToCart && <ThriftBagUpsell addItem={addToCart} />}
        </div>
      )}
    </>
  );
};
