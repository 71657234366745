import React, { PropsWithChildren, useMemo } from 'react';
import { ProductSearchState, SearchableFacets } from '../queries/types';
import { isFacetOptionActive } from './utils';
import { RefinementToggle } from './RefinementToggle';
import { RefinementTogglesForFacet } from './RefinementTogglesForFacet';
import styles from '../../storyblok/nested/Drawer/Filters/styles.module.css';
import { Icon } from '../../core';

type FacetOptions = Record<string, number>;
type Props = {
  facet: SearchableFacets,
  searchState: ProductSearchState,
  options: FacetOptions,
  groupRefinements: (facetOptions: FacetOptions) => Record<string, FacetOptions>; // group into headings
}

export const GroupedRefinementsForFacet: React.FC<Props> = ({ facet, options, searchState, groupRefinements }) => {
  const groupedRefinements = Object.entries(groupRefinements(options));
  const onlyOneGroup = groupRefinements.length === 1;
  return (
    <>
      {groupedRefinements.map(([title, groupedOptions]) =>
        <CollapsibleRefinements defaultOpen={onlyOneGroup} key={title} facet={facet} searchState={searchState} options={groupedOptions}>{title}</CollapsibleRefinements>
      )}
    </>
  );
};


export const CollapsibleRefinements: React.FC<PropsWithChildren<{
  facet: SearchableFacets,
  searchState: ProductSearchState,
  options: FacetOptions,
  defaultOpen?: boolean,
}>> = ({ children, facet, options, searchState, defaultOpen }) => {
  const [isExpanded, setIsExpanded] = React.useState(defaultOpen ?? false);
  if (Object.entries(options).length === 0) return null;
  return (
    <section>
      <button
        className={'font-bold text-sm py-4 px-2 border-b-1 border-gray-300 w-full text-left overflow-auto flex justify-between items-center'}
        onClick={() => setIsExpanded(!isExpanded)}
      >{children}
        <span style={{ transform: isExpanded ? 'scaleY(-100%)' : '' }} className={"transition"}>
          <Icon title='chevronDown' width={16} height={9}/>
        </span>
      </button>
      {isExpanded ? (
        <ul style={{ scrollbarWidth: 'thin' }} className={'overflow-auto'}>
        <RefinementTogglesForFacet facet={facet} searchState={searchState} options={options} />
        </ul>
      ): null}
    </section>
  );
};