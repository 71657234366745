import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Button, RichText } from '../../../core';
import { GetStartedPartnerStepStoryblok } from './types';
import styles from './styles.module.css';
import { thriftBagVariantID } from '../../../../utils/constants';

import router from 'next/router';
import { useContext } from 'react';
import { CheckoutCartContext } from '../../../../context/CheckoutCartContext';
import SignpostLink from '../../../core/SignpostLink';
import Tooltip from '../../../core/Button/Tooltip';
import { MAX_BAGS_PER_ORDER } from '../../../../consts/bags';
import { useThriftBagCartLine } from '../../../../data-fetching/cart/useCartLine';

interface Props {
  blok: GetStartedPartnerStepStoryblok;
  next: () => void;
  isLastSlide: boolean;
  variantID: string;
}

const GetStartedPartnerStep = ({ blok, next, isLastSlide, variantID }: Props) => {
  const { content, cta_text, button_colour } = blok || {};
  const {addToCart, maxAllowedQuantity, quantity} = useThriftBagCartLine(variantID);
  const {openCart} = useContext(CheckoutCartContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  async function handleClearOut() {
    if (!addToCart) return openCart();
    setIsLoading(true);
    await addToCart();
    setIsLoading(false);
  }

  return (
    <div className=" w-full h-full relative p-10 flex flex-col items-center" {...storyblokEditable(blok)}>
      {content && <RichText className={styles.content} document={content} />}
      {cta_text && (
        <>
          {isLastSlide ? (
            <>
            <Button
              text={cta_text}
              onClick={handleClearOut}
              isLoading={isLoading}
              loadingText={'Adding'}
              isSecondary={true}
              bgColour={button_colour}
            />
            <Tooltip open={showTooltip} text={`Max ${MAX_BAGS_PER_ORDER}`} />
            </>
          ) : (
            <SignpostLink signpostText={cta_text} onClick={next} isSecondary={true} bgColour={button_colour} />
          )}
        </>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default GetStartedPartnerStep;
