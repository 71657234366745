import { useContext, useEffect, useState } from "react"
import { WishlistContext } from "../context/WishlistContext";
import { useQuery } from '@tanstack/react-query';
import { cleanId } from "../utils/shopifyUtils";
import { SwymList } from '../modules/@swym/swym.service';
import { isNullOrUndefined } from '../utils/objectHelpers';

// returns null if the wishlist is not initialised yet
export const countProductInWishlist = (shopifyProductId: string | number, wishlist?: SwymList): number | null => {
  if (isNullOrUndefined(wishlist)) return null;
    return wishlist
        .listcontents
        .filter(product => cleanId(product.empi) === cleanId(shopifyProductId))
        .length;
};

export const useItemWatchCount = (shopifyProductId: string | number): number | null => {
    const { getItemWatchCount, wishlist } = useContext(WishlistContext);

    const countInWishlist =  countProductInWishlist(shopifyProductId, wishlist);

    const result = useQuery({
      queryKey: ['items', shopifyProductId, 'watch-count', countInWishlist],
      queryFn: (options) => {
        const countInWishlist = options.queryKey[3];
        if (countInWishlist === null) return null;
        const id = options.queryKey[1] as string | number;
        return getItemWatchCount(cleanId(id))
      },
      staleTime: 3 * 60 * 1000,
      gcTime: 3 * 60 * 1000,
    })

    return result.data ?? null
}