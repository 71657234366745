import { useRef, useState } from "react";
import { makeCoreRequest } from "../../utils/account";
import { Charity } from "../../types/charity";
import { SetStateAction, Dispatch } from "react";

const searchCharities = (searchTerm: string) => {
    const options = {
        method: 'GET',
        url: `/charities?search=${encodeURIComponent(searchTerm)}`,
    };

    return makeCoreRequest(options);
};

type Props = {
    setter: Dispatch<SetStateAction<Charity | null>>;
}

export const CharitySearch: React.FC<Props> = ({ setter }: Props) => {
    const [charities, setCharities] = useState<Charity[]>([]);
    const [charityInputString, setCharityInputString] = useState<string>('');
    const charityInputStringRef = useRef(charityInputString);
    charityInputStringRef.current = charityInputString;
    const timeOfLastQuery = useRef<number>(0);
    const charitySearchTimeout = useRef<NodeJS.Timeout | null>(null);

    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const [areCharitiesLoading, setAreCharitiesLoading] = useState<boolean>(false);

    const debounce = () => {
        // need to check if the trimmed string is any different
        if (
            charityInputStringRef.current.trim().length < 2
        ) {
            return;
        }

        // if there is currently a req in process return
        // the request will check if a new req is needed once it finishes
        if (areCharitiesLoading) {
            return;
        }

        //check if cool down has passed
        if (Date.now() - timeOfLastQuery.current > 1000) {
            queryCharities();
            return;
        }

        //check if no timeout, create one
        if (!charitySearchTimeout.current) {
            charitySearchTimeout.current = setTimeout(() => {
                queryCharities();
                charitySearchTimeout.current = null;
            }, 500 - (Date.now() - timeOfLastQuery.current));
            return;
        }
    };

    const queryCharities = async () => {
        setAreCharitiesLoading(true);

        const queriedString = charityInputStringRef.current.trim();

        if (queriedString.length < 2) {
            setCharities([]);
            setAreCharitiesLoading(false);
            return;
        }

        try {
            const response = await searchCharities(charityInputStringRef.current.trim() || '');
            setCharities(response?.body || []);
        } catch (err) {
            // TODO: setup proper errors
            console.log(err);
        }
        timeOfLastQuery.current = Date.now();

        if (charityInputStringRef.current.trim() !== queriedString && !charitySearchTimeout.current) {
            charitySearchTimeout.current = setTimeout(() => {
                queryCharities();
                charitySearchTimeout.current = null;
            }, 500 - (Date.now() - timeOfLastQuery.current));
        }

        setAreCharitiesLoading(false);
        return;
    };

    const selectCharity = (charity: Charity) => {
        setter(charity);
        setCharityInputString(charity.name);
        setDropdownVisible(false);
    };

    return (
        <div className={'flex flex-col '}>
            <input
                type="text"
                id="brand_search"
                placeholder="search..."
                onChange={(e) => {
                    setCharityInputString(e.target.value);
                    debounce();
                }}
                value={charityInputString}
                onFocus={() => {
                    setDropdownVisible(true);
                }}
            />
            {dropdownVisible && charities.length > 0 ? (
                <div className={'border-l border-r border-b border-black max-h-64 overflow-scroll bg-white'}>
                    {charities.map((charity) => {
                        return (
                            <div
                                key={charity.name}
                                className={'cursor-pointer pl-2 py-0.5'}
                                onClick={() => {
                                    selectCharity(charity);
                                }}
                            >
                                {charity.name}
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    )
}