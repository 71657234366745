import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import Signpost from '../../../core/Signpost';
import styles from './styles.module.css';
import { GridStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: GridStoryblok;
}

const Grid = ({ blok }: Props) => {
  const { title, subtitle, items, columns, columns_large, signpost_text, url } = blok || {};
  const cachedURL = url?.cached_url;

  const columnsClass = styles[`items-${columns}`];
  const columnsLargeClass = styles[`items-large-${columns_large}`];

  return (
    <section className={classNames('Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      {title && <h2 className={classNames(styles.title, 'display-18-caps')}>{title}</h2>}
      {subtitle && <p className="body-14 mb-4">{subtitle}</p>}
      <div className={classNames(styles.items, columnsClass, columnsLargeClass)}>
        {items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
      {signpost_text && <Signpost signpostText={signpost_text} href={cachedURL} />}
    </section>
  );
};

export default Grid;
