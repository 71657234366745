import { storyblokEditable } from '@storyblok/react';
import styles from './styles.module.css';
import { StatHighlightItemStoryblok } from './types';
import { RichText } from '../../../core';

interface Props {
  blok: StatHighlightItemStoryblok;
}

const StatHighlightItem = ({ blok }: Props) => {
  const { text } = blok || {};

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <RichText className="body-14" document={text} />
    </div>
  );
};

export default StatHighlightItem;
