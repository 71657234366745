import { ProductSearchState, SearchableFacets } from '../queries/types';
import React, { PropsWithChildren } from 'react';
import Icon from '../../core/Icons';

import { facetRefinementToDisplayString } from './utils';
import { useSearchUpdater } from '../queries/useSearchUpdater';

const useFacetOptionToggle = ({ facet, searchState }: { facet: SearchableFacets, searchState: ProductSearchState }) => {
  const searchUpdater = useSearchUpdater();

  return async (option: string) => {
    const facetState = searchState[facet];

    if (Array.isArray(facetState)) {
      if (facetState.includes(option)) {
        await searchUpdater({
          ...searchState,
          [facet]: facetState.filter(appliedOption => appliedOption !== option),
        });
      } else {
        await searchUpdater({
          ...searchState,
          [facet]: [...facetState, option],
        });
      }
    } else if (typeof facetState === 'undefined') {
      await searchUpdater({
        ...searchState,
        [facet]: option,
      });
    } else {
      if (facetState === option) {
        await searchUpdater({
          ...searchState,
          [facet]: undefined,
        });
      } else {
        await searchUpdater({
          ...searchState,
          [facet]: [facetState, option],
        });
      }
    }
  };
};


export const RefinementToggle: React.FC<PropsWithChildren<{
  facet: SearchableFacets,
  searchState: ProductSearchState,
  option: string,
  resultCount?: number
}>> = ({ children, facet, option, resultCount, searchState }) => {
  const toggleOption = useFacetOptionToggle({ facet, searchState });
  const facetState = searchState[facet];
  const checked = Array.isArray(facetState) ? facetState.includes(option) : facetState === option;

  if (option === 'false' && !checked) return null; // hides special case for boolean facet, original_tags
  return (
    <li className={'flex'} key={option}>
      <button onClick={() => toggleOption(option)}
              className={'text-sm py-4 px-2 border-b-1 border-gray-300 flex items-center w-full text-left overflow-auto'}
      >
        <CheckBox checked={checked} />
        {children ?? facetRefinementToDisplayString(option)}
        {resultCount ? <span className={'ml-auto'}>{resultCount.toLocaleString()}</span> : null}
      </button>
    </li>
  );
};


const CheckBox: React.FC<{ checked: boolean }> = ({ checked }) => {
  return <div className={'flex border-1 border-gray-600 items-center justify-center h-6 w-6 mr-2'}>
    {checked ? <Icon title='tick' height={12} width={12} /> : null}
  </div>;
};

