import { isNotNullOrUndefined } from '@apollographql/apollo-tools';

export const zip = <A, B>(a: A[], b: B[]): [A,B][] => {
  if (a.length !== b.length) throw new Error('Cannot zip arrays of different lengths');
  return a.map((el, i) => ([el, b[i]]))
}

// sorry for the ts-ignores.
// types are all fine externally, but couldn't get it happy internally and needed to move on,
export const asArray = <T>(t: T): T extends any[] ? Exclude<Exclude<T, undefined[]>, null[]> : Exclude<Exclude<T, undefined>, null>[] => {
    if (Array.isArray(t)) { // @ts-ignore
      return t;
    }
    else if (!isNotNullOrUndefined(t)) { // @ts-ignore
      return [];
    }
    else { // @ts-ignore
      return [t];
    }
}