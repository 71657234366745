import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';

import styles from './styles.module.css';
import { PdpIconsStoryblok } from './types';

interface Props {
  blok: PdpIconsStoryblok;
}

const PdpIcons = ({ blok }: Props) => {
  const { icons } = blok || {};

  return (
    <section className={classNames(styles.container, 'Section Section--spaced')} {...storyblokEditable(blok)}>
      {icons?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </section>
  );
};

export default PdpIcons;
