import classNames from 'classnames';
import styles from './styles.module.css';

interface LoadingAnimationProps {
  className?: string;
  width?: string; //valid tailwind width
}

const LoadingAnimation = ({ className, width, ...props }: LoadingAnimationProps) => {
  return (
    <div className={classNames(className, width || 'w-40')} {...props}>
      <div className={styles['loader-inner']}>
        <div className={styles['loader-dot']}></div>
        <div className={styles['loader-t-vert']}></div>
        <div className={styles['loader-t-horz']}></div>
      </div>
    </div>
  );
};
export default LoadingAnimation;
