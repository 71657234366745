import React, { useContext } from 'react';
import { SessionContext } from '../../context/SessionContext';
import { useRouter } from 'next/compat/router';
import classNames from 'classnames';
import styles from '../storyblok/nested/Search/styles.module.css';
import Head from 'next/head';
import Link from 'next/link';
import { GenderTogglePaths } from './GenderToggle/types';
import { SearchBox } from '../searching/SearchBox';
import { ArrowRight } from './Icons/iconList';

type Props = {
  placeholder?: string;
  hide_gender_picker?: boolean;
  disable_responsive_styles?: boolean;
  forceToSearch?: boolean;
  designer_collection?: boolean;
}

export const SearchBar = ({ placeholder, hide_gender_picker, disable_responsive_styles, forceToSearch, designer_collection }: Props) => {
  const { isWomen: isWomenContext } = useContext(SessionContext);

  const router = useRouter();
  const isWomen = isWomenContext || router?.asPath === '/';

  return (
    <section
      className={classNames(styles.container, styles.bordered, 'Section', {
        [styles.enableResponsive]: !disable_responsive_styles,
      })}
    >
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Head>
      {!hide_gender_picker && (
        <>
          <Link
            className={classNames('display-18-caps border-none', styles.label)}
            href={isWomen ? GenderTogglePaths.WOMEN : GenderTogglePaths.MEN}
          >
            {designer_collection ? 'Designer' : (isWomen ? 'Women' : 'Men')}
          </Link>
          <div className={styles.toggle}>
            <Link
              href={isWomen ? GenderTogglePaths.MEN : GenderTogglePaths.WOMEN}
              className={classNames('body-14-bold border-b-2 border-brandYellow ', styles.toggleLink)}
            >
              Shop {isWomen ? 'men' : 'women'}
            </Link>
          </div>
        </>
      )}

      <section className={classNames(styles.searchContainer)}>
        <SearchBox placeholder={placeholder} forceToSearch={forceToSearch}/>
      </section>
      {designer_collection && <div className="w-full order-3 mb-4 md:mb-0">Not looking for designer?{' '} 
        <Link href={isWomen ? GenderTogglePaths.WOMEN : GenderTogglePaths.MEN} className="font-bold underline inline-block">
          <span className="flex whitespace-nowrap items-center gap-1">Return to home <ArrowRight /></span>
        </Link>
      </div>}
    </section>
  );
};