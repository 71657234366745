import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Department, ProductSearchState } from '../queries/types';
import styles from './styles.module.css';
import { useInfiniteSearch } from '../queries/useInfiniteSearch';
import { LoadingAnimation, Signpost } from '../../core';
import Hit from '../Hit';
import BackToTop from '../../core/BackToTop';
import { min } from 'ramda';
import { AlgoliaSearchResults } from '../queries/algoliaProductSearch';

export const InfiniteSearch: React.FC<{ searchState: ProductSearchState, initialData?: AlgoliaSearchResults }> = ({ searchState, initialData }) => {
  const search = useInfiniteSearch(searchState, initialData);
  const totalHits = search.data?.pages[0].pagination.total_hits ?? 0;
  const pageSize = searchState.pagination.pageSize;
  const lastSeenPage = search.data?.pages[search.data?.pages.length - 1].pagination.page ?? 0;
  const viewedHits = min((lastSeenPage + 1) * pageSize, totalHits);
  const hits = search.data?.pages.flatMap(page => page.hits) ?? [];
  const noResults = hits.length === 0 && !search.isLoading && !search.isPending;
  const hasResults = hits.length > 0;

  return (
    <div className={classNames(styles.container)}>
      {search.isLoading ? (
        <div className='m-auto h-60 flex flex-col justify-center items-center'>
          <LoadingAnimation width='w-16' />
        </div>
      ) : null }
      { hasResults ? (
        <div className={styles.count}>{totalHits.toLocaleString()} found</div>
      ) : null}
      {search.hasPreviousPage && (
        <button className={classNames('body-12-bold-caps mb-8', styles.button)}
                onClick={() => !search.isFetchingPreviousPage && search.fetchPreviousPage()}>
          {search.isFetchingPreviousPage ? "Loading..." : "Load previous"}
        </button>
      )}
      <ol className={styles.list}>
        {hits?.map((hit, index) => (
          <Hit
            key={hit.objectID}
            hit={hit}
            searchState={searchState}
          />
        ))}
      </ol>

      {search.hasNextPage && (
        <button className={classNames('body-12-bold-caps mt-8', styles.button)}
                onClick={() => !search.isFetchingNextPage && search.fetchNextPage()}>
          {search.isFetchingNextPage ? "Loading..." : "Load more"}
        </button>
      )}
      {hasResults && (
        <>
          <div className={styles.count}>
            You’ve viewed {viewedHits} of {totalHits}
          </div>
          <div className={classNames('fixed', 'bottom-20', 'md:bottom-4', 'right-3')}>
            <BackToTop text='' />
          </div>
        </>
      )}
      {
      (noResults) && (
        <div className="Section h-96 flex flex-col items-center justify-center">
          <h2 className="body-18 max-w-md mb-4 text-center">
            Oops, we couldn't find anything matching your search - want to browse all our pre-loved items?
          </h2>
          <Signpost href={searchState.department === Department.Mens ? '/men/all' : '/women/all'} signpostText="Browse all items" />
        </div>
      )}
    </div>
  );
};
