import classNames from 'classnames';
import React from 'react';
import { RichText } from '../../../core';

import { RichTextStoryblok } from './types';
import styles from './styles.module.css';

interface Props {
  blok: RichTextStoryblok;
}

export const RichTextSection = ({ blok }: Props) => {
  const { Content, Heading, remove_padding, width } = blok || {};

  const isNarrow = width === 'narrow';

  return (
    <section
      className={classNames(styles.container, 'Section', {
        ['PageContent']: !remove_padding,
        ['Section--spaced']: remove_padding,
        [styles.narrow]: isNarrow,
      })}
    >
      {Heading && <h2 className={classNames(styles.heading, 'display-24')}>{Heading}</h2>}
      <RichText document={Content} className={classNames('body-14  rich-text', { ['px-4']: !remove_padding })} />
    </section>
  );
};

export default RichTextSection;
