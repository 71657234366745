import React from 'react';
import { CartItem } from '../CartItem';
import styles from './styles.module.css';
import { CartLine } from '../../../data-fetching/cart/useCart';
import { giftcardVariantIDs, thriftBagVariantIDs } from '../../../utils/constants';
import { ThriftBagCartItem } from '../CartItem/ThriftBagCartItem';
import { GiftcardCartItem } from '../CartItem/GiftcardCartItem';

interface Props {
  items: CartLine[];
}

export const CartList: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles.list}>
      {items.map((item) => {
        const isThriftBag = thriftBagVariantIDs.includes(item.merchandise.id);
        const isGiftcard = giftcardVariantIDs.includes(item.merchandise.id);
        if (isThriftBag) {
          return <ThriftBagCartItem key={item.id} item={item} />;
        } else if (isGiftcard) {
          return <GiftcardCartItem key={item.id} item={item} />;
        } {
          return <CartItem key={item.id} item={item} />
        }
      })}
    </div>
  );
};
