import { useEffect, useState } from 'react';

const useTimeout = (delayInMs: number) => {
    const [isTimeUp, setIsTimeUp] = useState(false);

    useEffect(() => {
      const timeoutTimer = setTimeout(() => {
        setIsTimeUp(true);
      }, delayInMs);

    return () => {
      clearTimeout(timeoutTimer);
    };
  }, [delayInMs]);

  return isTimeUp;
};

export default useTimeout;