import { storyblokEditable } from '@storyblok/react';
import styles from './styles.module.css';
import { AsSeenInItemStoryblok } from './types';
import Image from '../../../core/Image';

interface Props {
  blok: AsSeenInItemStoryblok;
}

const AsSeenInItem = ({ blok }: Props) => {
  const { image } = blok || {};

  const imgSrc = image?.filename;

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="As seen in" />}
    </div>
  );
};

export default AsSeenInItem;
