/**
 * General file for all tracking events
 */

import { AlgoliaHit } from '../components/searching/Hit/hit.types';
import { ProductService } from '../modules/product.service';
import { Customer } from '../context/AccountContext';
import { CanonicalItem, isCanonicalItem } from '../data-fetching/canonicalItem';
import { algoliaInsights } from '../components/searching/insights';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];

    // @ts-ignore
    gtag(...args: any[]);
    _learnq: any;
  }
}

type DataLayerProduct = {
  item_id: string | number;
  item_name: string;
  // affiliation: "Google Merchandise Store",
  // coupon: "SUMMER_FUN",
  /**
   * Brand
   */
  item_brand?: string;
  /**
   * Category
   */
  price: number | string;
  index?: number;
  item_department?: string;
  item_category?: string;
  item_category2?: string;
  item_category3?: string;
  item_category4?: string;
  item_category5?: string;
  item_list_id?: string;
  item_list_name?: string;
  item_variant?: string;
  location_id?: string;
  discount?: number;
  quantity?: number;
};

export namespace Tracking {
  /**
   * GTM Helpers
   */
  export const convertCanonicalItemToAnalytics = (item: CanonicalItem): DataLayerProduct => ({
    item_id: item.id,
    price: item.price,
    item_name: item.title,
    item_brand: item.brand,
    item_department: item.department,
    item_category: item.category,
    item_category2: item.style,
    item_category3: item.brand_type,
    item_category4: item.condition,
    item_category5: item.size.display,
    quantity: 1,
  });

  export const convertProductToAnalytics = (product: ProductService.Single): DataLayerProduct => ({
    item_id: parseInt(`${product.id.split('/').pop()}`),
    price: product.variants[0]?.price?.amount,
    item_name: product.title,
    item_brand: product.tagsByCategory.brand || '',
    item_department: product.tagsByCategory.department,
    item_category: product.tagsByCategory.category,
    item_category2: product.tagsByCategory.style,
    item_category3: product.tagsByCategory.brand_type,
    item_category4: product.tagsByCategory.condition,
    item_category5: product.tagsByCategory.size,
    quantity: 1,
  });

  export const convertHitToAnalytics = (hit: AlgoliaHit): DataLayerProduct => ({
    item_id: hit.id,
    item_name: hit.title,
    item_brand: hit.named_tags.brand,
    item_department: hit.named_tags.department,
    item_category: hit.named_tags.category,
    item_category2: hit.named_tags.style,
    item_category3: hit.named_tags.brand_type,
    item_category4: hit.named_tags.condition,
    item_category5: hit.named_tags.size,
    price: hit.price,
    quantity: 1,
  });

  /**
   * Events pushed using this could have a variety of purposes.
   * Consent is kept up to date in tag manager, and should be used to validate tags triggered by this.
   */
  export const PushToGTM = (event: Record<string, any> & { event: string }) => {
    _ensureDataLayerExistence();
    window.dataLayer.push(event);
  }

  const _ensureDataLayerExistence = () => {
    window.dataLayer = window.dataLayer || [];
  };

  /**
   * Tracking Functionss
   */

  export const HitsViewed = () => {
    _ensureDataLayerExistence();

    // Send hits viewed event: https://www.algolia.com/doc/guides/sending-events/implementing/connectors/google-tag-manager/?client=React-InstantSearch
    window.dataLayer.push({ event: 'Hits Viewed' });
  };

  export const setAlgoliaUserToken = (userToken: string) => {
    _ensureDataLayerExistence();

    // Set the user token: https://www.algolia.com/doc/guides/sending-events/implementing/connectors/google-tag-manager/?client=React-InstantSearch
    window.dataLayer.push({
      algoliaUserToken: `${userToken}`,
    });
    algoliaInsights('setUserToken', userToken);
  };


  export const setAuthenticatedAlgoliaUserToken = (userToken: string | undefined) => {
    _ensureDataLayerExistence();

    algoliaInsights('setAuthenticatedUserToken', `${userToken}`);
  };

  export const ViewProductList = (list_id: string, list_name: string, products: DataLayerProduct[]) => {
    // view_item_list
    _ensureDataLayerExistence();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: list_id,
        item_list_name: list_name,
        items: products.map((itm, index) => ({
          ...itm,
          index: index,
          // item_list_id: list_id,
          // item_list_name: list_name,
        })),
      },
    });
  };

  export const SelectProductInList = (list_id: string, list_name: string, products: DataLayerProduct[]) => {
    _ensureDataLayerExistence();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: 'select_item',
      ecommerce: {
        item_list_id: list_id,
        item_list_name: list_name,
        items: products.map((itm, index) => ({
          ...itm,
          index: index,
          // item_list_id: list_id,
          // item_list_name: list_name,
        })),
      },
    });
    // select_item
  };

  export const ViewProduct = (product: ProductService.Single) => {
    _ensureDataLayerExistence();
    // GTM
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        currency: 'GBP',
        value: product.variants[0].price.amount,
        items: [convertProductToAnalytics(product)],
      },
    });

    // Klaviyo
    window._learnq = window._learnq || [];

    let item: Record<string, any> = {
      ProductName: product.title,
      ProductID: product.id.split('/').pop(),
      SKU: product.tagsByCategory.sku,
      Categories: [],
      ImageURL: product.images[0]?.src,
      URL: `https://thrift.plus/products/${product.handle}`,
      Brand: product.tagsByCategory.brand,
      Price: product.variants[0].price.amount,
    };

    if (product.variants[0].compareAtPrice) {
      item['CompareAtPrice'] = product.variants[0].compareAtPrice.amount;
    }
    window._learnq.push(['track', 'Viewed Product', item]);
  };

  export const AddToCart = (product: ProductService.Single | CanonicalItem) => {
    _ensureDataLayerExistence();
    const analyticsProduct = isCanonicalItem(product) ? convertCanonicalItemToAnalytics(product) : convertProductToAnalytics(product);
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'GBP',
        value: analyticsProduct.price,
        items: [analyticsProduct],
      },
    });
  };

  export const AddToWishlist = (product: DataLayerProduct) => { // should this be swym item as well
    _ensureDataLayerExistence();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'add_to_wishlist',
      ecommerce: {
        currency: 'GBP',
        value: product.price,
        items: [product],
      },
    });
  };

  export const ViewCart = (value: number, products: DataLayerProduct[]) => {
    _ensureDataLayerExistence();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'view_cart',
      ecommerce: {
        currency: 'GBP',
        value: value,
        items: products,
      },
    });
  };

  export const RemoveFromCart = (product: DataLayerProduct) => {
    _ensureDataLayerExistence();

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'remove_from_cart',
      ecommerce: {
        currency: 'GBP',
        value: product.price,
        items: [product],
      },
    });
  };

  export const initiateCheckout = (subtotal: string | number, products: DataLayerProduct[]) => {
    _ensureDataLayerExistence();

    return; // todo: remove this method once new tracking is verified as working
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.

    window.dataLayer.push({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'GBP',
        value: subtotal,
        items: products,
      },
    });
  };

  export const identifyAppNotificationToken = (fcmToken: string) => {
    window._learnq = window._learnq || [];
    window._learnq.push([
      'identify',
      {
        fcm_token: fcmToken,
      },
    ]);
  };

  export const identifyUser = (user: Customer) => {
    _ensureDataLayerExistence();

    // window.dataLayer.push({
    //   user_id: userId,
    // });
    window._learnq = window._learnq || [];

    window._learnq.push([
      'identify',
      {
        $email: user.email,
        $first_name: user.first_name,
        $last_name: user.last_name,
      },
    ]);

    window.dataLayer.push({
      event: 'identify',
      'user_id': user.shopify_customer_id,
      'user_email': user.email,
      'user_name': `${user.first_name} ${user.last_name}`,
    });
  };

  export const unidentifyUser = () => {
    window.dataLayer.push({
      event: 'unidentify',
      'user_id': undefined,
      'user_email': undefined,
      'user_name': undefined,
    });
  };

  export const MakeAnOffer = (item: CanonicalItem) => {
    _ensureDataLayerExistence();

    window.dataLayer.push({
      event: 'make_an_offer',
      ecommerce: {
        currency: 'GBP',
        value: item.price,
        items: [convertCanonicalItemToAnalytics(item)],
      },
    });
  };

}
