import React, { useMemo, useState } from 'react';
import { ProductSearchState, SearchableFacets } from '../queries/types';
import { isFacetOptionActive } from './utils';
import { RefinementToggle } from './RefinementToggle';
import Fuse from 'fuse.js';

type FacetOptions = Record<string, number>;
type Props = {
  facet: SearchableFacets,
  searchState: ProductSearchState,
  options: FacetOptions
}

export const RefinementTogglesForFacet: React.FC<Props> = ({ facet, options, searchState }) => {
  const sortedEnabledFirst = useFacetsSortedEnabledFirst({ facet, searchState, options })
  const [listFilter, setListFilter] = useState('');

  const fuse = useMemo(() => {
    return new Fuse(sortedEnabledFirst, {
      keys: ['0'],
      shouldSort: true,
      threshold: 0.2
    })
  }, [sortedEnabledFirst]);

  const filteredResults = listFilter.trim().length === 0 ? sortedEnabledFirst : fuse.search(listFilter).map(result => result.item);
  const showFilter = sortedEnabledFirst.length > 12;
  return (
    <>
      {
        showFilter ? (
          <div className={"flex"}>
            <input value={listFilter} onChange={e => setListFilter(e.target.value)} className={'border-1 border-gray-300 body-12 m-2 w-full p-2'} placeholder={"Search the list"} />
          </div>
        ): null
      }

      {filteredResults.map(([option, resultCount]) => {
        return <RefinementToggle key={option}
                                 option={option}
                                 searchState={searchState}
                                 resultCount={resultCount}
                                 facet={facet}
        />;
      })}
    </>
  );
};


// otherwise sorts in the order of most-to-least results
const useFacetsSortedEnabledFirst = ({options, searchState, facet}: Props) => {
  const optionEntries = Object.entries(options);

  return useMemo(() => {
    return optionEntries.sort((a, b) => {
      const aIsActive = isFacetOptionActive({ facet, searchState, option: a[0] }) ? 1 : 0;
      const bIsActive = isFacetOptionActive({ facet, searchState, option: b[0] }) ? 1 : 0;
      return bIsActive - aIsActive;
    });
  }, [facet]);
}