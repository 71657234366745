import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import React, { useState } from 'react';
import classNames from 'classnames';
import FilterButton from '../../../components/shopify/FilterButton/index';
import Custom404 from '../../sections/Custom404';
import { CollectionService } from '../../../modules/collection.service';
import { LoadingAnimation } from '../../core';
import { useSearchState } from '../../searching/queries/useSearchState';
import { SortResultsDropdown } from '../../searching/SortResults/SortResultsDropdown';
import { FacetSidebar } from '../../searching/Facets/FacetSidebar';
import { InfiniteSearch } from '../../searching/InfiniteSearch';
import Drawer from '../../core/Drawer';
import { useQuery } from '@tanstack/react-query';
import { PartnerCollectionStoryblok } from '../../../component-types-sb';

const useCollection = (collectionHandle?: string) => {
  return useQuery({
    queryKey: ['collections', collectionHandle],
    queryFn: (params) => {
      const handle = params.queryKey[1];
      if (!handle) return null;
      return CollectionService.getSingleWithItems(handle);
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 5, // 5 minutes
  })
}

const PartnerCollection: React.FC<{blok: PartnerCollectionStoryblok}> = ({ blok }) => {
  const { body, collection_id } = blok;
  const items = body || [];
  const collectionResponse = useCollection(collection_id);
  const collection = collectionResponse.data;

  const [searchState] = useSearchState();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleShowFilters = () => {
    return setDrawerOpen(true);
  };

  const handleHideFilters = () => {
    return setDrawerOpen(false);
  };


  if (!collection_id) {
    return <Custom404 subtitle="The collection you were looking for does not exist." />;
  }

  return (
    <main className={classNames('Container', { ['Page']: blok.grid_layout })} {...storyblokEditable(blok)}>
      {items.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      <div className="Container">
        {/* TODO: review SEO */}
        {collectionResponse.isLoading ?
        <div className='flex justify-center items-center'><LoadingAnimation /></div>
        : collection ?
        <section className="Section">
          <div className="text-center my-6">
            <h1 className="body-20-bold text-center capitalize">{collection_id.split('-').join(' ')}</h1>
            {/* {collection.s && <p className="body-14 mt-3 mx-auto max-w-xl">{collection.description}</p>} */}
          </div>

            <div className={classNames('Section', 'relative z-10 flex justify-center gap-4')}>
              {searchState ? <SortResultsDropdown searchState={searchState} /> : null}
              {searchState && <FilterButton onClick={handleShowFilters} searchState={searchState} /> }
            </div>
          <Drawer component={FacetSidebar} alignment="right" isOpen={drawerOpen} hideDrawer={handleHideFilters} />
          {searchState && <InfiniteSearch searchState={searchState} />}
        </section>
        :
        <Custom404 subtitle="The collection you were looking for does not exist." />
        }
      </div>
    </main>
  );
};

export default PartnerCollection;
