import classNames from 'classnames';
import { storyblokEditable } from '@storyblok/react';
import { useMediaQuery } from 'react-responsive';

import HeroStoryblok from './types';
import styles from './styles.module.css';
import ConditionalWrapper from '../../../util/ConditionalWrapper';
import { RichText } from '../../../core';
import SignpostLink from '../../../core/SignpostLink';
import { bgColorTextToValue } from '../../../../utils/constants';
import { useEffect, useState } from 'react';
import Link from 'next/link';

interface Props {
  blok: HeroStoryblok;
}

const Hero = ({ blok }: Props) => {
  const {
    image,
    image_large,
    title,
    subtitle,
    url,
    signpost_text,
    background_color,
    image_align,
    text_align,
    image_align_large,
    text_align_large,
    font_colour = "black",
  } = blok || {};

  const isLarge = useMediaQuery({
    query: '(min-width: 48rem)',
  });
  const [isLoaded, setIsLoaded] = useState(false);

  const imgSrc = image?.filename;
  const imgLargeSrc = image_large?.filename;
  // const src = imgLargeSrc;

  const cachedURL = url?.cached_url;

  useEffect(() => {
    // Hacky way to prevent ssr error
    setIsLoaded(true);
  }, []);

  const src = isLoaded && isLarge && imgLargeSrc ? imgLargeSrc : imgSrc;

  //TODO: switch image back to next image

  return (
    <section
      className={classNames(styles.container, 'Section', {
        [styles.imageRight]: image_align === 'right',
        [styles.imageCenter]: image_align === 'center',
        [styles.textRight]: text_align === 'right',
        [styles.textCenter]: text_align === 'center',
        [styles.imageRightLarge]: image_align_large === 'right',
        [styles.imageCenterLarge]: image_align_large === 'center',
        [styles.textRightLarge]: text_align_large === 'right',
        [styles.textCenterLarge]: text_align_large === 'center',
        [styles.imageLoading]: !isLoaded,
      })}
      style={{ backgroundColor: bgColorTextToValue[background_color] }}
      {...storyblokEditable(blok)}
    >
      <ConditionalWrapper
        condition={!!cachedURL}
        wrapper={(children) => {
          return (
            <Link className={styles.link} href={cachedURL}>
              {children}
            </Link>
          );
        }}
      >
        {src && <img key={src} className={classNames(styles.image)} src={src} alt="Hero image" />}

        <div className={styles.content}>
          {title && <RichText document={title} className={classNames(styles.title, `display-24-caps ${font_colour === "white" && 'text-white'}`)} />}
          {subtitle && <p className={`body-14 mb-4 ${font_colour === "white" && 'text-white'}`}>{subtitle}</p>}
          {signpost_text && <SignpostLink signpostText={signpost_text} />}
        </div>
      </ConditionalWrapper>
    </section>
  );
};

export default Hero;
