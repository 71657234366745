import classNames from 'classnames';
import dynamic from 'next/dynamic';

import styles from './styles.module.css';

const TrustBox = dynamic(() => import('../../../widgets/Trustpilot'), { ssr: false });

const TrustpilotWidget = () => {
  return (
    <section className={classNames(styles.container, 'Section')}>
      <TrustBox />
    </section>
  );
};

export default TrustpilotWidget;
