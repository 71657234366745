import React, { useContext, useEffect, useCallback, useState } from 'react';
import { AccountContext } from '../../../context/AccountContext';
import { AsyncToggle } from '../../core/AsyncToggle';
import { Transition } from '@headlessui/react';

interface MarketingPreferencesProps {
  text: React.ReactNode
}

export const MarketingPreferences: React.FC<MarketingPreferencesProps> = ({text}) => {
  const { updateShopifyCustomer, customerDetails, isCustomerLoading } = useContext(AccountContext);

    const setCustomerMarketingPrefs = useCallback(async (acceptsMarketing: boolean) => {
      try {
        await updateShopifyCustomer({acceptsMarketing});
      } catch (error) {
        console.error('Error updating customer preferences:', error);
      }
    }, [updateShopifyCustomer]);

  return (
    <div className="relative body-14">
      <label className="flex items-center">
        <AsyncToggle
          a11yLabel='Opt in to marketing content'
          checked={customerDetails.accepts_marketing}
          onToggle={(checked) => {
            return setCustomerMarketingPrefs(checked);
          }}
          forceLoading={isCustomerLoading}
        />
        <div className="ml-2">{text}</div>
      </label>
    </div>
  )
}

export const MarketingOptInPrompt: React.FC = () => {
  const { customerDetails } = useContext(AccountContext);
  const [showPrompt, setShowPrompt] = useState<boolean>(!customerDetails.accepts_marketing);

  useEffect(() => {
    if (customerDetails.accepts_marketing) {

      const hideConfirmationAfter5Ms = setTimeout(() => {
        setShowPrompt(false);
      }, 5000);

      return () => clearTimeout(hideConfirmationAfter5Ms);
    }
  }, [customerDetails.accepts_marketing]);

  return (
  <Transition
    show={showPrompt}
    enter="transition-opacity duration-200 delay-75"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-400"
    leaveFrom="opacity-100"
    leaveTo="opacity-0 z-2"
  >
    {showPrompt && (
      <div className="relative bg-brandYellow25 py-3 px-4 mb-2 mx-2 mt-2 body-14">
        <MarketingPreferences
          text={
            customerDetails.accepts_marketing ? (
              <span>
                <strong>You&apos;re in.</strong> Opt out at any time in your account settings.
              </span>
            ) : (
              <span>
                Count me in for <strong>price drop alerts</strong>, exclusive deals and more.
              </span>
            )
          }
        />
      </div>
    )}
  </Transition>
);
};