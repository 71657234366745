import React from 'react';
import { CategoryType } from './types';
import styles from './styles.module.css';
import Image from '../Image';
import classNames from 'classnames';
import Link from 'next/link';

// variable with the title of the category and image src
const dummyCategories = [
  {
    title: 'COS',
    src: 'https://a.storyblok.com/f/177537/105x56/184e73962e/image-62.png',
  },
  {
    title: 'Zara',
    src: 'https://a.storyblok.com/f/177537/106x58/70954d560c/image-61.png',
  },
  {
    title: 'AndOtherStories',
    src: 'https://a.storyblok.com/f/177537/127x67/f878c7f743/image-60.png',
  },
  {
    title: 'Hush',
    src: 'https://a.storyblok.com/f/177537/95x53/3fcb952750/image-63.png',
  },
  {
    title: 'ASOS',
    src: 'https://a.storyblok.com/f/177537/104x59/21ee05e2d4/image-64.png',
  },
  {
    title: 'FrenchConnection',
    src: 'https://a.storyblok.com/f/177537/131x57/73f0b7fd45/image-65.png',
  },
  {
    title: 'Jigsaw',
    src: 'https://a.storyblok.com/f/177537/119x69/400500160d/image-66.png',
  },
  {
    title: 'Boden',
    src: 'https://a.storyblok.com/f/177537/87x49/db538b4ba2/image-67.png',
  },
  {
    title: 'Nike',
    src: 'https://a.storyblok.com/f/177537/106x51/99651d10b0/image-68.png',
  },
];

const BrandBlock = ({ title }: CategoryType) => {
  return (
    <section className={classNames(styles.container, 'Section')}>
      <h2 className={classNames(styles.title, 'body-18-bold')}>{title}</h2>
      <div className={styles.gridBlock}>
        {dummyCategories.map((category, index) => (
          <Link key={index} className={styles.Brand} href={`/brands/${category.title}`}>
            <Image alt={category.title} title={category.title} src={category.src} />
          </Link>
        ))}
      </div>
    </section>
  );
};

export default BrandBlock;
