import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import { RichText, Image, Signpost } from '../../../core';
import SignpostLink from '../../../core/SignpostLink';
import styles from './styles.module.css';
import IntroStoryblok from './types';
import { bgColorTextToValue } from '../../../../utils/constants';
import { useMediaQuery } from 'react-responsive';

interface Props {
  blok: IntroStoryblok;
  grid: boolean;
}

const Intro = ({ blok, grid }: Props) => {
  const { image, image_large, title, subtitle, background_color, signpost_text, url, signpost_type } = blok || {};

  const isLarge = useMediaQuery({
    query: '(min-width: 48rem)',
  });

  const imgSrc = image?.filename;
  const imgLargeSrc = image_large?.filename;
  const src = isLarge && imgLargeSrc ? imgLargeSrc : imgSrc;

  const cachedURL = url?.cached_url;

  const SignpostComponent = signpost_type === 'link' ? SignpostLink : Signpost;

  return (
    <section
      className={classNames(styles.container, 'Section', 'Section--spaced', {
        [styles.bordered]: !background_color || background_color === 'transparent',
        [styles.grid]: grid,
      })}
      style={{ backgroundColor: bgColorTextToValue[background_color] }}
      {...storyblokEditable(blok)}
    >
      {src && (
        <div className={styles.imageContainer}>
          <Image className={styles.image} src={src} alt="Intro image" />
        </div>
      )}
      <div className={styles.textContainer}>
        {title && <RichText document={title} className={classNames(styles.title, 'display-20-caps')} />}
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        {signpost_text && <SignpostComponent signpostText={signpost_text} href={cachedURL} />}
      </div>
    </section>
  );
};

export default Intro;
