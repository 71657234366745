import React, { FC } from 'react';

import { DrawerConsumer } from '../DrawerContext';
import Drawer from '../../components/core/Drawer';

const DrawerRoot: FC = () => {
  return (
    <DrawerConsumer>
      {(props) => {
        const drawerProps = { ...props, ...props.props };
        return <Drawer {...drawerProps} />;
      }}
    </DrawerConsumer>
  );
};

export default DrawerRoot;
