import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { BenefitsStripStoryblok } from './types';

interface Props {
  blok: BenefitsStripStoryblok;
}

const BenefitsStrip = ({ blok }: Props) => {
  const { items } = blok || {};

  return (
    <section className={classNames(styles.container, 'Section')} {...storyblokEditable(blok)}>
      {items.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </section>
  );
};

export default BenefitsStrip;
