import React, { useState, useEffect } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

import { Icon, Image, RichText } from '../../../core';

import styles from './styles.module.css';
import PartnerHeroStoryblok from './types';
import SignpostLink from '../../../core/SignpostLink/index';
import classNames from 'classnames';
import { bgColorTextToValue } from '../../../../utils/constants';
import Modal from 'react-modal';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';

interface Props {
  blok: PartnerHeroStoryblok;
}

Modal.setAppElement('body');

const PartnerHero = ({ blok }: Props) => {
  const { background_image, remove_bg_image_overlay, foreground_image, logo, text } = blok || {};

  const bgImgSrc = background_image?.filename;
  const fgImgSrc = foreground_image?.filename;
  const logoSrc = logo?.filename;

  return (
    <section className={styles.container} {...storyblokEditable(blok)} style={{ backgroundImage: `url(${bgImgSrc})` }}>
      <div className={remove_bg_image_overlay ? styles.contentBgNoOverlay : styles.contentBg} style={{ backgroundImage: `url(${fgImgSrc})` }}>
        <div className={styles.content}>
          {logoSrc && <Image src={logoSrc} alt="" className={styles.logo} />}
          <RichText className={classNames(styles.text)} document={text} />
        </div>
      </div>
    </section>
  );
};

export default PartnerHero;
