import React from 'react';
import { ProductMini } from '../../../helpers/recentlyviewed.utility';

import styles from './styles.module.css';
import { IndependentSimpleItemCard } from '../../shopify/SimpleItemCard';

export interface Props {
  grid?: number;
  items: ProductMini[];
  type: 'recently-viewed' | 'recommended';
}

export const ProductList: React.FC<Props> = ({ grid, items, type }: Props) => {
  const gridClass = grid === 2 ? styles.grid2 : styles.grid3;

  return (
    <div className="Container">
      <div className={gridClass}>
        {items.map((product) => {
          return <IndependentSimpleItemCard key={product.handle} handle={product.handle} />;
        })}
      </div>
    </div>
  );
};
