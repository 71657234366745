import styles from './styles.module.css';
import classNames from 'classnames';
import { HeaderProps } from './types';
import Icon from '../../../../core/Icons';

const DrawerHeader = ({ title, subtitle, onBack }: HeaderProps) => {
  const titleClass = 'body-16-bold flex items-center capitalize';
  const subTitle = 'body-14 border-none';

  return (
    <section className={classNames(styles.container, styles.bordered)}>
      <h2 className={classNames(titleClass)}>
        {onBack && (
          <button onClick={onBack} className="mr-2">
            <Icon title="return" />
          </button>
        )}
        <span>{title}</span>
      </h2>

      <h2 className={classNames(subTitle)}>{subtitle}</h2>
    </section>
  );
};

export default DrawerHeader;
