import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

const CollectionPageAdditionalContentStoryblok = ({ blok }) => {

  return (
    <>
    <StoryblokComponent blok={blok.category_slider[0]} key={blok.category_slider[0]._uid} />
    </>
  );
};

export default CollectionPageAdditionalContentStoryblok;