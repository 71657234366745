import React, { PropsWithChildren, useContext, useState } from 'react';
import { Signpost } from '../../core';
import { Register } from './Register';
import { Login } from './Login';
import { AccountContext } from '../../../context/AccountContext';

type Props = {
  initialState?: 'login' | 'register';
  userContext?: string;
}

export const LoginOrRegister: React.FC<PropsWithChildren<Props>> = ({ initialState, userContext, children }) => {
  const [formToShow, setFormToShow] = useState<'login' | 'register'>(initialState ?? 'register');
  const {isLoggedInDynamic} = useContext(AccountContext);
  if (isLoggedInDynamic && children) return <>{children}</>;

  return (
    <div className="w-full h-full relative p-3 flex flex-col justify-center items-center gap-1 bg-yellow-50">
      {formToShow === 'register' ? (
        <>
          <h1 className={'body-20-bold'}>Register to continue</h1>
          { userContext ? <p>{userContext}</p> : null }
          <div className='w-full max-w-sm'>
            <Register checkboxText={'Tick for price drop emails, exclusive deals and more.'} redirect={null} />
          </div>

          <div className='flex items-center flex-col justify-center w-full'>
            <div className='flex flex-row items-center justify-center w-full'>
              <div className='border-b-2 border-solid border-grey-700 w-1/6'></div>
              <p className='p-2'>or</p>
              <div className='border-b-2 border-solid border-grey-700 w-1/6'></div>
            </div>
            <Signpost signpostText='Login' onClick={() => setFormToShow('login')} />
          </div>
        </>
      ) : (
        <>
          <h1 className={'body-20-bold'}>Login to continue</h1>
          { userContext ? <p>{userContext}</p> : null }
          <div className="w-full max-w-sm">
            <Login />
          </div>
          <div className="flex items-center flex-col justify-center w-full">
            <div className='flex flex-row items-center justify-center w-full'>
              <div className='border-b-2 border-solid border-grey-700 w-1/6'></div>
              <p className=' p-2'>or</p>
              <div className='border-b-2 border-solid border-grey-700 w-1/6'></div>
            </div>
            <Signpost signpostText='Register' onClick={() => setFormToShow('register')} />
          </div>
        </>
      )}
    </div>
  );
}