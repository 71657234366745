import React, { SyntheticEvent, useCallback, useContext, useEffect, useState } from 'react';
import { SessionContext } from '../../../context/SessionContext';
import { useSearchState } from '../queries/useSearchState';
import { Department } from '../queries/types';
import styles from './styles.module.css'
import { useSearchSuggestions } from '../queries/useSearchSuggestions';
import { CloseDefault, Search } from '../../core/Icons/iconList';
import { useLocalStorage } from 'react-use';
import { uniq } from 'lodash';
import useTimeout from '../../../helpers/useTimeout';
import { useSearchUpdater } from '../queries/useSearchUpdater';

type Props = {
  placeholder?: string;
  forceToSearch?: boolean;
}

export const useSearchHistory = (maxResults?: number) => {
  const { isWomen } = useContext(SessionContext);
  const department = isWomen ? Department.Womens : Department.Mens;
  const [searchHistory, setSearchHistory] = useLocalStorage<string[]>(`recent_searches:${department}`, []);

  const addEntry = useCallback((search: string) => {
    setSearchHistory([search, ...(searchHistory ?? [])])
  }, [searchHistory])

  const filteredHistory = uniq(searchHistory).filter(search => search.trim().length > 0).slice(0,maxResults)
  return [filteredHistory, addEntry] as [string[]|undefined, (search: string) => void];
}

export const SearchBox: React.FC<Props> = ({ placeholder, forceToSearch }) => {
  const [searchState] = useSearchState();
  const [searchText, setSearchText] = useState(searchState?.query ?? '');
  const [applySearch, setApplySearch] = useState(false);
  const [debouncedSearchText, setDebouncedSearchText] = useState('');
  const [_searchHistory, appendSearch] = useSearchHistory(3);
  const suggestionResult = useSearchSuggestions({ query: debouncedSearchText });
  const { isWomen } = useContext(SessionContext);
  const updateSearch = useSearchUpdater({ forceToSearch });
  
  const applySearchSuggestion = (searchText: string) => {
    setSearchText(searchText);
    setApplySearch(true);
  }

  const search = async () => {
    appendSearch(searchText);

    await updateSearch({ query: searchText, department: isWomen? Department.Womens : Department.Mens });
  }
  const handleFormSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    search();
  }

  const clearFilter = () => {
    setSearchText('');
    if (searchState?.query) {
      updateSearch({ query: undefined, department: isWomen? Department.Womens : Department.Mens })
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => setDebouncedSearchText(searchText), 250);
    return () => clearTimeout(timeout);
  }, [searchText]);

  useEffect(() => {
    if (searchState?.query){
      setSearchText(searchState?.query);
    }
  }, [searchState?.query])

  useEffect(() => {
    if (!applySearch) return
    try {
      // this just tries to unfocus the search box
      // @ts-ignore
      document.activeElement?.blur()
    } catch(err) {}
    setApplySearch(false);
    search();
  }, [applySearch]);

  return (
    <div className={styles.container} role='combobox' aria-expanded='false' aria-haspopup='listbox'
         aria-labelledby='autocomplete-1-label'>
      <form className={styles.form} action='' noValidate={true} role='search' onSubmit={handleFormSubmit}>
        <div className={styles.inputWrapper}>
          <input className={styles.input} aria-autocomplete='both'
                 aria-labelledby='autocomplete-1-label'
                 id='autocomplete-1-input' autoComplete='off'
                 autoCorrect='off' autoCapitalize='off'
                 enterKeyHint='search' spellCheck='false'
                 placeholder={placeholder ?? `Shop ${isWomen ? 'women' : 'men'}'s fashion`} maxLength={512}
                 type='search'
                 onChange={e => setSearchText(e.target.value)}
                 value={searchText}
          />
          <button className={styles.clear} type='reset' title='Clear'
                  onClick={clearFilter}>
            <CloseDefault height={24} width={24} color='transparent' />
          </button>
          <div className={`${styles.suggestionWrapper}`}>
            {
              suggestionResult.data?.map(({ text, hits }) => {
                return <SearchSuggestion key={text} search={debouncedSearchText} suggestion={text}
                                         resultsCount={hits} applySearchSuggestion={applySearchSuggestion} />;
              })
            }
            {
              (!debouncedSearchText && !searchText && !suggestionResult.data) ? (
                <RecentSearchesDropdown applySearchSuggestion={applySearchSuggestion} />
              ) : null
            }
          </div>
        </div>
        <button className={styles.search} type='submit' title='Submit'>
          <Search height={26} width={26} color='transparent' />
        </button>
      </form>
    </div>
  );
}

const RecentSearchesDropdown: React.FC<{applySearchSuggestion: (query: string) => void}> = ({applySearchSuggestion}) => {
  const [searchHistory] = useSearchHistory(3);

  // have to do this to prevent hydration errors unfortunately, because localstorage is not available for server-side renders
  const readyToRender = useTimeout(100);
  if (!readyToRender) return null;

  if (searchHistory?.length === 0) return null;
  return <>
    <div className={"mx-3 mt-3 mb-1 body-14 text-gray-400"}>Recent searches</div>
    { searchHistory && searchHistory.map(search => {
      return <SearchSuggestion applySearchSuggestion={applySearchSuggestion} key={search} search={''} suggestion={search} />;
    })}
  </>

}

const SearchSuggestion: React.FC<{ search: string, suggestion: string, resultsCount?: number, applySearchSuggestion: (query: string) => void }> = ({ search, suggestion, resultsCount, applySearchSuggestion }) => {
  const { isWomen } = useContext(SessionContext);
  // used to bold the search word in the text
  const splitIndex = suggestion.toLowerCase().indexOf(search.toLowerCase().trim());
  const before = suggestion.slice(0, splitIndex);
  const after = suggestion.slice(splitIndex + search.length);

  // i know mousedown is a strange choice, but is necessary otherwise it doesn't work on safari becuase the button gets hidden before the handler can fire
  return (
    <button
      className={styles.suggestion}
      tabIndex={0}
      type={"button"}
      onMouseDown={async (e) => {
        applySearchSuggestion(suggestion);
      }}
      onClick={async (e) => {
        applySearchSuggestion(suggestion);
      }}
    >
      {splitIndex >= 0 ?
        <span>{before}<strong>{search}</strong>{after}</span>:
        <span>{suggestion}</span>
      }
      {typeof resultsCount !== 'undefined' ? <div>{resultsCount}</div> : null}
    </button>
  )
}