import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { StoryblokComponent } from '@storyblok/react';
import { SessionContext } from '../../../../../context/SessionContext';
import FadeIn from '../../../../transitions/FadeIn';

const Shop = ({ config }) => {
  const { gender } = useContext(SessionContext);

  const genderDrawerKeys = {
    women: 'shop_drawer',
    men: 'shop_drawer_mens',
  };

  const items = config.content[genderDrawerKeys[gender]];
  const links = config.content.shop_drawer_shared;
  return (
    <FadeIn>
      {items?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} config={config} />
      ))}
      <div className={classNames(styles.sharedContainer, 'Section--spaced')}>
        {links?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </FadeIn>
  );
};

export default Shop;
