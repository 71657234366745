import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import Signpost from '../../../core/Signpost';
import styles from './styles.module.css';
import { CtaStoryblok } from './types';
import classNames from 'classnames';
import { Button } from '../../../core';
import SignpostLink from '../../../core/SignpostLink/index';

interface Props {
  blok: CtaStoryblok;
}

const CTA = ({ blok }: Props) => {
  const { title, subtitle, url, cta_text } = blok || {};
  const cachedURL = url?.cached_url;
  return (
    <section className={classNames('Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      {title && <h2 className={classNames(styles.title, 'display-18-caps')}>{title}</h2>}
      {subtitle && <p className="body-14 mb-6">{subtitle}</p>}
      <div className={styles.content}>{cta_text && <SignpostLink signpostText={cta_text} href={cachedURL} />}</div>
    </section>
  );
};

export default CTA;
