import { isUndefined } from 'lodash';
import { capitalizeFirstLetter } from '../utils/helpers';

export type CanonicalItem = {
  id: number;
  handle: string;
  title: string;
  display_title: string;
  photos: string[];
  department: string;
  brand?: string;
  brand_type: string;
  colour: string;
  condition: string;
  style: string;
  category: string;
  collections: string[];
  size: {
    display: string;
    uk?: number;
    sml?: 'XS' | 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL',
    dimensions?: {
      height: number,
      width: number,
      length: number,
    },
    uk_shoe?: number,
    eu_shoe?: number,
    chest?: number,
    collar?: number,
    waist?: number,
    leg?: number,
  },
  seller: string;
  materials: string[];
  original_tags: boolean;
  total_inventory: number;
  in_stock: boolean;
  // ISO date string
  listed_at: string | null;
  defects: string[];
  sku: string;
  strikethrough_price?: number;
  variant_id: number;
  price: number;
  pattern: string;
  neckline?: string,
  sleeve_length?: string,
  dress_length?: string,
  leg_style?: string,
  _original: any;
}
export const isCanonicalItem = (product: any): product is CanonicalItem => {
  if (!product) return false;
  // seems like this key is unique to this type, so a decent check
  const hasDisplayTitleKey = typeof (product as any)['display_title'] !== 'undefined';
  return hasDisplayTitleKey;
}

export const generateSizeDisplayString = (item: CanonicalItem) => {
  if (item.size.dimensions) {
    return `${item.size.dimensions.width}cm x ${item.size.dimensions.height}cm x ${item.size.dimensions.length}cm`;
  }
  const normalisedSizeComponents = Object.entries(item.size)
    .map(([key, value]) => ({ key, value })) // convert array to object for convenience
    .filter(({
               key,
               value,
             }) => !['display', 'dimensions'].includes(key)) // remove keys we don't want to include in the summary
    .map(({
            key,
            value,
          }) => `${capitalizeFirstLetter(key.replace('_', ' '))} ${value}`) // prefix sizes with the dimension
    .map(sizePart => sizePart.replace(/sml/i, '')) // remove SML
    .map(sizePart => sizePart.replace(/shoe/i, '')) // remove the word shoe, should be obvious to users it's a shoe size
    .map(sizePart => sizePart.replace(/uk/i, 'UK')) // uppercase UK
    .map(sizePart => sizePart.replace(/eu/i, 'EU')) // uppercase EU
    .map(sizePart => sizePart.trim()) // remove any weird white space

  if (normalisedSizeComponents.length === 0) return '';
  return `Size ${normalisedSizeComponents.join(', ')}`;
};

const isNotUndefined = <T>(anything: T | undefined): anything is T => {
  return typeof anything !== 'undefined';
}
export const generateTitleDisplayString = (item: CanonicalItem): string => {
  const { brand_type, brand, style, materials } = item;

  const allowedMaterials = [
    'angora',
    'cashmere',
    'cotton',
    'leather',
    'linen',
    'mohair',
    'silk',
    'suede',
    'wool',
  ];

  const displayMaterial = materials
    .find(material => allowedMaterials.includes(material.toLowerCase()));
  const materialPrefix = displayMaterial && materials.length === 1 ? 'Pure' : undefined;
  const materialSuffix = displayMaterial && materials.length > 1 ? 'Blend' : undefined;
  const titleComponents = [
    brand_type === 'vintage' ? brand_type : '',
    brand,
    materialPrefix,
    displayMaterial,
    materialSuffix,
    style,
  ].filter(isNotUndefined);

  return titleComponents
    .filter(titleComponent => titleComponent?.trim().length !== 0) // remove any empty segments
    .map(titleComponent => capitalizeFirstLetter(titleComponent))
    .join(' ');
};