import { ImageLoader } from 'next/image';

/**
 * Render the paths for Next Image
 */
const customImageLoader: ImageLoader = ({ src, width, quality, ...args }) => {
  if (src.indexOf('cdn.shopify.com') > -1) {
    // Shopify url
    if (!src.includes('_')) {
      return src.replace(/(\.jpg|\.png|\.jpeg)/, `_${width}x$1`); // Add _widthx in front of format
    } else {
      //if already includes _, then we can assume the size has already been added
      return src;
    }
  }

  if (src.indexOf('storyblok.com') > -1) {
    // Docs: https://www.storyblok.com/docs/image-service
    return `${src}/m/${width}x0`;
  }
  // Don't usr vercel optimization
  return src;

  // return `https://example.com/${src}?w=${width}&q=${quality || 75}`;
}

export default customImageLoader;