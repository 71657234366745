import styles from './styles.module.css';
import { SignpostCore } from './types';
import classNames from 'classnames';

import Icon from '../Icons/index';
import Link from 'next/link';

const chooseSignpostLinkComponent = (href?: string) => href ? (href.startsWith('http') ? 'a' : Link) : 'button';

const Signpost = ({ signpostText, href, target, onClick, iconLeft, iconRight = 'arrowRight' }: SignpostCore) => {

  const Element = chooseSignpostLinkComponent(href);
  return (
    <Element
      className={styles.container}
      target={target}
      {...(href && { href })}
      {...(onClick && !href && { onClick })}
    >
      {iconLeft && <Icon className={classNames(styles.icon, styles.iconLeft)} title={iconLeft} />}
      <h5 className={classNames(styles.text, 'body-16-bold')}>{signpostText}</h5>
      {iconRight && <Icon className={styles.icon} title={iconRight} />}
    </Element>
  );
};

export default Signpost;
