// import * as Sentry from "@sentry/browser";
import { fetchPostJSON } from './api-helpers';

export const addSubscriber = async (email: string, extraData?: any) => {
  const data = {
    email: email,
    ...(extraData || {}),
  };
  
  return await fetchPostJSON('/api/add-subscriber', data);
};
