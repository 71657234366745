import React, { useContext, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { RegisterAccountPartnerStepStoryblok } from '../../../../component-types-sb';
import { Register } from '../../../account/Authentication/Register';
import { Login } from '../../../account/Authentication/Login';
import { Signpost } from '../../../core';
import { RichText } from '../../../core';
import styles from './styles.module.css';

interface Props {
  blok: RegisterAccountPartnerStepStoryblok;
}

const RegisterAccountPartnerStep = ({ blok }: Props) => {
  const { content } = blok || {};
  const [formToShow, setFormToShow] = useState<'login' | 'register'>('register');

  return (
    <div className=" w-full h-full relative p-10 flex flex-col items-center gap-2" {...storyblokEditable(blok)}>
      {formToShow === 'register' ? (
        <>
          {content && <RichText className={styles.content} document={content} />}
          <div className="w-full max-w-sm">
            <Register checkboxText={'Tick for price drop emails, exclusive deals and more.'} redirect={null} />
          </div>

          <div className="flex items-center flex-col justify-center">
            <div className="flex flex-row items-center justify-center w-full">
              <p className="bg-white z-10 p-2">or</p>
              <div className="absolute border-b-2 border-solid border-grey-700 w-2/6"></div>
            </div>
            <Signpost signpostText="Login" onClick={() => setFormToShow('login')} />
          </div>
        </>
      ) : (
        <>
          <h1>Login to continue</h1>
          <div className="w-full max-w-sm">
            <Login />
          </div>
          <div className="flex items-center flex-col justify-center">
            <div className="flex flex-row items-center justify-center w-full">
              <p className="bg-white z-10 p-2">or</p>
              <div className="absolute border-b-2 border-solid border-grey-700 w-2/6"></div>
            </div>
            <Signpost signpostText="Register" onClick={() => setFormToShow('register')} />
          </div>
        </>
      )}
    </div>
  );
};

export default RegisterAccountPartnerStep;
