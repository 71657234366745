import { RichText, Image } from '../';
import Link from 'next/link';
import { formatDate } from '../../../utils/formatting';

const BlogCard = ({ item }: any) => {
  const { name, Opener, image, Published } = item.content;
  const PublishedDate = Published ? formatDate(Published) : null;

  return (
    <Link href={`/blog/${item.slug}`} className="border">
      <Image
        className="w-full h-1/2 md:h-[30%] object-cover"
        src={image?.filename}
        alt={image?.alt}
        width={300}
        height={300}
      />
      <div className="block mt-4 px-4 py-3">
        <div className="flex flex-col space-y-2 body-14">
          <time dateTime={PublishedDate}>{PublishedDate}</time>
          <p className="body-16-bold underline">{name}</p>
          <RichText document={Opener} className={'body-14 space-y-6'} />
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
