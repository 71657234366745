import { ArrowHeadLeft, ArrowHeadRight } from '../../../../../core/Icons/iconList';
import React from 'react';

type Props = {
    currentPage: number,
    totalPages: number,
    loading: boolean,
    onPageChange: (page: number) => void
};

export const PaginationBar: React.FC<Props> = ({ currentPage, totalPages, loading, onPageChange }) => {
    const hasPrevPage = currentPage > 1;
    const hasNextPage = currentPage < totalPages;

    const prevPage = hasPrevPage ? currentPage - 1 : currentPage;
    const nextPage = hasNextPage ? currentPage + 1 : currentPage;

    const enabledClasses = "py-4 cursor-pointer";
    const disabledClasses = "py-4 pointer-events-none text-gray-400";

    return (
        <div className={'flex flex-col'}>
            <div className="flex mx-auto items-center">
                <button
                    onClick={() => onPageChange(prevPage)}
                    className={hasPrevPage ? enabledClasses : disabledClasses}
                    disabled={!hasPrevPage}
                >
                    <ArrowHeadLeft color={!hasPrevPage ? "#A8A8A8" : ''} />
                </button>
                <div className="bg-brandGrey50 rounded-full h-8 w-8 flex items-center justify-center text-black font-bold">
                    {loading ? '...' : currentPage}
                </div>
                <button
                    onClick={() => onPageChange(nextPage)}
                    className={hasNextPage ? enabledClasses : disabledClasses}
                    disabled={!hasNextPage}
                >
                    <ArrowHeadRight color={!hasNextPage ? "#A8A8A8" : ''} />
                </button>
            </div>
            <small className={'mx-auto'}>{currentPage} of {totalPages}</small>
        </div>
    );
}