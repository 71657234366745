import React, { useContext } from 'react';
import { Button } from '../../../../../core';
import { WishlistContext } from '../../../../../../context/WishlistContext';
import { CanonicalItem } from '../../../../../../data-fetching/canonicalItem';

export const RemoveFromWishlistButton: React.FC<{item:CanonicalItem}> = ({item}) => {
    const { toggleItemInWishlist } = useContext(WishlistContext);

    return (
        <Button
          className="w-1/2"
          onClick={() => {
            toggleItemInWishlist(item);
          }}
          iconLeft="heartWishRemove"
          text="remove"
          isSecondary
        />
    )
}