import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { StatHighlightListStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: StatHighlightListStoryblok;
}

const StatHighlightList = ({ blok }: Props) => {
  const { title, subtitle, items } = blok || {};

  return (
    <section className={classNames('Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      {title && <h2 className={classNames(styles.title, 'display-18-caps')}>{title}</h2>}
      {subtitle && <p className="body-14">{subtitle}</p>}
      <div className={styles.items}>
        {items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default StatHighlightList;
