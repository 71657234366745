import React, { useState } from 'react';
import Link from 'next/link';
import { thriftBagVariantID } from '../../../utils/constants';
import { Button, Image } from '../../core';
import styles from './styles.module.css';

type Props = {
  addItem: (() => Promise<void>)
}

const ThriftBagUpsell: React.FC<Props> = ({ addItem }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleAddToCart = async () => {
    setIsLoading(true);
    await addItem();
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <Image src={'/thrift_bag.jpg'} alt="thrift bag" className={styles.image} />
      </div>
      <div className={styles.textContainer}>
        <p className="body-14">
          Ready for a clearout? Add a Thrift+ Bag to your order.{' '}
          <Link href="/clearout" className="underline">
            Learn more.
          </Link>
        </p>
        <Button
          isLoading={isLoading}
          loadingText={'Adding'}
          className={styles.button}
          text={'Add to bag'}
          onClick={async (e: any) => {
            e.preventDefault();
            handleAddToCart();
          }}
        />
      </div>
    </div>
  );
};

export default ThriftBagUpsell;
