import { normaliseVariantID } from './useCartLine';
import { CartLineInput, ShopifyService } from '../../modules/shopify.service';

// returns the checkout URL for the cart
export const buyNow = async (buyNow: { customerAccessToken?: string, lineItems: {merchandiseId: string | number, quantity: number}[] }): Promise<string | null> => {
  const normalisedLineItems: CartLineInput[] = buyNow.lineItems
    .map(lineItem => {
      return {
        merchandiseId: normaliseVariantID(`${lineItem.merchandiseId}`),
        quantity: lineItem.quantity,
      }
    })
  if (normalisedLineItems.length === 0) return null;
  const cart = await ShopifyService.instantCart({ lines: normalisedLineItems, customerAccessToken: buyNow.customerAccessToken });
  return cart.cartCreate?.cart?.checkoutUrl ?? null;
}
