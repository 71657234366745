import { Brand } from '../../types/brands';
import { fetchGetJSON } from '../api-helpers';
const THRIFT_BASE_URL = process.env.NEXT_PUBLIC_THRIFT_BASE_API;

export const checkBrand = async (brand: string): Promise<Brand[] | undefined> => {
  try {
    const response: Brand[] = await fetchGetJSON(`${THRIFT_BASE_URL}/public/v1/brands?search=${encodeURIComponent(brand)}&limit=5`);
    
    // we don't want vintage or b2b supply brands to show in the brand checker to c2b customers
    // we always want to return 3 results
    const firstSixBrands = response.slice(0, 6);
    const filteredResults = firstSixBrands?.filter((res) => res.brand_type !== 'vintage' && !isB2BSupplyBrand(res.brand_name));

    return filteredResults.slice(0, 3)
  } catch (err) {
    // Sentry.captureException(err);
    console.log('error is', err);
  }
};

// in order to process B2B supply quickly with different brand types to their C2B equivalents, we create distinct B2B brands then map their names to a customer-facing equivalent for listings + orders
const B2B_SUPPLY_BRANDS_DISPLAY_MAPPINGS: Record<string, string> = {
    'Asos B2B': 'Asos',
};

const isB2BSupplyBrand = (brandName: string) => {
    if (B2B_SUPPLY_BRANDS_DISPLAY_MAPPINGS.hasOwnProperty(brandName)) return true;

    return false;
}
