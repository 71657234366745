import React, { FormEvent, useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import classNames from 'classnames';
import styles from './styles.module.css';
import { NewsletterSignupStoryblok } from './types';
import { addSubscriber } from '../../../../utils/klaviyo';

interface Props {
  blok: NewsletterSignupStoryblok;
  className?: string;
  backgroundColor?: string;
  textAlign?: "start" | "end" | "left" | "right" | "center" | "justify" | "match-parent";
}

const NewsletterSignup = ({ blok, className, backgroundColor, textAlign }: Props) => {
  const { title, subtitle } = blok || {};
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState<'success' | 'error' | null>(null);

  const message = {
    success: "We've signed you up!",
    error: 'Something went wrong...',
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    try {
      await addSubscriber(email);
      setResponse('success');
      setEmail('');
    } catch (err) {
      setResponse('error')
    }
    setIsLoading(false);
  };

  return (
    <section
      className={classNames(styles.container, 'Section', 'SingleColumnLarge', className, { [styles.loading]: isLoading })}
      style={{ backgroundColor: backgroundColor ?? '#FFFCBF', textAlign: textAlign ?? 'center'}}
      {...storyblokEditable(blok)}
    >
        {title && <h3 className={classNames(styles.title, 'display-18-caps')}>{title}</h3>}
        {subtitle && <h4 className={classNames(styles.subtitle, 'body-14')}>{subtitle}</h4>}
      <form className={styles.inputCenter} onSubmit={handleSubmit}>
        <div className={styles.inputContainer}>
          <input
            className={classNames(styles.input, 'body-14')}
            type="email"
            placeholder="Your email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input className={classNames(styles.submit, 'button-12-caps')} type="submit" value="Sign up" disabled={!email}
          style={{ opacity: !email ? 0.5 : 1 }}/>
        </div>
        {response && (
          <p
            className={classNames(styles.response, {
              [styles.success]: response === 'success',
              [styles.error]: response === 'error',
            })}
          >
            {message[response]}
          </p>
        )}
      </form>
    </section>
  );
};

export default NewsletterSignup;
