import { StoryblokStory } from 'storyblok-generate-ts';
import { PageStoryblok } from '../component-types-sb';

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const generateSeoValuesfromStory = (story: StoryblokStory<PageStoryblok>) => {
  let seoSettings: { title?: string; description?: string; } = {};

  if (story?.content?.seo?.title) {
    seoSettings.title = story?.content?.seo?.title;
  } else {
    seoSettings.title = `${story.name} | Thrift +`;
  }
  if (story?.content?.seo?.description) {
    seoSettings.description = story?.content?.seo?.description;
  }

  return seoSettings;
};

export const getUnixTimestampForDaysAgo = (daysAgo = 30) => {
  const d = new Date();
  return Math.floor(d.setDate(d.getDate() - daysAgo) / 1000);
};

export const roundNumberDownToNearest5 = (x: number) => Math.floor(x / 5) * 5;

export const capitalizeFirstLetter = (str: string) => {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const startAndEndTimestampsFor = (date: Date) => {
  const start = new Date(new Date(date).setUTCHours(0, 0, 0, 0));
  const end = new Date(new Date(date).setUTCHours(23, 59, 59, 999));
  return {
    start, end
  };
};

export const toAgeInDays = (date: Date) => {
  const now = new Date();
  const { start: startOfOldDate } = startAndEndTimestampsFor(date);
  const { start: thisMorning } = startAndEndTimestampsFor(now);
  const msDifference = thisMorning.getTime() - startOfOldDate.getTime();
  const daysDifference = msDifference / (DAY_IN_MS);
  return daysDifference;
};