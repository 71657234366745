import React, { useState } from 'react';

import { IntlUtility } from '../../../helpers';
import { Image } from '../../core';
import styles from './styles.module.css';
import classNames from 'classnames';
import Link from 'next/link';
import { CartLine } from '../../../data-fetching/cart/useCart';
import { useCartLine } from '../../../data-fetching/cart/useCartLine';
import CustomSelect from '../../core/CustomSelect';

interface Props {
  item: CartLine;
  visible?: boolean;
  updateCart?: () => void;
}

export const MAX_NUM_OF_GIFTCARDS = 10;

export const GiftcardCartItem: React.FC<Props> = ({ item }) => {
  const { removeFromCart, setQuantity } = useCartLine(item.merchandise.id);
  const [isLoading, setIsLoading] = useState(false);

  const isAvailable = item.merchandise.availableForSale;

  const price = parseFloat(item.cost.totalAmount.amount);
  const compareAtPrice = item.merchandise.compareAtPrice ? parseFloat(item.merchandise.compareAtPrice.amount) : parseFloat(item.merchandise.price.amount);

  const url = `/products/${item.canonicalItem?.handle}`;

  const options = new Array(MAX_NUM_OF_GIFTCARDS)
    .fill(null)
    .map((_, i) => ({ label: `${i+1}`, value: i+1 }));

  const handleUpdateQty = async (qty:number) => {
    if (!setQuantity) return;
    setIsLoading(true);
    const cappedQty = Math.min(qty, MAX_NUM_OF_GIFTCARDS);
    await setQuantity(cappedQty);
    setIsLoading(false);
  };

  return (
    <div key={item.id} className={classNames(styles.list, { [styles.listLoading]: isLoading })}>
      <div className={styles.imageCont}>
        <Link href={url} className="forced-aspect-square block">
          {item.merchandise.image ? <Image src={item.merchandise.image.url} alt={item.merchandise.image.altText ?? item.merchandise.product.title} className={styles.image} /> : null}
        </Link>
        {!isAvailable && <div className={classNames('display-10-caps', styles.soldOut)}>Sold out</div>}
      </div>

      <div className={styles.itemCont}>
        <Link href={url}>
          <p className="body-14 block md:hidden">{item.merchandise.product.title}</p>
          <p className="body-14 hidden md:block">{item.merchandise.product.title}</p>
        </Link>
        <p className="body-14">{item.canonicalItem?.size.display}</p>

        <p className="body-14-bold whitespace-pre-line">
          {IntlUtility.formatPrice({
            amount: price,
            currencyCode: item.cost.totalAmount.currencyCode,
          })}
        </p>
      </div>


      <div className={styles.btnCol}>
        <label className="body-12 mb-2">
            <span className={"flex items-center gap-1 my-1"}>Qty</span>
            <CustomSelect
              options={options}
              value={item.quantity}
              onChange={({ value }) => handleUpdateQty(value)}
              fullWidth={false}
            />
          </label>
        <button className="body-12 mb-1 text-right whitespace-nowrap" onClick={() => removeFromCart && removeFromCart()} >
          Remove
        </button>
      </div>
    </div>
  );
};