import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import classNames from 'classnames';
import styles from './ProductAlt.module.css';

const ProductAlt = ({ blok }) => {
  const items = blok?.sections || [];

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {items.map((nestedBlok: any) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  );
};

export default ProductAlt;
