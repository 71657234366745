import React, { useContext } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { WebviewEventContext } from '../../../context/WebviewEventContext';
import { Logo } from '../../core/Icons/iconList';
import SplashImage from './mobile_splash.png';

import styles from './styles.module.css';

type Props = {};

const MobileSplashScreen: React.FC = ({}) => {
  const router = useRouter();
  const { setShowSplashScreenHandler } = useContext(WebviewEventContext);

  const handleRegisterClick = () => {
    router.push('/account/create-account');
    setTimeout(() => {
      setShowSplashScreenHandler(false);
    }, 400);
  };

  const handleLoginClick = () => {
    router.push('/account/login');
    setTimeout(() => {
      setShowSplashScreenHandler(false);
    }, 400);
  };

  const handleGuestClick = () => {
    // setTimeout(() => {
    setShowSplashScreenHandler(false);
    // }, 50);
    router.push('/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.image}>
        <Image src={SplashImage} alt="Thrift" />
        {/* <img src="./mobile_splash.png" alt="Thrift"></img> */}
      </div>
      <div className={styles.logo}>
        <Logo width={250} />
      </div>
      <div className={styles.subtitle}>Making second-hand seamless.</div>
      <div className={styles.buttonContainer}>
        <div className={styles.btnLeft} onClick={handleRegisterClick}>
          Register
        </div>
        <div className={styles.btnRight} onClick={handleLoginClick}>
          Login
        </div>
      </div>
      <div className={styles.bottomText} onClick={handleGuestClick}>
        Continue as guest
      </div>
    </div>
  );
};

export default MobileSplashScreen;
