import React, { Component, createContext } from 'react';

import { uuidv4 } from '../utils/helpers';

interface Props {
  children: React.ReactNode;
}

interface State {
  components: {};
  showModal: (component: any, props?: {}) => void;
  hideModal: (modalKey: string) => void;
}

export const ModalContext = createContext<State>({
  components: {},
  showModal: () => {},
  hideModal: () => {},
});

export class ModalProvider extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      components: {},
      showModal: (component, props = {}) => {
        const key = uuidv4();
        const components = {
          ...this.state.components,
          [key]: { component, props },
        };
        this.setState({
          components,
        });
      },
      hideModal: (modalKey) => {
        const components = this.state.components;
        delete components[modalKey];
        this.setState({
          components,
        });
      },
    };
  }

  hideAllModals = () =>
    this.setState({
      components: {},
    });

  componentWillUnmount() {
    this.hideAllModals();
  }

  render() {
    return <ModalContext.Provider value={this.state}>{this.props.children}</ModalContext.Provider>;
  }
}

export const ModalConsumer = ModalContext.Consumer;
