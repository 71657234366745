import { Button } from '../../core';
import { useSimpleForm } from '../useForm';
import { FormEventHandler, useContext, useEffect, useState } from 'react';
import { AccountContext } from '../../../context/AccountContext';
import styles from './styles.module.css';

type RegisterFormContent = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  acceptsMarketing: boolean,
}

const defaultFormState: RegisterFormContent = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  acceptsMarketing: false,
}

type Props = {
  checkboxText?: string,
  redirect?: string | null
};

export const Register: React.FC<Props> = ({checkboxText, redirect}) => {
  const {formManager, formData} = useSimpleForm(defaultFormState);
  const {registerShopifyCustomer, shopifyCustomerError, setShopifyCustomerError, isCustomerLoading} = useContext(AccountContext);
  useEffect(() => setShopifyCustomerError(null), []);

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    if (isCustomerLoading) return;
    registerShopifyCustomer(
      formData.firstName,
      formData.lastName,
      formData.email,
      formData.password,
      formData.acceptsMarketing,
      redirect,
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <div className="flex flex-col gap-2 mb-4">
        <label className="body-14-bold" htmlFor="firstName">
          First Name
        </label>
        <input type="text" id="firstName" placeholder="John" required value={formManager.firstName.state} onChange={(e) => formManager.firstName.update(e.target.value)} />
      </div>

      <div className="flex flex-col gap-2 mb-4">
        <label className="body-14-bold" htmlFor="lastName">
          Last Name
        </label>
        <input type="text" id="lastName" placeholder="Doe" required value={formManager.lastName.state} onChange={(e) => formManager.lastName.update(e.target.value)} />
      </div>

      <div className="flex flex-col gap-2 mb-4">
        <label className="body-14-bold" htmlFor="email">
          Email
        </label>
        <input type="email" id="email" placeholder="example@example.com" required value={formManager.email.state} onChange={(e) => formManager.email.update(e.target.value)} />
      </div>
      <div className="flex flex-col gap-2 mb-4">
        <label className="body-14-bold" htmlFor="password">
          Password
        </label>
        <input type="password" placeholder="*********" required value={formManager.password.state} onChange={(e) => formManager.password.update(e.target.value)}/>
      </div>

      {shopifyCustomerError && <p className="bg-brandRed25 p-2 body-12 mb-4">{shopifyCustomerError}</p>}

      {checkboxText && <label className="flex gap-1 mb-2 body-14">
        <input type="checkbox" className='mr-1 mb-4 mt-1' checked={formManager.acceptsMarketing.state} onChange={(e) => formManager.acceptsMarketing.update(e.target.checked)} />
        <span>{checkboxText}</span>
      </label>}

      <Button isLoading={isCustomerLoading} loadingText="Creating..." text="Create Account" className="w-full" />

    </form>
  )
}