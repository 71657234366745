import { storyblokEditable, StoryblokComponent } from '@storyblok/react';
import styles from './styles.module.css';
import { LayoutGridStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: LayoutGridStoryblok;
}

const LayoutGrid = ({ blok }: Props) => {
  const { items, columns, columns_large } = blok || {};

  const columnsClass = styles[`items-${columns}`];
  const columnsLargeClass = styles[`items-large-${columns_large}`];

  return (
    <section {...storyblokEditable(blok)}>
      <div className={classNames(styles.items, columnsClass, columnsLargeClass)}>
        {items?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} grid={true} />
        ))}
      </div>
    </section>
  );
};

export default LayoutGrid;
