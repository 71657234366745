import { ISbStoryData } from '@storyblok/react';
import { createContext, PropsWithChildren, useContext } from 'react';
import { ConfigStoryblok } from '../component-types-sb';


export const configContext = createContext<ISbStoryData<ConfigStoryblok> | null>(null);

export const ConfigContextProvider: React.FC<PropsWithChildren<{config: ISbStoryData<ConfigStoryblok> | null}>> = ({ children, config }) => {
  return <configContext.Provider value={config}>{children}</configContext.Provider>;
}

export const useStoryblokConfig = (): ConfigStoryblok | null => {
  const fullConfigStory = useContext(configContext);
  return fullConfigStory?.content ?? null
}