import { NavLinkStoryblok } from "../../../../component-types-sb";

interface Props {
  blok: NavLinkStoryblok;
  className: string;
}

const NavLink = ({ blok, className }: Props) => {
  const { text, url } = blok;

  const cachedURL = url?.cached_url;
  return (
    <a className={className} href={cachedURL}>
      {text}
    </a>
  );
};

export default NavLink;
