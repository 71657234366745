import { getWishlistItemHandle } from "../../../../context/WishlistContext";
import { SwymList } from "../../../../modules/@swym/swym.service";
import { isNullOrUndefined } from "../../../../utils/objectHelpers";

export const getWatchlistHandlesWithActiveOfferProductsRemoved = (wishlist?: SwymList, products?: {sku: string, variantId: string, handle: string, productId: string}[]): string[] | null => {
    if (isNullOrUndefined(wishlist)) return null;
    if (isNullOrUndefined(products)) return null;
    if (wishlist?.listcontents.length < 5) return null;

    return wishlist?.listcontents?.filter(item => {
        const productHandle = getWishlistItemHandle(item);
        const hasActiveOffer = products?.some(product => product.handle === productHandle);
        return !hasActiveOffer;
    })?.slice(0, 5).map(item => getWishlistItemHandle(item)) ?? [];
}