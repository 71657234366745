import { storyblokEditable, StoryblokComponent } from '@storyblok/react';

import { PartnerFaqListStoryblok } from './types';
import classNames from 'classnames';

interface Props {
  blok: PartnerFaqListStoryblok;
}

const PartnerFAQList = ({ blok }: Props) => {
  const { sections } = blok || {};

  return (
    <section className={classNames('Section', 'Section--spaced')} {...storyblokEditable(blok)}>
      <div>
        {sections?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  );
};

export default PartnerFAQList;
