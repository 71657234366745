import React, { useState, useContext, useEffect } from 'react';
import DrawerHeader from '../DrawerHeader/index';
import styles from './styles.module.css';
import FadeIn from '../../../../transitions/FadeIn';
import { DrawerContext } from '../../../../../context/DrawerContext';
import Shop from '../Shop/index';
import { getBrandList } from '../../../../../utils/brandList';
import { SessionContext } from '../../../../../context/SessionContext';
import Link from 'next/link';

const Brands = ({ items, config }) => {
  const { showDrawer } = useContext(DrawerContext);
  const { isWomen, basePath } = useContext(SessionContext);
  const [brands, setBrands] = useState([]);
  const [searchText, setSearchText] = useState('');

  const handleBack = () => showDrawer(Shop as any, { config: config });

  const getBrands = async () => {
    const brands = await getBrandList(isWomen ? 'women' : 'men');
    setBrands(brands);
  };

  const mappedBrands = brands.map(brandId => {
    return {
      url: `${basePath}/${brandId}`,
      name: brandId.split('-').join(' '),
      id: brandId,
    }
  })

  const filteredBrands = searchText ? mappedBrands.filter((brand) => {
    return brand.name.toLowerCase().includes(searchText.toLowerCase())
  }) : mappedBrands;

  useEffect(() => {
    getBrands();
  }, [isWomen]);

  let didInit = false;
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      getBrands();
    }
  }, []);

  return (
    <FadeIn>
      <div className={styles.container}>
        <DrawerHeader title="Brands" onBack={handleBack} />
        <div className="p-2">
          <input className={'w-full inputSecondary'}
            type="text"
            placeholder="Search for brands"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          </div>
          <ul className={styles.items}>
            {filteredBrands.map((brand) => {
              return (
                <li className={styles.item} key={brand.id}>
                  <Link className={styles.link} href={brand.url}></Link>
                  {brand.name}
                </li>
              );
            })}
          </ul>
      </div>
    </FadeIn>
  );
};

export default Brands;
