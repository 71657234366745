import { ProductSearchState, SearchRefinements } from './types';
import { useInfiniteSearch } from './useInfiniteSearch';
import { useEffect, useState } from 'react';
import { equals } from 'ramda';

export const useRefinements = (searchState: ProductSearchState) => {
  const [lastSeenRefinements, setLastSeenRefinements] = useState<SearchRefinements | undefined>();
  const result = useInfiniteSearch(searchState)
  // shares a query with the infinite search for efficiency, but means we get this result for each page, so need to do this
  const refinements = result.data?.pages.find(() => true)?.refinements;
  const refinementString = JSON.stringify(refinements);

  // when the query changes, all these get wiped
  // so here we're caching the last seen refinements to stop the filters from jumping on re-render
  useEffect(() => {
    if (equals(refinements, lastSeenRefinements)) return;
    if (typeof refinements === 'undefined') return;
    setLastSeenRefinements(refinements);
  }, [refinementString, refinements]);

  try {

    return {
      ...result,
      data: refinements ?? lastSeenRefinements,
    }
  } catch (err) {
    return {
      ...result,
      data: null
    }
  }
};
