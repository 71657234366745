import { storyblokEditable } from '@storyblok/react';
import Image from '../../../../core/Image';

import styles from './styles.module.css';
import { CarouselItemImageStoryblok } from './types';
import classNames from 'classnames';
import { Icon } from '../../../../core';
import Link from 'next/link';

export interface Props {
  blok: CarouselItemImageStoryblok;
}

const CarouselItemImage = ({ blok }: Props) => {
  const { title, subtitle, url, background_image } = blok || {};
  const imgSrc = background_image?.filename;
  const cachedURL = url?.cached_url;
  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      {cachedURL && <Link className={styles.link} href={cachedURL}></Link>}
      {imgSrc && <Image className={styles.image} src={imgSrc} alt="Carousel image" />}
      <div className={styles.textContainer}>
        {title && (
          <h5 className={classNames(styles.title, 'body-16-bold')}>
            {title}
            <Icon title="arrowRight" className={styles.arrow} />
          </h5>
        )}
        {subtitle && <h6 className="body-14">{subtitle}</h6>}
      </div>
    </div>
  );
};

export default CarouselItemImage;
