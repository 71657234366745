import classNames from 'classnames';
import React from 'react';
import { Signpost } from '../../core';
import styles from './styles.module.css';

const Custom404 = ({ title = 'Page Not Found', subtitle = 'The page you were looking for does not exist.' }) => {
  return (
    <div className={classNames(styles.container, 'Section')}>
      <h1 className={classNames(styles.title, 'display-24')}>{title}</h1>
      <h2 className={classNames(styles.subtitle, 'body-18 max-w-md')}>
        {' '}
        <>
          Oops! We've just launched our new site, and you've found a loophole! We are tracking issues and will get this
          fixed ASAP. If you have any feedback, do get in touch with{' '}
          <a href="mailto:hello@thrift.plus">hello@thrift.plus</a>
        </>
        {/* {subtitle} */}
      </h2>
      <Signpost href="/" signpostText="Continue shopping" />
    </div>
  );
};

export default Custom404;
