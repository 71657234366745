import React, { PropsWithChildren } from 'react';
import { useState, useEffect } from "react";
import { ButtonProps } from './types';
import Tooltip from './Tooltip';
import styles from './styles.module.css';
import Icon from '../Icons';
import classNames from 'classnames';

import Link from 'next/link';
import useTimeout from '../../../helpers/useTimeout';
import SignpostLink from '../SignpostLink';

const Button = ({
  text,
  isSecondary,
  iconLeft,
  iconRight,
  onClick,
  tooltip,
  className,
  disabled,
  isLoading,
  loadingText = 'Waiting...',
  fullWidth,
  href,
  bgColour,
  target,
  children,
}: PropsWithChildren<ButtonProps>) => {
  const button_class = bgColour ? undefined : (isSecondary ? styles.secondaryButton : styles.brandButton);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const timedOut = useTimeout(3000);

  useEffect(() => {
    setShowTooltip(timedOut)
  }, [timedOut])

  const Element = href ? Link : 'button';

  const customStyles = {
    backgroundColor: bgColour,
    border: bgColour !== 'white' ? 'border' : undefined,
    color: bgColour === "black" ? 'white' : undefined,
  };

  return (
    <Element
      className={classNames(styles.button, button_class, className, {
        [styles.loading]: isLoading,
        [styles.fullWidth]: fullWidth,
      })}
      disabled={disabled}
      {...(href && { href, target })}
      {...(onClick && !href && { onClick })}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      style={customStyles}
    >
      {iconLeft && <Icon title={iconLeft} className={classNames(styles.icon, styles.iconLeft)} />}
      <div className={classNames('body-14-bold')}>{isLoading ? loadingText : (children ?? text)}</div>
      {iconRight && <Icon title={iconRight} className={styles.icon} />}

      <Tooltip
      open={showTooltip} text={tooltip} />

    </Element>
  );
};

export default Button;


export const AsyncButton = ({onClick, ...props}) => {

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const wrappedOnClick = () => {
    setIsLoading(true)
    onClick().then(() => {
      setIsLoading(false);
    })
  }

  return <SignpostLink signpostText={props.text} onClick={wrappedOnClick} iconRight={''} bgColour={props.button_colour} {...props}/>

}