import { useState } from 'react';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';
import Image from '../../../../core/Image';

import styles from './styles.module.css';
import { DrawerCategoryItemStoryblok } from './types';
import { Icon } from '../../../../core';
import classNames from 'classnames';
import Link from 'next/link';

interface Props {
  blok: DrawerCategoryItemStoryblok;
  showDropdown: boolean;
}

const DrawerCategoryItem = ({ blok, showDropdown }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { image, url, title, subcategories } = blok || {};

  const imgSrc = image?.filename;
  const cachedURL = url?.cached_url;

  const ClickElement = showDropdown ? (
    <button className={styles.link} onClick={() => setExpanded(!expanded)}></button>
  ) : (
    cachedURL && <Link className={styles.link} href={cachedURL}></Link>
  );
  return (
    <>
      <div className={classNames(styles.container, { [styles.dropdownOpen]: expanded })} {...storyblokEditable(blok)}>
        {ClickElement}
        <div className={styles.content}>
          {imgSrc && <Image className={styles.image} src={imgSrc} alt="Brand tile" />}
          {title && <h5 className="body-14-bold">{title}</h5>}
        </div>
        {showDropdown && (
          <span className={styles.dropdownButton} onClick={() => setExpanded(!expanded)}>
            <Icon title="chevronDown" />
          </span>
        )}
      </div>
      {expanded && (
        <div className={styles.dropdownContent}>
          {subcategories?.map((subcat) => (
            <StoryblokComponent blok={subcat} key={subcat._uid} />
          ))}
        </div>
      )}
    </>
  );
};

export default DrawerCategoryItem;
