export enum BrandType {
  DESIGNER = 'designer',
  PREMIUM = 'premium',
  HIGH_STREET = 'high_street',
  LOW_HIGH_STREET = 'low_high_street',
  NOT_ACCEPTED = 'not_accepted',
  VINTAGE = 'vintage'
}

export type Brand = {
  brand_id: string,
  brand_name: string,
  brand_type: BrandType,
}
