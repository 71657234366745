import { storyblokEditable } from '@storyblok/react';
import styles from './styles.module.css';
import { BenefitsStripItemStoryblok } from './types';
import classNames from 'classnames';
import { Icon } from '../../../../core';

interface Props {
  blok: BenefitsStripItemStoryblok;
}

const BenefitsStripItem = ({ blok }: Props) => {
  const { icon, text, description } = blok || {};

  return (
    <div className={styles.container} {...storyblokEditable(blok)}>
      <div className={styles.wrapper}>
        {icon && <Icon title={icon} />}
        {text && <h3 className={classNames(styles.text, 'display-10-caps')}>{text}</h3>}
      </div>
      {description && <p className={classNames(styles.description, 'body-14')}>{description}</p>}
    </div>
  );
};

export default BenefitsStripItem;
