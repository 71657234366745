import React from 'react';
import { Image, RichText } from '../../../../core';

const SizeGuide = ({ sizeGuide }) => {
  const { title, text, image } = sizeGuide;
  const imgSrc = image?.filename;

  return (
    <div className="h-full p-3">
      <RichText document={title} className="display-20-caps mb-3" />
      <RichText document={text} className="body-12 mb-4" />
      <Image src={imgSrc} alt={image.alt} className="block" />
    </div>
  );
};

export default SizeGuide;
